import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ConvertToImageService } from '../../../shared/commands/convert-to-image.service';
import { DirtyDataService, DirtyDataStatus } from '../../../shared/commands/dirty-data.service';
import { PrintMapService } from '../../../shared/commands/print-map.service';
import { ExportSmeService } from '../../commands/export-sme.service';
import { MapStateService } from '../../../shared/map-state.service';
import { CopyAndOpenMapService } from '../../../shared/commands/copy-and-open-map.service';
import { Subscription, Observable, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { extract, I18nService } from '../../../core/i18n.service';
import { SmService } from 'supermappe-core';
import { UiConstants } from '../../../shared/ui-constants';
import { SmeService } from '../../../core/sme/sme.service';
import { Router } from '@angular/router';
import { InputDialogService } from '../../../shared/dialog/input-dialog.service';
import { QuickEditService } from '../../../shared/commands/quick-edit.service';
import { Title } from '@angular/platform-browser';
import { CustomSelectElement } from '../../toolbar/custom-toolbar/custom-select-element-dto';
import { CustomizeToolbarService } from '../../toolbar/custom-toolbar/customize-toolbar.service';
import { CustomToolbarDto } from '../../toolbar/custom-toolbar/custom-toolbar-dto';
import { MapLanguageService } from '../../../shared/commands/map-language.service';
import { MapClipboardService } from '../../commands/map-clipboard.service';
import { ZoomService } from '../../../shared/commands/zoom.service';
import { MapFoldingService } from '../../../shared/commands/map-folding.service';
import { TtsService } from '../../../shared/commands/tts.service';
import { NewTableService } from '../../new-table/new-table.service';
import { ModalService } from '../../../shared/links-menu/modal-service';
import { MapShowService } from '../../../map-show/map-show.service';
import { ShareMapService } from '../../../shared/share-map/share-map.service';
import { WebSearchService } from '../../../shared/commands/web-search.service';
import { Logger } from '../../../core/logger.service';
import { ResourceUpload } from '../../../core/firebase/resource-upload';
import { AutosaveService } from '../../commands/autosave.service';
import { ConfirmationService } from '../../../shared/dialog/confirmation.service';
import { MapFindService } from '../../../shared/map-find/map-find.service';
import { ShareLinkService } from '../../../shared/commands/share-link.service';
import { CreateGoogleDocService } from '../../../shared/commands/create-google-doc.service';
import { CreateMapDocumentService } from '../../../shared/commands/create-map-document.service';
import { ExtraItem, ExtraService } from '../../commands/extra.service';
import { SmEventBroker } from '../../../shared/sm-event-broker';
import { UserPreferenceService } from '../../../shared/user-preference.service';
import { UserPrefsDto } from '../../../shared/users/user-prefs-dto';
import { LinksService } from '../../../shared/links.service';
import { ChatService } from '../../../shared/chat/chat.service';
import { FirebaseService } from '../../../core/firebase/firebase.service';
import { MessageBoxService } from "../../../shared/dialog/messagebox.service";
import { ArrangeService } from '../../../shared/commands/arrange.service';
import { MathService } from 'src/app/shared/commands/math.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { DeviceService } from 'src/app/core/device.service';
import { ExportMapInGoogleDriveService } from 'src/app/shared/export-map-in-google-drive/export-map-in-google-drive.service';

const logger: Logger = new Logger('edit-menu-component');

@Component({
    selector: 'app-edit-menu',
    templateUrl: './edit-menu.component.html',
    styleUrls: ['./edit-menu.component.scss']
})
export class EditMenuComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('primary') primary: ElementRef | undefined;

    @Output() startLoading = new EventEmitter<void>();
    @Output() endLoading = new EventEmitter<boolean>();
    @Input() isLoading = true;
    @Input() editMenuCompressed = false;
    public pasteMap = false;

    private onGoogleDocIdChangeSubscription: Subscription | undefined;
    private updateUserPreferenceSubscription: Subscription | undefined;
    public showGoogleDoc = false;
    public hasGoogleDoc = false;
    public hasNotes: boolean = false;
    public isMathEnabled: boolean = false;
    public isOutlineEnabled: boolean = false;
    public isPdfEnabled: boolean = false;

    error = false;

    dirtyDataChangeSubscription: Subscription | undefined;
    onErrorSubscription: Subscription | undefined;
    onChangeSelectionSubscription: Subscription | undefined;
    mapLanguageChangeSubscription: Subscription | undefined;
    onGetVoicesSubscription: Subscription | undefined;
    onMapDirtyStateChangeSubscription: Subscription | undefined;
    onTTSEnabledChangedSubscription: any;
    mapClipboardServiceSubscription: Subscription | undefined;
    askNameSubscription: Subscription | undefined;
    renameMapSubscription: Subscription | undefined;
    uploadWorkJsonSubscription: Subscription | undefined;
    smeServiceRenameMapSubscription: Subscription | undefined;
    askRowColSubscription: Subscription | undefined;
    openNewTableDialogSubscription: Subscription | undefined;
    openLinkDialogSubscription: Subscription | undefined;
    askUrlSubscription: Subscription | undefined;
    createNewMapSubscription: Subscription | undefined;
    uploadMapSubscription: Subscription | undefined;
    createWorkMapSubscription: Subscription | undefined;
    mapIsSavingSubscription: Subscription | undefined;
    getLinkPreviewSubscription: Subscription | undefined;
    changedBackgroundcolorSubscription: Subscription | undefined;
    getUserPreferenceSubscription: Subscription | undefined;
    onMapIsMineSubscription: Subscription | undefined;
    onShareDataSubscription: Subscription | undefined;
    onGridChangeSubscription: Subscription | undefined;

    selectionData: any;
    isSingleNodeSelected = false;
    isSingleEdgeSelected = false;
    isMultiSelectionPresent = false;
    isCopyExtended = false;
    isOnlyEdgeSelected = false;
    isGoogleUser = false;

    fontColorTooltip = '';
    fontBackColorTooltip = '';
    usedColorLabel = '';
    selectedFontColor = '#000000';
    selectedFontBackColor = 'transparent';
    selectedBackgroundColor = 'white';

    triggered = false;
    ttsEnable: boolean;
    currentMapUpload: ResourceUpload | undefined;

    baseColors = UiConstants.PALETTE.BASE;
    highlightColors = UiConstants.PALETTE.HIGHLIGHTERS;
    backgroundColors = UiConstants.PALETTE.BACKGROUND;

    languages: Array<CustomSelectElement> = new Array<CustomSelectElement>().concat(UiConstants.LANGUAGES);

    selectedMapLanguage: CustomSelectElement = UiConstants.LANGUAGES[0];
    selectedMapLanguageIcon: string = this.selectedMapLanguage.icon;
    selectionLanguage: CustomSelectElement = UiConstants.LANGUAGES[0];

    fontFamilies = UiConstants.FONTS.fontFamilies;
    fontSizes = UiConstants.FONTS.fontSizes;
    selectedFontSize = '';

    public alignments = UiConstants.ALIGNMENTS;
    public sizes = UiConstants.SIZES;

    public isSelectionPresent = false;
    public isLinkPresent = false;
    public isTitleTextPresent = false;
    public isDeepTextPresent = false;
    public isImagePresent = false;
    public mainLinkIsVideo = false;

    public nodeShapes = UiConstants.NODESHAPES;
    public bordersWidth = UiConstants.BORDERSWIDTH;
    private baseTextPositions: CustomSelectElement[];
    public textPositions: CustomSelectElement[];
    public tableTextPositions: CustomSelectElement[];

    public isFramed: boolean;

    public edgeArrowRightChecked = false;
    public edgeArrowLeftChecked = false;

    public isMine: boolean;
    public isGridVisible = false;

    public isLab: boolean = false;

    //MOBILE LIMITATIONS
    public isMobileMode: boolean = false;
    public isDevEnvOrLocalHost: boolean = false;

    constructor(
        private router: Router,
        private i18nService: I18nService,
        private dirtyDataService: DirtyDataService,
        private mapLanguageService: MapLanguageService,
        private convertToImageService: ConvertToImageService,
        private copyAndOpenMapService: CopyAndOpenMapService,
        private printMapService: PrintMapService,
        private exportSmeService: ExportSmeService,
        public mapStateService: MapStateService,
        private translateService: TranslateService,
        private smService: SmService,
        private smeService: SmeService,
        private shareMapService: ShareMapService,
        private inputDialogService: InputDialogService,
        private quickEditService: QuickEditService,
        private titleService: Title,
        private customizeToolbarService: CustomizeToolbarService,
        private mapClipboardService: MapClipboardService,
        private zoomService: ZoomService,
        private newTableService: NewTableService,
        private mapFoldingService: MapFoldingService,
        private modalService: ModalService,
        private ttsService: TtsService,
        private mapShowService: MapShowService,
        private webSearchService: WebSearchService,
        private autosaveService: AutosaveService,
        private confirmationService: ConfirmationService,
        private mapFindService: MapFindService,
        private userPreferenceService: UserPreferenceService,
        private shareLinkService: ShareLinkService,
        private createGoogleDocService: CreateGoogleDocService,
        private createMapDocumentService: CreateMapDocumentService,
        private extraService: ExtraService,
        private smEventBroker: SmEventBroker,
        private linksService: LinksService,
        private chatService: ChatService,
        private firebaseService: FirebaseService,
        private messageBoxService: MessageBoxService,
        private arrangeService: ArrangeService,
        private mathService: MathService,
        private authenticationService: AuthenticationService,
        public deviceService: DeviceService,
        private exportMapToGoogleDriveService: ExportMapInGoogleDriveService,
    ) {
        // Constructor
        this.isLab = this.authenticationService.isLab();
        this.isMine = false;
        this.onMapIsMineSubscription = this.mapStateService.onMapIsMine.subscribe((_isMine: boolean) => {
            this.isMine = _isMine;
            this.isMobileMode = deviceService.isMobileMode();
            this.isDevEnvOrLocalHost = window.location.origin.includes("dev.supermappex.it") || window.location.origin.includes("localhost:")
        });
        // this.languages.concat(UiConstants.LANGUAGES);
        this.selectedMapLanguage = UiConstants.findLanguage(this.i18nService.language, this.languages);
        this.selectedMapLanguageIcon = (this.selectedMapLanguage ? this.selectedMapLanguage.icon : '');
        this.selectionLanguage = UiConstants.findLanguage(this.i18nService.language, this.languages);
        this.usedColorLabel = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-MYCOLOR'));
        this.fontColorTooltip = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-TEXT-COLOR'));
        this.fontBackColorTooltip = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-TEXT-BACK-COLOR'));
        this.canUndo = false;
        this.canRedo = false;
        this.canCopy = false;
        this.canDelete = false;
        this.canPaste = false;
        this.isBold = false;
        this.isItalic = false;
        this.isUnderline = false;
        this.isSuperscript = false;
        this.isSubscript = false;
        this.fontFamily = 'Arial';
        this.fontSize = '14';
        this.color = '#000000';
        this.backgroundColor = 'transparent';
        this.isFramed = false;
        this.ttsEnable = false;
        this.baseTextPositions = UiConstants.TEXTPOSITIONS;
        this.textPositions = this.baseTextPositions;
        this.tableTextPositions = UiConstants.TABLETEXTPOSITIONS;
        this.mapIsSaving = false;
        this.isMathEnabled = this.authenticationService.isMathEnabled();
        this.isOutlineEnabled = this.authenticationService.isOutlineEnabled();
        this.isPdfEnabled = this.authenticationService.isPdfEnabled();
    }

    canShowing = true;
    canUndo: boolean;
    canRedo: boolean;
    canCopy: boolean;
    canPaste: boolean;
    canDelete: boolean;
    canOrder = false;
    canArrange = false;
    canAlign = false;
    canResize = false;
    multiselection = false;
    noSelection = false;
    nodeSelected = false;
    noNodeSelected = true;
    noShapedNodeSelected = true;
    edgeSelected = false;
    edgeCurveSelected = false;
    imageSelected = false;
    cellSelected = false;
    isTableSelected = false;
    isTableLocked = false;
    scrCenterX = 0;
    scrCenterY = 0;

    public fontFamily: string;
    public fontSize: string;
    public color: string;
    public backgroundColor: string;
    public isBold: boolean;
    public isItalic: boolean;
    public isUnderline: boolean;
    public isSuperscript: boolean;
    public isSubscript: boolean;

    public mapIsSaving: boolean;

    ngOnInit() {
        this.isGoogleUser = this.authenticationService.isGoogleUser() || this.authenticationService.linkedWithGoogle();
        this.onErrorSubscription = this.mapStateService.onError.subscribe((error: any) => {
            this.error = error !== '';
        });
        if (this.dirtyDataService.dirtyDataChange) {
            this.dirtyDataChangeSubscription = this.dirtyDataService.dirtyDataChange
                .subscribe((dirtyDataStatus: DirtyDataStatus) => {
                    this.canUndo = dirtyDataStatus.canUndo;
                    this.canRedo = dirtyDataStatus.canRedo;
                });
        }

        this.onMapDirtyStateChangeSubscription = this.mapStateService.onDirtyStateChange.subscribe((value: boolean) => {
            this.canShowing = !value;
        });

        this.onChangeSelectionSubscription =
            this.customizeToolbarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
                this.changeSelection(_customData);
            });

        if (this.mapLanguageService.mapLanguageChange) {
            this.mapLanguageChangeSubscription = this.mapLanguageService.mapLanguageChange
                .subscribe((language: string) => {
                    if (language) {
                        this.selectedMapLanguage = UiConstants.findLanguage(language, this.languages);
                        this.selectedMapLanguageIcon = (this.selectedMapLanguage ? this.selectedMapLanguage.icon : '');
                        try { this.smService.setDefaultLanguage(language); } catch (e) { }
                    }
                });
        }
        // this.mapClipboardServiceSubscription = this.mapClipboardService.onCanCopy.subscribe((_canCopy: boolean) => {
        //   this.canCopy = _canCopy;
        // })
        this.getUserPreferenceSubscription = this.userPreferenceService.onUserPrefsGets.subscribe((userPref: UserPrefsDto) => {
            this.pasteMap = userPref.usePasteWithouKeyboard;

        });

        this.userPreferenceService.getUserPreference();

        this.updateUserPreferenceSubscription = this.userPreferenceService.onUserPrefsChange.subscribe((userPref: UserPrefsDto) => {
            this.pasteMap = userPref.usePasteWithouKeyboard;

        });

        this.mapClipboardServiceSubscription = this.mapClipboardService.onCanPaste.subscribe((_canPaste: boolean) => {
            this.canPaste = _canPaste;
        });

        this.onGetVoicesSubscription =
            this.ttsService.onGetVoices.subscribe((voices: SpeechSynthesisVoice[]) => {
                if (this.deviceService.isMobileOrTabletDevice()) {
                    this.languages = UiConstants.initLanguagesMobile(voices, this.translateService);
                } else {
                    this.languages = UiConstants.initLanguagesDesktop(voices, this.translateService);
                }
            });

        if (this.ttsService.voices) {
            if (this.deviceService.isMobileOrTabletDevice()) {
                this.languages = UiConstants.initLanguagesMobile(this.ttsService.voices, this.translateService);
            } else {
                this.languages = UiConstants.initLanguagesDesktop(this.ttsService.voices, this.translateService);
            }
        }

        this.onTTSEnabledChangedSubscription = this.smService.onttsEnabledChanged.subscribe((value: boolean) => {
            this.ttsEnable = value;
        });

        this.mapIsSavingSubscription = this.autosaveService.mapIsSaving.subscribe((isSaving: boolean) => {
            this.mapIsSaving = isSaving;
        });

        this.showGoogleDoc = false;
        this.hasGoogleDoc = false;
        if (this.onGoogleDocIdChangeSubscription) { this.onGoogleDocIdChangeSubscription.unsubscribe(); }
        this.onGoogleDocIdChangeSubscription = this.createGoogleDocService.onGoogleDocFileIdChange.subscribe((id: string) => {
            this.hasGoogleDoc = (id !== '');
            this.showGoogleDoc = true;
        });

        this.mapStateService.onGridChange.subscribe((value: boolean) => {
            this.isGridVisible = value;
        });

    }

    ngAfterViewInit(): void {
        if (this.smEventBroker.backgroundChangedData) {
            this.changedBackgroundcolorSubscription = this.smEventBroker.backgroundChangedData.subscribe((color: any) => {
                setTimeout(() => {
                    this.selectedBackgroundColor = color;
                }, 100);
            });
        }
    }

    ngOnDestroy(): void {
        if (this.onErrorSubscription) { this.onErrorSubscription.unsubscribe(); }
        if (this.dirtyDataChangeSubscription) { this.dirtyDataChangeSubscription.unsubscribe(); }
        if (this.onChangeSelectionSubscription) { this.onChangeSelectionSubscription.unsubscribe(); }
        if (this.mapLanguageChangeSubscription) { this.mapLanguageChangeSubscription.unsubscribe(); }
        if (this.onGetVoicesSubscription) { this.onGetVoicesSubscription.unsubscribe(); }
        if (this.onMapDirtyStateChangeSubscription) { this.onMapDirtyStateChangeSubscription.unsubscribe(); }
        if (this.onTTSEnabledChangedSubscription) { this.onTTSEnabledChangedSubscription.unsubscribe(); }
        if (this.mapClipboardServiceSubscription) { this.mapClipboardServiceSubscription.unsubscribe(); }
        if (this.askNameSubscription) { this.askNameSubscription.unsubscribe(); }
        if (this.renameMapSubscription) { this.renameMapSubscription.unsubscribe(); }
        if (this.uploadWorkJsonSubscription) { this.uploadWorkJsonSubscription.unsubscribe(); }
        if (this.smeServiceRenameMapSubscription) { this.smeServiceRenameMapSubscription.unsubscribe(); }
        if (this.askRowColSubscription) { this.askRowColSubscription.unsubscribe(); }
        if (this.openNewTableDialogSubscription) { this.openNewTableDialogSubscription.unsubscribe(); }
        if (this.openLinkDialogSubscription) { this.openLinkDialogSubscription.unsubscribe(); }
        if (this.askUrlSubscription) { this.askUrlSubscription.unsubscribe(); }
        if (this.createNewMapSubscription) { this.createNewMapSubscription.unsubscribe(); }
        if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
        if (this.mapIsSavingSubscription) { this.mapIsSavingSubscription.unsubscribe(); }
        if (this.getLinkPreviewSubscription) { this.getLinkPreviewSubscription.unsubscribe(); }
        if (this.onGoogleDocIdChangeSubscription) { this.onGoogleDocIdChangeSubscription.unsubscribe(); }
        if (this.updateUserPreferenceSubscription) { this.updateUserPreferenceSubscription.unsubscribe(); }
        if (this.changedBackgroundcolorSubscription) { this.changedBackgroundcolorSubscription.unsubscribe(); }
        if (this.getUserPreferenceSubscription) { this.getUserPreferenceSubscription.unsubscribe(); }
        if (this.onMapIsMineSubscription) { this.onMapIsMineSubscription.unsubscribe(); }
        if (this.onShareDataSubscription) { this.onShareDataSubscription.unsubscribe(); }
        if (this.onGridChangeSubscription) { this.onGridChangeSubscription.unsubscribe(); }
    }

    mapShowing() {
        if (this.canShowing) {
            this.mapShowService.showing(this.mapStateService.id);
        }
        // this.toggleFullScreenService.goFullScreen();
        // this.router.navigate(['map-show', this.mapStateService.id]);
    }

    openMenu() {
        this.quickEditService.disableAll();
        this.mapStateService.resetPasteState(false);
        this.hasNotes = this.extraService.hasNotes();
    }

    // isOpened(event: any) {
    //   this.triggered = event.currentTarget.isOpened;
    // }

    // openMenu(menu: any) {
    //   if (this.triggered) {
    //     menu.openMenu();
    //   }
    // }

    // closeAllMenu() {
    //   this.triggers.forEach((item) => {
    //     item.closeMenu();
    //   });

    // }

    navigateToView() {
        // this.router.navigate(['map-view', this.mapStateService.id]);
        window.location.href = 'map-view/' + this.mapStateService.id;
    }

    copyMap() {
        // this.copyAndOpenMapService.copyAndOpenMap(this.mapStateService.id, this.mapStateService.name);
        this.startLoading.emit();
        this.copyAndOpenMapService.copyAndOpenMap(this.mapStateService.id, this.mapStateService.name, undefined, () => { this.endLoading.emit(); })
            .then(() => {
                this.endLoading.emit();
            }).catch((error) => {
                this.endLoading.emit();
                this.messageBoxService.showTextMessage(
                    this.messageBoxService.MODE_TYPE.OK,
                    this.translateService.instant('ERROR_MAP_NOT_FOUND'),
                    this.translateService.instant('ERROR_GOOGLEID_NOT_FOUND')
                ).subscribe(() => {
                    this.endLoading.emit();
                    console.log(`ERROR in copyMap: ${error.message}`);
                });
            });
    }

    exportMapGoogleDrive() {
        this.exportMapToGoogleDriveService.startLoading.subscribe({
            next: () => {

                this.startLoading.emit();
            }
        });
        this.exportMapToGoogleDriveService.endLoading.subscribe({
            next: (value: any) => {

                this.endLoading.emit(value);
            }
        });

        this.exportMapToGoogleDriveService.openFolderPickerFromDrive(this.mapStateService.id, this.mapStateService.name);

       
        // this.exportMapToGoogleDriveService.exportMapGoogleDrive(this.mapStateService.id, this.mapStateService.name).subscribe(
        //     {
        //         next: (result) => {
        //             this.endLoading.emit()
        //         },
        //         error: (error) => {
        //             this.endLoading.emit();
        //             this.messageBoxService.showTextMessage(
        //                 this.messageBoxService.MODE_TYPE.OK,
        //                 this.translateService.instant('ERROR_MAP_NOT_FOUND'),
        //                 this.translateService.instant('ERROR_GOOGLEID_NOT_FOUND')
        //             ).subscribe(() => {
        //                 console.log(`ERROR in downloadMap: ${error.message}`);
        //             });
        //         }
        //     });

    }

    // saveMap() {
    //   this.saveMapService.saveMap(this.mapStateService.id, this.mapStateService.workJson, this.mapStateService.name);
    // }

    exportToSme() {
        if (!this.mapIsSaving) {
            this.exportSmeService.exportSme(this.mapStateService.id, this.mapStateService.workJson, this.mapStateService.name);
        }
    }

    undo() {
        this.dirtyDataService.undo();
    }

    redo() {
        this.quickEditService.disableAll();
        this.dirtyDataService.redo();
    }

    copyMapImage() {
        const msgKey = (this.convertToImageService.copyMapImage() ? 'IMAGE_COPIED' : 'IMAGE_NOT_COPIED');
        this.confirmationService.confirm(
            this.translateService.instant(extract('MAIN_MENU_EDIT-COPY-MAP-IMAGE')),
            this.translateService.instant(extract(msgKey)),
            this.translateService.instant(extract('BUTTON_OK')),
            '');
    }

    copySelection(type: string) {
        this.mapStateService.setCopyType(type);
        document.execCommand('copy');

        // modifica chrome iOS
        this.mapClipboardService.onCopy.emit();
    }

    cut() {
        document.execCommand('cut');
    }

    paste() {
        // if (this.userPreferenceService.userPrefs?.usePasteWithouKeyboard) {
        //     this.mapClipboardService.showPaste(true);
        // } else {
        // this.modalService.showCopyPastePopup();
        //}
        // this.mapClipboardService.pasteFragment();
        navigator.clipboard.read().then((data) => {
            this.mapClipboardService.pasteData(data);
        });
    }

    newpaste() {
        let logghino: string = "";

        navigator['clipboard'].read().then((data) => {
            data.forEach((item) => {
                item.types.forEach((tipo) => {
                    item.getType(tipo).then((b) => {
                        b.text().then((blobText) => {
                            logghino = "MIME TYPE: " + tipo + " CONTENUTO: " + blobText;
                            //open a dialog with the log
                            this.dialogLogghino(logghino);
                            console.log(logghino);
                        })
                    });
                });
            })
        })
    }

    dialogLogghino(log: string) {
        this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, "NUOVO PASTE", log);

    }

    delete() {
        this.smService.deleteSelection();
    }

    convertToImage(qrcodes: boolean) {
        this.convertToImageService.convert(true, qrcodes, this.mapStateService.id, this.mapStateService.googleDocFileId);
    }

    convertToImagePdf() {
        this.convertToImageService.convertToPdf(true, this.mapStateService.id, this.mapStateService.googleDocFileId);
    }

    convertToDocumentPdf() {
        this.createMapDocumentService.createMapDocument('pdf');
    }

    convertToDocumentDocxMicrosoft() {
        this.createMapDocumentService.createMapDocument('docx-ms');
    }

    convertToDocumentDocxLibreOffice() {
        this.createMapDocumentService.createMapDocument('docx-lo');
    }

    printMap(qrcodes: boolean) {
        this.printMapService.printMap(this.mapStateService.limitPrintSize, qrcodes);
    }

    changeFontColor(e: any) {
        this.selectedFontColor = e;
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.TEXTCOLOR, this.selectedFontColor);
    }

    changeColorFontBack(e: any) {
        this.selectedFontBackColor = e;
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.BACKCOLOR, this.selectedFontBackColor);
    }

    changeBackground(e: any) {
        this.selectedBackgroundColor = e;
        this.mapStateService.setBackgroundColor(e);
    }

    shareMap() {
        this.shareMapService.showShareMapDialog(this.mapStateService.id, this.mapStateService.name, true, true, this.mapStateService.isShared, false);
    }

    openRevision() {
        const id = this.mapStateService.id;
        this.mapStateService.mapPreview = this.smService.convertToImage(false, null, this.mapStateService.limitPrintSize, false);
        this.router.navigate(['/map-history', id]);
    }

    openDialog(urlToOpen: string, preview: string) {
        return new Observable((observer: Observer<boolean>) => {
            this.mapStateService.setEditingState(true);
            this.smService.setEnableKeyPresses(false);
            this.quickEditService.onCanEdit.emit(false);
            this.mapStateService.setFocusOnMap(false);
            // UnsavedChangesDialog defined somewhere else and imported above
            if (this.askNameSubscription) { this.askNameSubscription.unsubscribe(); }
            this.askNameSubscription = this.inputDialogService.askName(this.translateService.instant(extract('TITLE_RENAME_MAP')),
                this.translateService.instant(extract('INPUT_MAPNAME')), this.mapStateService.name,
                this.mapStateService.id, urlToOpen, '', preview)
                .subscribe({
                    next: (result) => {
                        observer.next(result);
                        observer.complete();
                        this.mapStateService.setFocusOnMap(true);
                        this.smService.setEnableKeyPresses(true);
                        this.quickEditService.onCanEdit.emit(true);
                    },
                    error: (error) => {
                        observer.next(false);
                        observer.complete();
                        this.mapStateService.setFocusOnMap(true);
                        this.smService.setEnableKeyPresses(true);
                        this.quickEditService.onCanEdit.emit(true);
                    }
                });
        });
    }

    renameMap() {
        if (this.renameMapSubscription) { this.renameMapSubscription.unsubscribe(); }
        this.renameMapSubscription = this.openDialog('', this.mapStateService.mapPreview)
            .subscribe((result: any) => {
                if (result && result.name.trim() && result.name !== this.mapStateService.name) {
                    const name = result.name.trim();
                    this.mapStateService.name = name;
                    this.mapStateService.workJson = this.smService.setMapName(name);
                    this.mapStateService.isDirty = true;
                    if (this.uploadWorkJsonSubscription) { this.uploadWorkJsonSubscription.unsubscribe(); }
                    this.smeService.findOwnerIdByMapId(this.mapStateService.id).subscribe((result2: any) => {
                        const ownerId = result2.ownerId;
                        this.uploadWorkJsonSubscription = this.smeService.uploadWorkJson(ownerId, this.mapStateService.id, this.mapStateService.workJson).subscribe(() => {
                            this.mapStateService.isDirty = false;
                        });

                        if (this.smeServiceRenameMapSubscription) { this.smeServiceRenameMapSubscription.unsubscribe(); }
                        this.smeServiceRenameMapSubscription = this.smeService.renameMap(this.mapStateService.id, this.mapStateService.name + '.sme').subscribe(() => {
                            this.mapStateService.forceAutoSave();
                            // logger.info('Map name changed');
                            const newTitle: string = result2.name + ' - ' + this.translateService.instant(extract('MAPEDIT_TITLE'));
                            this.titleService.setTitle(newTitle);
                        });

                    });
                }
                this.mapStateService.setEditingState(false);
            });

    }

    changeMapLanguage(l: CustomSelectElement) {
        this.selectedMapLanguage = l;
        this.selectedMapLanguageIcon = (this.selectedMapLanguage ? this.selectedMapLanguage.icon : '');
        this.smService.setDefaultLanguage(l.value);
        this.mapStateService.isDirty = true;
    }

    changeSelectionLanguage(event: any) {
        this.selectionLanguage = event;
        this.smService.setSelectionLanguage(event.value);
        this.mapStateService.isDirty = true;
    }

    changeSelection(_selectionData: any): any {
        this.selectionData = _selectionData;
        this.isSelectionPresent = (this.selectionData.ids && this.selectionData.ids.length > 0);
        this.isSingleNodeSelected = this.selectionData && (this.selectionData.nNodes + this.selectionData.nNodesImage + this.selectionData.nNodesImageFrame + this.selectionData.nNodesText + this.selectionData.nCells + this.selectionData.nCellsImage === 1);
        this.isSingleEdgeSelected = (this.selectionData.nEdges === 1);
        this.isMultiSelectionPresent = (this.selectionData.ids && this.selectionData.ids.length > 1);
        this.isOnlyEdgeSelected = (this.isSelectionPresent && this.selectionData.nEdges === this.selectionData.ids.length);
        const customLanguage = _selectionData.language;
        this.noSelection = _selectionData != null && _selectionData.noSelection;
        this.canCopy = _selectionData != null && !_selectionData.noSelection;
        this.multiselection = _selectionData != null && _selectionData.multiSelection;
        this.canArrange = _selectionData != null && (_selectionData.canArrange || (_selectionData.multiSelection && !_selectionData.edgeSelected));
        this.canAlign = this.canArrange && _selectionData.nodeSelected;
        this.canResize = this.canArrange;
        this.canDelete = this.canCopy;
        this.canOrder = this.canCopy;
        this.selectionLanguage = UiConstants.findLanguage(customLanguage, this.languages);
        this.fontFamily = _selectionData.textInfo.fontName;
        const fonts = _selectionData.textInfo.fontName.split(',');
        if (fonts.length > 0) {
            this.fontFamily = fonts[0].replace(/"/g, '');
        } else {
            this.fontFamily = '';
        }

        this.fontSize = _selectionData.textInfo.fontSize;

        const fontSizePx = parseInt(this.fontSize, 10);
        const size = Math.round(fontSizePx * 3 / 4) + '';
        this.selectedFontSize = size;
        this.color = _selectionData.textInfo.color;
        this.backgroundColor = _selectionData.textInfo.backgroundColor;
        this.isBold = _selectionData.textInfo.isBold;
        this.isItalic = _selectionData.textInfo.isItalic;
        this.isUnderline = _selectionData.textInfo.isUnderline;
        this.isSuperscript = _selectionData.textInfo.isSuperscript;
        this.isSubscript = _selectionData.textInfo.isSubscript;
        this.isFramed = _selectionData.isFramed;
        this.nodeSelected = _selectionData.nodeSelected || _selectionData.isTableSelected;
        this.noNodeSelected = (this.nodeSelected && !_selectionData.nodeSelected);
        this.noShapedNodeSelected = (this.selectionData && (this.selectionData.nNodes + this.selectionData.nNodesText) === 0);
        this.edgeSelected = _selectionData.edgeSelected;
        this.imageSelected = _selectionData.imageSelected;
        this.isTableSelected = _selectionData.isTableSelected;
        this.edgeCurveSelected = _selectionData.originalSelection.edgeCurve;
        this.cellSelected = _selectionData.nCells > 0;
        this.isTableLocked = this.isSingleNodeSelected && _selectionData.originalSelection.element &&
            _selectionData.originalSelection.element.nodeTable && _selectionData.originalSelection.element.nodeTable.tableId &&
            _selectionData.originalSelection.element.locked;
        if (this.isTableSelected && !_selectionData.ImageSelected && this.selectionData.nCellsImage === 0) {
            // Remove from topping list
            this.textPositions = [];
            this.tableTextPositions.forEach(element => {
                this.textPositions.push(element);
            });
        } else {
            this.textPositions = [];
            this.baseTextPositions.forEach(element => {
                this.textPositions.push(element);
            });
        }
        if (_selectionData.edgeSelected) {
            if (_selectionData.edgeType === 'both') {
                this.edgeArrowLeftChecked = true;
                this.edgeArrowRightChecked = true;
            } else if (_selectionData.edgeType === 'forward') {
                this.edgeArrowLeftChecked = false;
                this.edgeArrowRightChecked = true;
            } else if (_selectionData.edgeType === 'backward') {
                this.edgeArrowLeftChecked = true;
                this.edgeArrowRightChecked = false;
            } else {
                this.edgeArrowLeftChecked = false;
                this.edgeArrowRightChecked = false;
            }
        }
        this.isLinkPresent = (this.selectionData && this.selectionData.originalSelection && this.selectionData.originalSelection.class === 'node' && this.selectionData.originalSelection.element && this.selectionData.originalSelection.element.links && this.selectionData.originalSelection.element.links.length > 0);
        this.isTitleTextPresent = (this.selectionData.titleText && this.selectionData.titleText !== '' ? true : false);
        this.isDeepTextPresent = (this.selectionData.deepHtml && this.selectionData.deepHtml !== '' && this.selectionData.deepHtml !== undefined ? true : false);
        this.isImagePresent = (/* this.selectionData.class === 'node' && */ this.selectionData.nodeType === 'image');
        this.isCopyExtended = ((this.isSingleNodeSelected || this.isSingleEdgeSelected) && (this.isTitleTextPresent || this.isDeepTextPresent || this.isImagePresent));
        if (this.isSingleNodeSelected && this.selectionData.originalSelection.element) {
            this.mainLinkIsVideo = (this.selectionData.originalSelection.element.mainLinkType === 'video');
        }
        this.scrCenterX = this.selectionData.originalSelection.scrCenterX;
        this.scrCenterY = this.selectionData.originalSelection.scrCenterY;
    }

    zoomFit() {
        this.smService.resizeMap();
    }

    zoom100() {
        this.smService.changeZoomFactor(1, true);
        this.smService.redraw();
    }

    zoom(direction: number) {
        this.zoomService.zoom(direction);
    }

    setFoldingState(open: boolean) {
        this.mapFoldingService.setFoldingState(open);
    }

    addNode() {
        this.smService.addNodeAndSelectIfNoSelection('');
    }

    openNewTableDialog() {
        return new Observable((observer: Observer<any>) => {
            // UnsavedChangesDialog defined somewhere else and imported above
            if (this.askRowColSubscription) { this.askRowColSubscription.unsubscribe(); }
            this.askRowColSubscription = this.newTableService.askColRow(this.translateService.instant(extract('GENERIC_TOOLBAR_INSERT_NEW_TABLE')))
                .subscribe({
                    next: result => {
                        observer.next(result);
                        observer.complete();
                    },
                    error: (error) => {
                        observer.next(false);
                        observer.complete();
                    }
                });
        });

    }

    addTable() {
        this.mapStateService.setCanEnableQuickEdit(false);
        if (this.openNewTableDialogSubscription) { this.openNewTableDialogSubscription.unsubscribe(); }
        this.openNewTableDialogSubscription = this.openNewTableDialog().subscribe((res: any) => {
            if (res) {
                this.smService.addNewTable(res.col, res.row);
            }
            this.mapStateService.setCanEnableQuickEdit(true);
        });
    }

    quickEdit() {
        this.quickEditService.toggleQuickEdit();
    }

    speechEdit() {
        this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.MAP);
    }

    changeFontFamily(font: string) {
        const fontFamily = font.replace(/"/g, '');
        this.fontFamily = fontFamily;
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.FONT, fontFamily);
    }

    changeFontSize(size: string) {
        const fontSizePx = parseInt(size, 10);
        const fontSize = Math.round(fontSizePx * 4 / 3) + 'px';
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.FONTSIZE, fontSize);
    }

    arrangeMap() {
        this.arrangeService.arrangeMap();
    }

    textBold() {
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.BOLD, !this.isBold);
    }

    textItalic() {
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.ITALIC, !this.isItalic);
    }

    textUnderline() {
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.UNDERLINE, !this.isUnderline);
    }

    changeSelectionBackcolor(e: any) {
        this.smService.setNodeBackgroundColor(e);
    }

    changeSelectionColor(e: any) {
        this.smService.setBorderColor(e);
    }

    changeNodeShape(value: any) {
        this.smService.setSelectionShape(value);

    }
    changeBorderWidth(value: any) {
        this.smService.setSelectionWidth(value);
    }

    changeTextPosition(value: any) {
        this.smService.setSelectionTextLayout(value);
    }

    removeImage() {
        this.smService.setSelectionNoImage();
    }

    public deleteText() {
        this.smService.deleteTitleSelection();
    }

    public deleteLink() {
        this.linksService.deleteLink();
    }

    public deleteDeepText() {
        const data = { titleHtml: this.selectionData.titleHtml, deepHtml: '', deleteDeep: true };
        this.smService.editSelectedElement(data);
    }

    openBlockNotes() {
        this.extraService.toggleExtraPanel(ExtraItem.OPEN_NOTEPAD);
    }

    openOutline() {
        this.extraService.toggleExtraPanel(ExtraItem.OPEN_OUTLINE);
    }

    openPdf() {
        this.extraService.toggleExtraPanel(ExtraItem.OPEN_PDF);
    }

    openChat() {
        this.chatService.toggleChatPanel();
    }

    toggleFrameImage() {
        this.smService.setSelectedNodesImageFrame(!this.isFramed);
    }

    setSelectedEdgesTerminals(param: string) {
        if (param === 'left') {
            this.edgeArrowLeftChecked = !this.edgeArrowLeftChecked;
        }
        if (param === 'right') {
            this.edgeArrowRightChecked = !this.edgeArrowRightChecked;
        }
        this.smService.setSelectedEdgesTerminals(this.edgeArrowLeftChecked, this.edgeArrowRightChecked);
    }

    breakEdge() {
        setTimeout(() => {
            this.smService.breakEdge();
        }, 10);
    }

    curvedEdge(curved: boolean) {
        this.smService.curveEdge(curved);
    }

    removeTable() {
        if (!this.isTableLocked) {
            this.smService.deleteTable();
        }
    }

    removeRow() {
        if (!this.isTableLocked) {
            this.smService.deleteRow();
        }
    }

    removeCol() {
        if (!this.isTableLocked) {
            this.smService.deleteColumn();
        }
    }

    addTableRowUp() {
        if (!this.isTableLocked) {
            this.smService.addTableRowUp();
        }
    }

    addTableRowDown() {
        if (!this.isTableLocked) {
            this.smService.addTableRowDown();
        }
    }

    addTableColumnRight() {
        if (!this.isTableLocked) {
            this.smService.addTableColumnRight();
        }
    }

    addTableColumnLeft() {
        if (!this.isTableLocked) {
            this.smService.addTableColumnLeft();
        }
    }

    lockTable(lock: boolean) {
        this.smService.lockTable(lock);
    }

    bringSelectionToFront() {
        this.smService.bringSelectionToFront();
    }

    sendSelectionToBack() {
        this.smService.sendSelectionToBack();
    }

    changeAlign(value: any) {
        this.smService.alignSelection(value);
    }

    changeSize(value: any) {
        this.smService.resizeSelection(value);
    }

    TTSRead() {
        if (this.smService) {
            this.smService.toggleTTS();
            this.ttsEnable = this.smService.isTTSEnabled();
            if (this.ttsEnable) {
                this.ttsService.speak(this.translateService.instant("TTS_ENABLED_SPEECHSYNTHESIS_MESSAGE"), this.translateService.currentLang);
            } else {
                this.ttsService.stop();
            }
        }
    }

    editText() {
        if (this.noSelection) {
            this.smService.addNodeAndSelect(this.scrCenterX, this.scrCenterY);
        }
        this.smService.requestEditForCurrentElement();
    }

    editDeep() {
        if (this.selectionData.originalSelection.deepHtml === undefined) this.selectionData.originalSelection.deepHtml = '';
        this.modalService.showDeepContent(this.selectionData.originalSelection.id, this.selectionData.originalSelection.deepHtml, this.selectionData.originalSelection.deepText, this.selectionData.originalSelection.language);
    }

    public editMath() {
        this.mathService.editMath();
    }

    public editLink() {
        this.linksService.editLink();
    }

    editLinkGoogleDrive(type: string) { // type: 'drive' or 'map'
        this.linksService.editLinkGoogleDrive(type);
    }

    public searchOnWeb(type: string, _nodeSelected?: boolean) {
        if (_nodeSelected || this.selectionData) {
            this.webSearchService.textSearch = this.selectionData.titleText;
        } else {
            this.webSearchService.textSearch = '';
        }
        this.webSearchService.searchOnWeb(type);
        this.webSearchService.disableMagicSearch();
    }

    public searchOnInternet() {
        this.webSearchService.toggleWebSearchPanel();
    }

    public openMap() {
        const redirectUri = window.location.origin + '/gdrive-picker';
        window.open(redirectUri, '_blank');
    }

    public newMap() {
        const redirectUri = window.location.origin + '/map-new';
        window.open(redirectUri, '_blank');
    }

    public backToHome() {
        window.location.href = window.location.origin + '/home';
    }

    findInMap() {
        this.mapFindService.openFindText();
    }

    public createGoogleDoc() {
        this.createGoogleDocService.createGoogleDoc();
    }

    public openMapPreferences() {
        this.modalService.showDialogPreferences();
    }

    createOrUpdateGoogleDoc() {
        this.createGoogleDocService.createGoogleDoc();
    }

    openGoogleDoc() {
        this.createGoogleDocService.openGoogleDoc(this.mapStateService.googleDocFileId);
    }

    limPreference(value: boolean) {
        if (this.userPreferenceService.userPrefs) {
            this.userPreferenceService.userPrefs.usePasteWithouKeyboard = value;
        }
        this.pasteMap = value;
        if (this.userPreferenceService.userPrefs) {
            this.userPreferenceService.setUserPreference(this.userPreferenceService.userPrefs);
        }
    }

    // openTouchChrome() {
    //   const uri = 'https://www.anastasis.it/faq-supermappex/';
    //   window.open(uri, '_blank');
    // }

    showQRCode(type: string) {
        let link = '';
        if (type === 'map') {
            link = this.shareLinkService.getLinkToShare(this.mapStateService.id);
        } else {
            link = 'https://docs.google.com/document/d/' + this.mapStateService.googleDocFileId + '/edit';
        }
        this.modalService.showQRCode(type.toLocaleUpperCase(), link, 500);
    }

    public getPrintQuality(): number {
        return this.mapStateService.getPrintQuality();
    }

    public setPrintQuality(level: number) {
        this.mapStateService.setPrintQuality(level);
    }

    public getImageFilter(): string {
        return this.webSearchService.getImageFilter();
    }

    public setImageFilter(filter: string) {
        this.webSearchService.setImageFilter(filter);
        this.webSearchService.refreshSearchOnWebIfActive();
    }

    cropImage() {
        this.modalService.showSelectedNodeImageDialogCropper();
    }

    downloadChromeLink(so: string) {
        this.firebaseService.downloadChromeLink(so);
    }

    setGrid() {
        this.mapStateService.isGridVisible = !this.mapStateService.isGridVisible;
        this.mapStateService.onGridChange.emit(this.mapStateService.isGridVisible);
    }
}
