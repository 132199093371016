import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, input } from '@angular/core';
import { PrintMapService } from '../../../shared/commands/print-map.service';
import { SmService } from 'supermappe-core';
import { ConvertToImageService } from '../../../shared/commands/convert-to-image.service';
import { TtsService } from '../../../shared/commands/tts.service';
import { FbService } from '../../../shared/commands/fb.service';
import { ZoomService } from '../../../shared/commands/zoom.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { MapFoldingService } from '../../../shared/commands/map-folding.service';
// import { Logger } from '../../../core/logger.service';
import { MapStateService } from '../../../shared/map-state.service';
import { CopyAndOpenMapService } from '../../../shared/commands/copy-and-open-map.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShareMapService } from '../../../shared/share-map/share-map.service';
import { MapShowService } from '../../../map-show/map-show.service';
import { MapFindService } from '../../../shared/map-find/map-find.service';
import { FirebaseService } from '../../../core/firebase/firebase.service';
import { SmeService } from 'src/app/core/sme/sme.service';
import { MessageBoxService } from 'src/app/shared/dialog/messagebox.service';
import { TranslateService } from '@ngx-translate/core';

// const logger: Logger = new Logger('MapEditComponent');

@Component({
    selector: 'app-view-toolbar',
    templateUrl: './view-toolbar.component.html',
    styleUrls: ['./view-toolbar.component.scss']
})
export class ViewToolbarComponent implements OnInit, OnDestroy {
    @Input() mapId = '';
    @Input() viewToolbarCompressed = false;
    @Output() startLoading = new EventEmitter<void>();
    @Output() endLoading = new EventEmitter<boolean>();
    @Input() isLoading = true;

    ttsEnable = false;
    canCopy = false;
    canEdit = false;
    isLogged = false;
    mapSearchVisible = false;

    onLoadedSubscription: Subscription | undefined;
    openFindInMapSubscrition: Subscription | undefined;
    onSwitchToEditShowSubscription: Subscription | undefined;

    // isHandMode: boolean;
    selectiondata: any;
    mapStateOpen = false;

    public isUserExpired = false;

    constructor(private router: Router,
        private smService: SmService,
        private convertToImageService: ConvertToImageService,
        private shareMapService: ShareMapService,
        private ttsService: TtsService,
        private printService: PrintMapService,
        private zoomService: ZoomService,
        private mapFoldingService: MapFoldingService,
        private authenticationService: AuthenticationService,
        private mapStateService: MapStateService,
        private copyAndOpenMapService: CopyAndOpenMapService,
        private fbService: FbService,
        private mapFindService: MapFindService,
        private mapShowService: MapShowService,
        private firebaseService: FirebaseService,
        private translateService: TranslateService) {
    }

    ngOnInit() {
        this.isUserExpired = this.authenticationService.isUserExpired();
        this.ttsEnable = false;
        // this.isHandMode = false;
        this.mapStateOpen = true;
        this.isLogged = this.authenticationService.isAuthenticated();
        this.canEdit = false;
        this.canCopy = true;
        this.onLoadedSubscription = this.mapStateService.onLoaded.subscribe((loaded: any) => {
            if (loaded && this.mapStateService.readOnly !== undefined) {
                this.canEdit = this.isLogged && !this.mapStateService.readOnly && !this.isUserExpired;
                this.canCopy = this.isLogged && this.mapStateService.readOnly && !this.isUserExpired;

            }
        });

        if (this.isLogged) {
            this.fbService.initShareMap(this.mapId);
        }
        this.openFindInMapSubscrition = this.mapFindService.openFindInMap
            .subscribe((show: boolean) => {
                this.mapSearchVisible = show;
            });


        this.onSwitchToEditShowSubscription = this.mapShowService.onSwitchToEditShow.subscribe((isEditShow: boolean) => {
            if (this.mapSearchVisible) {
                this.mapSearchVisible = false;
            }
        });
    }

    ngOnDestroy() {
        if (this.onLoadedSubscription) { this.onLoadedSubscription.unsubscribe(); }
        if (this.openFindInMapSubscrition) { this.openFindInMapSubscrition.unsubscribe(); }
        if (this.onSwitchToEditShowSubscription) { this.onSwitchToEditShowSubscription.unsubscribe(); }
    }

    centerMap() {
        this.smService.resizeMap();
    }

    zoom(direction: number) {
        this.zoomService.zoom(direction);
    }

    TTSRead() {
        if (this.smService) {
            this.smService.toggleTTS();
            this.ttsEnable = this.smService.isTTSEnabled();
            if (this.ttsEnable) {
                this.ttsService.speak(this.translateService.instant("TTS_ENABLED_SPEECHSYNTHESIS_MESSAGE"), this.translateService.currentLang);
            } else {
                this.ttsService.stop();
            }
        }
    }

    printMap(qrcodes: boolean) {
        this.printService.printMap(this.mapStateService.limitPrintSize, qrcodes);
    }

    convertToImage(qrcodes: boolean) {

        this.convertToImageService.convert(true, qrcodes, this.mapStateService.id, this.mapStateService.googleDocFileId);
    }

    shareMap() {
        this.shareMapService.showShareMapDialog(this.mapStateService.id, this.mapStateService.name, false, true, this.mapStateService.isShared, false);
    }

    setFoldingState(open: boolean) {
        this.mapFoldingService.setFoldingState(open);
    }

    addMapEvent(type: string, payload: string) {
        const userEmail = this.authenticationService.getUserEmail();
        const userName = this.authenticationService.getUserName();
        const userIcon = this.authenticationService.getUserImageUrl();
        return this.firebaseService.addMapEvent('no-guid', type, userEmail, userName, userIcon, this.mapStateService.id, payload);
    }

    navigateToEdit() {
        this.addMapEvent('user_out', '').then(() => {
            if (this.isLogged && !this.authenticationService.isAuthenticated()) {
                this.router.navigate(['login', 'map-edit/' + this.mapStateService.id]);
            } else {
                window.location.href = 'map-edit/' + this.mapStateService.id;
            }
            // this.router.navigate(['home']);
        }).catch((error) => {
            if (!this.authenticationService.isAuthenticated()) {
                this.router.navigate(['login', 'map-edit/' + this.mapStateService.id]);
            }
        });
        // this.router.navigate(['map-edit', this.mapStateService.id]);
        // window.location.href = 'map-edit/' + this.mapStateService.id;
    }


    copyMap() {
        if (this.isLogged && this.authenticationService.isAuthenticated()) {
            //  this.copyAndOpenMapService.copyAndOpenMap(this.mapStateService.id, this.mapStateService.name);
            this.startLoading.emit();
            //  this.copyAndOpenMapService.endLoading = this.endLoading;
            this.copyAndOpenMapService.copyAndOpenMap(this.mapStateService.id, this.mapStateService.name, undefined, () => { this.endLoading.emit(); }).then(() => {
                this.endLoading.emit(true);
            }).catch((err) => {
                this.endLoading.emit(false);
            });
        } else {
            this.router.navigate(['/login', 'map-view/' + this.mapStateService.id]);
        }
    }

    mapShowing() {
        // this.toogleFullScreenService.goFullscreen();
        // this.router.navigate(['map-show', this.mapStateService.id]);
        this.mapShowService.showing(this.mapStateService.id);
    }

    navigateToLogin() {
        this.router.navigate(['login', 'map-view/' + this.mapStateService.id]);
    }

    toggleFindInMap() {
        this.mapFindService.toggleFindText();
    }

}
