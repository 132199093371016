import { Component, OnInit, Input, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { FbService } from '../commands/fb.service';
import { SmeService } from '../../core/sme/sme.service';
import { Logger } from '../../core/logger.service';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { ShareLinkService } from '../commands/share-link.service';
import { MapStateService } from '../map-state.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationService } from '../dialog/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { ModalService } from '../links-menu/modal-service';
import { MapOperationType } from '../map-operations';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { MessageBoxService } from '../dialog/messagebox.service';
import {
    MatSnackBar,
} from '@angular/material/snack-bar';


export class UserItem {
    caller: any;
    isOwner: boolean = false;
    picture: string = '';
    name: string = '';
    email: string = '';
    private _role: string = '';
    public get role(): string {
        return this._role;
    }
    public set role(value: string) {
        this._role = value;
        switch (value) {
            case 'owner':
                this.isOwner = true;
                this._internalRole = 'owner';
                break;
            case 'writer':
                this._internalRole = 'edit';
                break;
            case 'reader':
            case 'commenter':
                this._internalRole = 'view';
                break;
        }
        if (!this.caller.isLoading) {
            this.caller.askSaveData();
        }
    }
    private _internalRole: string = '';
    public get internalRole(): string {
        return this._internalRole;
    }
    public set internalRole(value: string) {
        this._internalRole = value;
        switch (value) {
            case 'remove':
                this.caller.deleteUser(this);
                break;
            case 'view':
                this._role = 'reader';
                break;
            case 'edit':
                this._role = 'writer';
                break;
        }
        if (!this.caller.isLoading) {
            this.caller.askSaveData();
        }
    }
}

const logger: Logger = new Logger('ShareMapComponent');

@Component({
    selector: 'app-share-map',
    templateUrl: './share-map.component.html',
    styleUrls: ['./share-map.component.scss']
})

export class ShareMapComponent implements OnInit, OnDestroy {

    @ViewChild('addUserNameField') private addUserNameField: ElementRef | undefined;

    @Input() mapId: string | undefined;
    @Input() mapName: string | undefined;
    @Input() googleUri: string | undefined;
    @Input() showClassroom = false;
    @Input() showFacebook = false;
    @Input() showOnlyQrCode = false;

    @ViewChildren('tooltip') tooltips: any;

    private saveDataId: any = null;

    public addUserEmail = '';
    public addUserIsValid = false;
    public loadUsersList = new Array<UserItem>;
    public saveUsersList = new Array<UserItem>;
    public shareEmails = new Array<string>;
    public shareEmailsFreq = new Array<string>;
    public shareEmailsGroup = new Array<string>;

    public urlToShare = '';

    private _publicShareType = 'none';
    public get publicShareType() {
        return this._publicShareType;
    }
    public set publicShareType(value) {
        this._publicShareType = value;
        if (!this.isLoading) {
            this.saveData();
        }
    }

    public shareTitle = '';
    public canDo = '';
    public isLoading = false;
    public isLogged: boolean;
    public isShared = false;
    public linkCopied: boolean;
    // public isFirstLoggedUser: boolean;
    public isMapPubliclyShared: boolean;
    public isSharing = false;

    public getShareDataSubscription: Subscription | undefined;
    public insertMapSharedSubscription: Subscription | undefined;
    private stopSharingSubscription: Subscription | undefined;
    private statShareSubscription: Subscription | undefined;

    public isLinkGenerated: boolean;

    public shareType = 'view';

    public viewShareType = { value: 'view', name: this.translateService.instant('SHARE_LINK_READONLY'), icon: 'visibility' };
    public editShareType = { value: 'edit', name: this.translateService.instant('SHARE_LINK_EDIT'), icon: 'edit' };
    public noneShareType = { value: 'none', name: this.translateService.instant('SHARE_LINK_NONE'), icon: 'lock' };
    public removeShareType = { value: 'remove', name: this.translateService.instant('SHARE_LINK_REMOVE') };

    public addShareTypesOptions = [
        this.viewShareType,
        this.editShareType
    ];

    public editShareTypesOptions = [
        this.viewShareType,
        this.editShareType,
        this.removeShareType
    ];

    public publicShareTypesOptions = [
        this.noneShareType,
        this.viewShareType,
        this.editShareType
    ];

    public origShowFacebook = false;
    public data: any = null;
    public isMapMine = false;
    public canShare = false;
    public accessToken = '';

    filteredOptions: Observable<string[]> | null = null;
    suggestedEmails = new Array<string>();

    constructor(
        private fbService: FbService,
        private smeService: SmeService,
        public mapStateService: MapStateService,
        private shareLinkService: ShareLinkService,
        private dialogRef: MatDialogRef<ShareMapComponent>,
        public authenticationService: AuthenticationService,
        private confirmationService: ConfirmationService,
        private translateService: TranslateService,
        private modalService: ModalService,
        private messageBoxService: MessageBoxService,
        private _snackBar: MatSnackBar
    ) {
        this.isLogged = this.authenticationService.isAuthenticated();
        this.linkCopied = false;
        this.urlToShare = '';
        this.showOnlyQrCode = false;
        this.isLinkGenerated = false;
        // this.isFirstLoggedUser = false;
        this.isMapPubliclyShared = false;
    }

    async ngOnInit() {
        this.isLoading = true;
        this.origShowFacebook = this.showFacebook;
        this.showFacebook = false;
        this.mapStateService.setEditingState(true);
        // if (document.querySelector('.mdc-button')) {
        //   const buttonRipple = new MDCRipple(document.querySelector('.mdc-button'));
        // }
        if (this.mapId) {
            await this.fbService.initShareMap(this.mapId);
        }
        // this.googleService.isFirstLoggedUser().then((_isFirstLoggedUser: any) => {
        //     this.isFirstLoggedUser = _isFirstLoggedUser;
        // });

        this.loadData();
        if (this.isLogged) {
            this.generateLink().then(() => {
                if (this.showOnlyQrCode) {
                    this.closeShareMap();
                    this.showQRCodeAndCopyLink();
                }
            });
        } else {
            this.isLinkGenerated = true;
            this.urlToShare = window.location.href;
            this.closeShareMap();
            this.showQRCodeAndCopyLink();
        }
    }

    ngOnDestroy() {
        this.mapStateService.setEditingState(false);
        if (this.insertMapSharedSubscription) { this.insertMapSharedSubscription.unsubscribe(); }
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
    }

    loadData() {
        this.isLoading = true;
        const email = this.authenticationService.getUserEmail();
        const mapId = this.mapId ? this.mapId : '';
        const firebaseUserId = this.authenticationService.credentials?.firebaseUserId + '';
        const unknownUsername = this.translateService.instant('UNKNOWN_USERNAME');
        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
        this.getShareDataSubscription = this.smeService.getShareData(email, firebaseUserId, mapId).subscribe((data: any) => {
            this.data = { ...data };
            this.loadUsersList = [];
            if (data && data.shareData) {
                this.isShared = data.shareData.isShared;
                this.shareType = (data.shareData.shareType === 'edit' ? 'edit' : 'view');
                this.isMapMine = data.shareData.isMine;
                this.canShare = data.shareData.canShare;
                this.isMapPubliclyShared = data.shareData.isPubliclyShared;
            } else {
                this.isShared = false;
                this.shareType = '';
                this.isMapMine = true;
                this.canShare = false;
                this.isMapPubliclyShared = false;
            }
            if (this.canShare) {
                this.showFacebook = this.origShowFacebook;
            } else {
                this.showClassroom = false;
            }
            for (let i = 0; i < data.shareData.shares.length; i++) {
                const share = data.shareData.shares[i];
                if (share.trustee === 'anyoneWithLink') {
                    this.publicShareType = (share.role === 'writer' ? 'edit' : 'view');
                } else {
                    const user = new UserItem();
                    user.caller = this;
                    user.email = share.trustee;
                    user.name = share.fullName || unknownUsername;
                    user.picture = share.picture;
                    user.role = share.role;
                    this.loadUsersList.push(user);
                }
            }
            this.saveUsersList = [...this.loadUsersList];
            this.updateFilter();
            this.isLoading = false;
        });
    }

    shareFacebook() {
        if (this.isLinkGenerated) {
            this.isSharing = true;
            this.fbService.shareMap(this.urlToShare);
            if (this.isLogged && this.mapId) {
                if (this.insertMapSharedSubscription) { this.insertMapSharedSubscription.unsubscribe(); }
                this.insertMapSharedSubscription = this.smeService.insertMapShared(this.mapId, 'view').subscribe((resp: any) => {
                    this.mapStateService.isShared = true;
                    const data = { mapId: this.mapId, shareType: 'view' };
                    this.mapStateService.startSharing.emit(data);
                    logger.debug(resp);
                    this.closeShareMap();
                    // add stat
                    if (this.mapId) {
                        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
                        this.statShareSubscription = this.smeService.addCustomMapStat(this.mapId, MapOperationType.STAT_SHAREFB, this.authenticationService.getUserEmail()).subscribe((_data: any) => { });
                    }
                    this.isSharing = false;
                });
            } else {
                this.isSharing = false;
                this.closeShareMap();
            }
        }
    }

    shareClassroom() {
        if (this.mapId) {
            window.open(`${window.origin}/share-classroom/${this.mapId}`, '_blank');
        }
    }

    closeShareMap() {
        this.dialogRef.close();
    }

    copyToClipboard() {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.value = this.urlToShare;
        input.select();
        document.execCommand('copy');
        window.getSelection()?.removeAllRanges();
        document.body.removeChild(input);
    }

    generateLink() {
        return new Promise((resolve, reject) => {
            this.shareLinkService.writeStaticMap(this.mapId ? this.mapId : '', this.mapStateService.name, this.mapStateService.author)
                .then((result: any) => {
                    this.urlToShare = result.url;
                    this.isLinkGenerated = true;
                    return resolve(null);
                })
                .catch((err) => {
                    console.log('ERROR: ' + err);
                    this.closeShareMap();
                    return reject();
                });
        });
    }

    copyLink(showDone: boolean) {
        if (this.isLinkGenerated) {
            this.isLoading = true;
            this.isSharing = true;
            this.copyToClipboard();
            this.isLoading = false;
            if (showDone) {
                let message = this.translateService.instant(extract('LINK_COPIED'));
                if (!this.isShared) {
                    message += '\n\n' + this.translateService.instant(extract('SHAREMAP_WARNING_NOT_SHARED'));
                }
                this.confirmationService.confirm(
                    this.translateService.instant(extract('MAIN_MENU_FILE-SHARE')),
                    message,
                    this.translateService.instant(extract('BUTTON_OK')),
                    '');
            }
        }
    }

    showQRCodeAndCopyLink() {
        if (this.isLinkGenerated && this.mapId) {
            // this.isSharing = true;
            this.copyLink(false);
            const link = this.shareLinkService.getLinkToShare(this.mapId);
            // this.isSharing = false;
            let message = '';
            if (!this.showOnlyQrCode && !this.isShared) {
                message += this.translateService.instant(extract('SHAREMAP_WARNING_NOT_SHARED'));
            }
            this.modalService.showQRCode('MAP', link, 500, message);
            // this.isMapPubliclyShared = true;
        }
    }

    // manageSharings() {
    //     this.googleService.showShareDialog(this.mapStateService.googleFileId);
    // }

    // stopSharing() {
    //     if (this.mapId) {
    //         this.isSharing = true;
    //         if (this.stopSharingSubscription) { this.stopSharingSubscription.unsubscribe(); }
    //         this.stopSharingSubscription = this.smeService.stopSharing(this.mapId).subscribe((res: any) => {
    //             if (this.mapId) {
    //                 if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
    //                 const email = this.authenticationService.getUserEmail();
    //                 const firebaseUserId = this.authenticationService.credentials?.firebaseUserId + '';
    //                 this.getShareDataSubscription = this.smeService.getShareData(email, firebaseUserId, this.mapId).subscribe((_data: any) => {
    //                     if (_data && _data.shareData) {
    //                         this.mapStateService.isShared = true;
    //                         this.isShared = true;
    //                         this.shareType = (_data.shareData.shareType === 'edit' ? 'edit' : 'view');
    //                         this.isMapMine = _data.shareData.isMine;
    //                         this.canShare = _data.shareData.canShare;
    //                     } else {
    //                         this.mapStateService.isShared = false;
    //                         this.isShared = false;
    //                         this.shareType = 'view';
    //                         this.isMapMine = true;
    //                         this.canShare = false;
    //                     }
    //                     this.isSharing = false;
    //                     this.closeShareMap();
    //                     this.confirmationService.confirm(
    //                         this.translateService.instant(extract('MAIN_MENU_FILE-SHARE')),
    //                         this.translateService.instant(extract('SHARING_STOPPED')),
    //                         this.translateService.instant(extract('BUTTON_OK')),
    //                         '');
    //                 }, (error: any) => {
    //                     this.isSharing = false;
    //                     logger.error('stopSharing().subscribe: ' + error);
    //                 });
    //             }
    //         });
    //     }
    // }

    warnUser() {
        const userMail = this.authenticationService.getUserEmail();
        this.messageBoxService.googleShareNotFirstUserWarning(userMail);
    }

    userCantShare() {
        this.messageBoxService.showUserCantShare();
    }

    private isValidEmail(email: string): boolean {
        const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return regex.test(email);
    }

    onAddUserKeyUp($event: any) {
        this.addUserEmail = $event.target.value.trim();
        this.addUserIsValid = this.isValidEmail(this.addUserEmail);
        if ($event.keyCode === 13) {
            this.addUser();
        } else {
            this.updateFilter();
        }
    }

    onAddUserChange($event: any) {
        this.addUserEmail = $event.target.value.trim();
        this.addUserIsValid = this.isValidEmail(this.addUserEmail);
        this.updateFilter();
    }

    optionSelected(value: string) {
        // console.log('+++++value: ' + value);
    }

    updateFilter() {
        this.shareEmails = new Array<string>();
        this.shareEmailsFreq = new Array<string>();
        this.shareEmailsGroup = new Array<string>();

        let isFreq: boolean = false;
        let isGroup: boolean = false;

        if (this.data && this.data.shareData && this.data.shareData.shareEmails) {
            for (let i = 0; i < this.data.shareData.shareEmails.length; i++) {
                const email = this.data.shareData.shareEmails[i]
                if (email.startsWith('%FREQ')) {
                    isFreq = true;
                    isGroup = false;
                }
                else if (email.startsWith('%GROUP')) {
                    isFreq = false;
                    isGroup = true;
                }
                else if (email.includes(this.addUserEmail)) {
                    if (isFreq) {
                        this.shareEmailsFreq.push(email);
                    }
                    else if (isGroup) {
                        this.shareEmailsGroup.push(email);
                    }
                }
            }
        }
    }

    async addUser() {
        this.addUserEmail = this.addUserNameField?.nativeElement.value.trim();
        this.addUserIsValid = this.isValidEmail(this.addUserEmail);
        if (this.addUserIsValid) {
            const user = new UserItem();
            user.caller = this;
            user.email = this.addUserEmail;
            user.name = '';
            user.picture = '';
            this.isLoading = true;
            user.internalRole = this.shareType;
            this.isLoading = false;
            if (!this.isUserInList(user)) {
                this.saveUsersList.push(user);
                this.saveData();
                this.addUserEmail = '';
                if (this.addUserNameField) {
                    this.addUserNameField.nativeElement.value = '';
                }
            } else {
                console.log('+++USER ALREADY PRESENT');
            }
        } else {
            console.log('+++USER IS NOT A VALID MAIL')
        }
    }

    isUserInList(user: UserItem): boolean {
        let found = false;
        for (let i = 0; i < this.loadUsersList.length; i++) {
            if (this.loadUsersList[i].email === user.email) {
                found = true;
                break;
            }
        }
        return found;
    }

    deleteUser(user: UserItem) {
        for (let i = 0; i < this.saveUsersList.length; i++) {
            if (this.saveUsersList[i].email === user.email) {
                this.saveUsersList.splice(i, 1);
                this.askSaveData();
                break;
            }
        }
    }

    askSaveData() {
        if (this.saveDataId) { clearTimeout(this.saveDataId); }
        this.saveDataId = setTimeout(() => {
            this.saveData();
        }, 100)
    }

    saveData() {
        this.isLoading = true;
        console.log('*****DATA SAVE*****');
        const shares = [];
        for (let i = 0; i < this.saveUsersList.length; i++) {
            const user = this.saveUsersList[i];
            shares.push({
                trustee: user.email,
                role: user.role
            });
        }
        if (this.publicShareType !== this.publicShareTypesOptions[0].value) {
            shares.push({
                trustee: 'anyoneWithLink',
                role: (this.publicShareType === 'edit' ? 'writer' : 'reader')
            });
        }
        this.smeService.writeShares(this.mapId + '', shares).subscribe({
            next: (res) => {
                this.isLoading = false;
                this.loadData();
                this.showSnackBar();
            },
            error: (error) => {
                this.isLoading = false;
            }
        });
    }

    showSnackBar() {
        this._snackBar.open(
            this.translateService.instant('SHARE_SAVE_MESSAGE'),
            this.translateService.instant('BUTTON_OK').toUpperCase(),
            {
                duration: 4000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom'
            });
    }

}


