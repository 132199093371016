<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>


<div class="access-config" *ngIf="!isLoading && dataFound && !submitted">
    <div>
        <div class="access-title">
            <div>
                <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
                    <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
                </a>
                <span class="access-subtitle">{{'ACCESSCONFIG_TITLE' | translate }} - </span>
                <span class="info" translate>ACCESSCONFIG_ORDERID</span><span class="info-order">{{orderId}}</span>
                <!-- <span [hidden]="!role" class="access-subtitle">{{getRoleType()}}</span> -->
            </div>
            <div *ngIf="!showContract" class="access-buttons">
                <button color="warn" type="submit" mat-flat-button (click)="onSubmit()">{{'ACCESSCONFIG_SAVE' | translate}}</button>
                <!-- <button color="primary"  [disabled]="!enabledSave()" type="button" mat-button (click)="goBack()">{{'PAGE_BACK' | translate}}</button> -->
            </div>
        </div>
        <div class="access-info">
            <span class="info" translate>ACCESSCONFIG_EXPIREDATE</span><span class="info-order">{{expireDate}}</span>
            <span class="info" translate>ACCESSCONFIG_LICENSETYPE</span><span class="info-order">{{getLicenseType()}}</span>
            <!-- <span hidden="role==='admin' || role==='anastasis' " class="info" translate>ACCESSCONFIG_ORIGIN</span><span class="info-order">{{getOriginType()}}</span> -->
        </div>
    </div>

    <div *ngIf="showContract" class="order-locked">
        <!-- Accettazione dei termini di servizio -->
        <p class="user-type-title" translate>PRIVACY_DOC_TITLE</p>
        <!-- DPA -->
        <div>
            <div [innerHTML]="safeDPAContent"></div>
            <mat-checkbox class="bold-checkbox" (click)="dpaClicked()">{{'PRIVACY_CONFIRM' | translate}}</mat-checkbox>
            <span class="star">*</span>
            <br />
            <br />
        </div>
        <!-- Contratto di servizio -->
        <div>
            <div [innerHTML]="safeContractContent"></div>
            <mat-checkbox class="bold-checkbox" (click)="contractClicked()">{{'PRIVACY_CONFIRM' | translate}}</mat-checkbox>
            <span class="star">*</span>
            <br />
        </div>
        <button color="primary" [disabled]="!isUnlockEnabled()" type="submit" mat-button class="unlock-button" (click)="unlockOrder()">{{'ACCESSCONFIG_UNLOCK' | translate}}</button>
    </div>

    <div *ngIf="!showContract">
        <!-- <form class="access-form" [formGroup]="accessConfigForm" novalidate (ngSubmit)="onSubmit()"> -->
        <form class="access-form" [formGroup]="accessConfigForm" novalidate>
            <span class="access-subtitle" translate>ACCESSCONFIG_MSG</span>
            <div class="access-name">
                <span translate>ACCESSCONFIG_NAME</span>
                <input type="text" placeholder="{{'ACCESSCONFIG_NAME_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" class="map-input-name access-col2" formControlName="accessName" />
                <span *ngIf="f['accessName'].errors" class="invalid-feedback">
                    <span *ngIf="f['accessName'].errors['required']" translate>EMPTY_NAME_NOT_ALLOWED</span>
                </span>
                <span class="access-desc" translate>ACCESSCONFIG_NAME_DESC</span>
            </div>
            <app-admin-config #adminconfig [parentForm]="accessConfigForm" [canEditDomain]="canEditDomain()" [accessList]="accessList" [showDomainLics]="false" [isSingleUser]="false">
            </app-admin-config>
            <div *ngIf="canEditUsers()">
                <app-users-list #usersconfig [slotCount]="slotCount" (showStats)="showUserStats($event)" (sendEmail)="sendUserEmail($event)" [showSendMail]="true" [showSlot]="false" [minSlot]="minSlot" [usersForm]="accessConfigForm" [withDomain]="canEditDomain()"></app-users-list>
            </div>
            <!-- <div class="access-buttons">
      <button color="primary" [disabled]="!accessConfigForm.valid" mat-button class="ok-button" type="submit">{{'ACCESSCONFIG_SAVE' | translate}}</button>
      <button color="primary" type="button" mat-button (click)="reloadAccessList()">{{'ACCESSCONFIG_RESET' | translate}}</button>
    </div> -->
        </form>
    </div>

    <div class="access-title" *ngIf="!dataFound">
        <p>
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
            <span class="access-subtitle">{{'ACCESSCONFIG_TITLE' | translate }} - </span>
            <span class="info" translate>ACCESSCONFIG_ORDERID</span><span class="info-order">{{orderId}}</span>
        </p>
        <br />
        <div class="order-not-found">
            <div class="onf-title">
                <p translate>ORDER-NOTFOUND-TITLE</p>
            </div>
            <div class="onf-subtitle">
                <p translate>ORDER-NOTFOUND-SUB</p>
            </div>
            <div class="onf-text">
                <p translate>ORDER-NOTFOUND-LINE1</p>
                <p translate>ORDER-NOTFOUND-LINE2</p>
                <p translate>ORDER-NOTFOUND-LINE3</p>
                <p translate>ORDER-NOTFOUND-LINE4</p>
            </div>
        </div>
    </div>
</div>

<div class="access-title" *ngIf="submitted">
    <p>
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a>
        <span class="access-subtitle">{{'ACCESSCONFIG_TITLE' | translate }} - </span>
        <span class="info" translate>ACCESSCONFIG_ORDERID</span><span class="info-order">{{orderId}}</span>
    </p>
    <br />
    <div class="order-not-found">
        <div class="onf-title">
            <p translate>ORDER-SUBMITTED-TITLE</p>
        </div>
        <!-- <div class="onf-subtitle">
            <p translate>ORDER-SUBMITTED-SUB</p>
        </div> -->
    </div>
</div>
