<button class="picker-container mat-tooltip-trigger" color="primary" mat-icon-button [disabled]="disabled" (click)="toggleColor()" [matTooltip]="toolTip">
    <div [ngClass]="{'hidden':isOpen || pickerIcon==='square'}">
        <mat-icon class="over-top" mat-icon [svgIcon]="pickerIcon"></mat-icon>
        <mat-icon class="over" mat-icon svgIcon="color-helper" [style.color]="selectedColor"></mat-icon>
    </div>
    <div [ngClass]="{'hidden':isOpen || pickerIcon!=='square'}">
        <mat-icon class="over-top" mat-icon svgIcon="square" [style.color]="selectedColor"></mat-icon>
    </div>
    <mcc-color-picker class="under" [hidden]="!hiddenPicker" [selectedColor]="selectedColor" [hideButtons]="hideButtons" [isOpen]="isOpen" (selected)="selected($event)" (clickOut)="cancelSelection()" (change)="changedColor($event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" [hideUsedColors]="hideUsedColors" hideEmptyUsedColors hideTransparentUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
        <mcc-color-picker-collection *ngIf="showTransparent" label="{{nocolor}}" [size]="0" [transparent]="showTransparent"></mcc-color-picker-collection>
        <mcc-color-picker-collection [colors]="colors" [size]="colors.length" label="{{colorLabel}}" [transparent]="false"></mcc-color-picker-collection>
    </mcc-color-picker>

</button>
