<div [formGroup]="usersForm" class="group-form">
    <!-- <div>
        <span translate>ACCESSCONFIG_USERS</span>
    </div> -->
    <div *ngIf="showSlot" class="access-name">
        <span translate>ACCESSCONFIG_SLOTS</span>
        <input type="number" autocomplete="off" spellcheck="false" class="mat-input-number access-col2" formControlName="slotCount" />
        <span *ngIf="showSlot && usersForm.get('slotCount')?.errors" class="invalid-feedback">
            <span *ngIf="showSlot && usersForm.get('slotCount')?.errors?.['required']" translate>FIELD_REQUIRED</span>
            <span *ngIf="showSlot && usersForm.get('slotCount')?.errors?.['min']" translate>MIN_ERROR</span>
            <span *ngIf="showSlot && usersForm.get('slotCount')?.errors?.['max']" translate>MAX_ERROR</span>
        </span>
        <span *ngIf="showSlot && !usersForm.get('slotCount')?.errors"> </span>
        <!-- <span class="access-desc" translate>ACCESSCONFIG_USERS</span> -->
    </div>

    <span *ngIf="!showSlot">{{slotCount}} {{'ACCESSCONFIG_SLOTS' | translate}}</span><br />
    <div *ngIf="!withDomain" class="access-desc-users" translate>ACCESSCONFIG_USERS_DESC </div>
    <div *ngIf="withDomain" class="access-desc-users" translate>ACCESSCONFIG_USERS_DESC_NODOMAIN</div>
    <div class="access-desc-users">
        <span translate>ACCESSCONFIG_USERS_WARNING</span>
        <img src="../../assets/google-logo.png?v=1" class="small-icon" />
    </div>
    <div class="access-users-container" formArrayName="usersList" *ngFor="let user of getControls(); let i = index;">
        <div class="access-user" [formGroupName]="i">
            <div class="access-user-form">
                <div class="access-user-index">{{startIndex+i+1}}</div>
                <input type="email" class="access-user-input" formControlName="email" placeholder="{{'ACCESSCONFIG_USERS_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" />
                <input *ngIf="!user.get('email')?.errors" class="access-user-input" formControlName="name" placeholder="{{'ACCESSCONFIG_USERS_NAME_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" />
                <input *ngIf="!user.get('email')?.errors" class="access-user-input" formControlName="notes" type="text" placeholder="{{'ACCESSCONFIG_USERS_NOTE_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" />
                <button *ngIf="showSendMail && !user.get('email')?.errors" [disabled]="user.get('email')?.invalid || user.get('email')?.value ===''" mat-icon-button class="access-user-icon-button" (click)="sendUserEmail(user)">
                    <mat-icon color="primary" matTooltip="{{'SEND_MAIL' | translate}}">email</mat-icon>
                </button>
                <button *ngIf="showSendMail && !user.get('email')?.errors" [disabled]="user.get('email')?.invalid || user.get('email')?.value ===''" mat-icon-button class="access-user-icon-button" (click)="showUserStats(user)">
                    <mat-icon clor="primary" matTooltip="{{'SHOW_STATS' | translate}}" svgIcon="chart-line"></mat-icon>
                </button>
            </div>
            <div *ngIf="user.get('email')?.invalid" class="access-user-error">
                <div *ngIf="user.get('email')?.hasError('uniqueBy')" translate>
                    EMAIL_ALREADY_EXISTS_MSG
                </div>
                <div *ngIf="user.get('email')?.errors?.['email']" translate>
                    NOT_VALID_EMAIL_MSG
                </div>
            </div>
        </div>
    </div>
    <mat-paginator class="paginator" #paginator [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [length]="length" [pageSize]="pageSize" (page)="pageEvent = $event; changePage($event)">
    </mat-paginator>
</div>
