import { Component, ElementRef, EventEmitter,  OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ExtraItem, ExtraOpenDto, ExtraService } from '../commands/extra.service';
import { Subject, Subscription, take, tap } from 'rxjs';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../../shared/map-state.service';
import { UiConstants } from '../../shared/ui-constants';
import { CustomSelectElement } from '../toolbar/custom-toolbar/custom-select-element-dto';
import { TtsService } from '../../shared/commands/tts.service';
import { TranslateService } from '@ngx-translate/core';
import { QuickEditService } from '../../shared/commands/quick-edit.service';
import { Point } from 'jspdf';
import { CropDimensions, NgxCaptureService } from 'ngx-capture';
import { ImageMapsService } from '../commands/image-maps.service';
import { MapClipboardService } from '../commands/map-clipboard.service';
import { GoogleService } from 'src/app/core/google/google.service';
import { I18nService, extract } from 'src/app/core/i18n.service';
import { SmeService } from 'src/app/core/sme/sme.service';
import { ResourceUpload } from 'src/app/core/firebase/resource-upload';
import { SpeechRecognitionService } from '../../shared/speech/speechrecognition.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { PdfHomeComponent } from './pdf-home/pdf-home.component';
import { PdfService } from './pdf.service';
import { RecentPdfDto } from './pdf-home/recent-pdf-dto';
import { FirebaseService } from 'src/app/core/firebase/firebase.service';
import { PdfMasterComponent } from './pdf-master/pdf-master.component';
import { Guid } from 'guid-typescript';
import { ConfirmationService } from 'src/app/shared/dialog/confirmation.service';
import { onSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { UserPreferenceService } from 'src/app/shared/user-preference.service';
import { UserPrefsDto } from 'src/app/shared/users/user-prefs-dto';
import { PdfToolsPrefsDto } from './pdf-master/pdf-tools-prefs-dto';
import { ModalService } from 'src/app/shared/links-menu/modal-service';
import { MathocrService as MathOcrService } from '../commands/mathocr.service';
import { MathocrConfirmationService } from 'src/app/shared/dialog/mathocr-confirmation.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CookieService } from 'ngx-cookie-service';
import { NotepadService } from '../notepad/notepad.service';
import { MessageBoxService } from 'src/app/shared/dialog/messagebox.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceService } from 'src/app/core/device.service'; 
import { Router } from '@angular/router';


@Component({
    selector: 'app-pdf-editor',
    templateUrl: './pdf-editor.component.html',
    styleUrls: ['./pdf-editor.component.scss']
})
export class PdfEditorComponent implements OnInit, OnDestroy {

    private _currLanguage = UiConstants.LANGUAGES[0].value;
    private _currVoice: SpeechSynthesisVoice | undefined;
    public languages: CustomSelectElement[] = new Array<CustomSelectElement>();
    public voices: SpeechSynthesisVoice[] = new Array<SpeechSynthesisVoice>();
    public filterVoices: SpeechSynthesisVoice[] = new Array<SpeechSynthesisVoice>();

    selectedLangIcon = UiConstants.LANGUAGES[0].icon;
    rateValue = 1;
    public speechEditEnabled: boolean = false;
    public isTalking: boolean = false;
    private onTTSTalkingStart: Subscription | undefined;
    private onTTSTalkingEnd: Subscription | undefined;
    onGetVoicesSubscription: Subscription | undefined;
    onCheckPdfToLoadSubscription: Subscription | undefined;
    uploadPDFasBlobSubscription: Subscription | undefined;
    onTTSBoundarySubcription: Subscription | undefined;
    downloadPdfFromDriveSubscription: Subscription | undefined;

    onSpeechEditEnableSubscription: Subscription | undefined;
    speechRecordSubscription: Subscription | undefined;
    onExtraPanelResizedSubscrition: Subscription | undefined;
    renamePdfSubscription: Subscription | undefined;
    askNameSubscription: Subscription | undefined;
    uploadPdfSubscription: Subscription | undefined;
    downloadPdfSubscription: Subscription | undefined;
    updatePdfMapSubscription: Subscription | undefined;
    userPreferenceChangeSubscription: Subscription | undefined;
    configSavedSubscription: Subscription | undefined;
    configCloseSubscription: Subscription | undefined;


    public isDirty = false;
    private issavingPref = false;
    public colorPDF = "primary"
    picker: any;
    isInitializedFirestore = false;
    private isOpenMessageWin = false;

    public editVisible = true;

    public canCaptureMath: boolean = true;

    @ViewChild('speechInput') speechInput: ElementRef | undefined;
    @ViewChild('screen', { static: true }) screen: any;
    @ViewChild('rect', { static: true }) rectangle?: ElementRef;
    @ViewChild('over', { static: true }) overlay?: ElementRef;
    @ViewChild('extraContainer') extraContainer: ElementRef | undefined;
    @ViewChild('overpanel') overPanel: ElementRef | undefined;
    @ViewChild('speedInput') speedInput: ElementRef | undefined;
    @ViewChild('pdfRecents') pdfRecents: PdfHomeComponent | undefined;
    @ViewChild('pdfMaster') pdfMaster: PdfMasterComponent | undefined;

    @Output() resultImage = new EventEmitter<string>();

    rect?: HTMLElement;
    captureZone?: HTMLElement;

    private isDrawing = false;
    private offsetLeft: number = 95;
    private offsetTop: number = 180;
    private offsetLeftPDF: number = 22;
    private offestTopPDF: number = 2;
    private mouseStart: Point = { x: 0, y: 0 };

    cropDimensions: CropDimensions = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };
    destroy$ = new Subject<void>();


    public capturingScreen: boolean = false;
    private captureTogglePressed: boolean = false;
    public percCropX: number = 0;
    public percCropY: number = 0;
    public percCropX2: number = 100;
    public percCropY2: number = 100;
    onTTSTalkingChanged: Subscription | undefined;
    fileChoosenSubscription: Subscription | undefined;
    deletePdfFromMapsSubscription: Subscription | undefined;
    // autosaveTimer: any;
    saveStatus = '';
    saveError = '';
    forceSave = false;
    uploading: boolean = false;
    saved = true;
    saving = false;
    startSaving = false;
    error = false;
    currentPdfId: string = '';
    currentGoogleId: string = '';

    noPDfOpen: boolean = false;
    isInitialize: boolean = false;
    dbListenerPdfUnsubscribeRef: any;
    dbListenerPdfUnsubscribe: any;
    credential: any;
    isReloading = false;
    lastUpdate: string = new Date().toISOString();
    lastUpdatingRecent: string = new Date().toISOString();
    settingEnable: boolean = true;


    _currentFileName: string = '';
    onToggleExtraSubscrition: Subscription | undefined;
    onMapGetFocusSubscription: Subscription | undefined;
    get currentFileName(): string {
        return this._currentFileName;
    }
    set currentFileName(value: string) {
        this._currentFileName = value;
        this.pdfMaster?.setDownloadFilename(value);
    }
    _useKaraoke: boolean = false;
    get useKaraoke(): boolean {
        return this._useKaraoke;
    }
    set useKaraoke(value: boolean) {
        this._useKaraoke = value;
        if (this.pdfMaster) this.pdfMaster.useKaraoke = value;
    }


    setPdfFocus() {
        this.pdfMaster?.setFocus(true);
    }
    public isLab: boolean = false;

    // Math OCR
    public mathOCR_icon: string = "assets/icons/icon_mathocr.png";
    public IsMathOCR: boolean = false;
    private latex2ImageSubscription: Subscription | undefined;

    // Print pdf
    public pdfUrl: string = "";

    public isCopyOnDeep: boolean = false;

    public isTextSelected = false;

    constructor(
        private router: Router,
        private extraService: ExtraService,
        private authService: AuthenticationService,
        private pdfService: PdfService,
        private smService: SmService,
        private mapStateService: MapStateService,
        private ttsService: TtsService,
        private smeService: SmeService,
        private translateService: TranslateService,
        private quickEditService: QuickEditService,
        private speechRecognitionService: SpeechRecognitionService,
        private i18nService: I18nService,
        private captureService: NgxCaptureService,
        private imageMapsService: ImageMapsService,
        private googleService: GoogleService,
        private mapClipboardService: MapClipboardService,
        private confirmationService: ConfirmationService,
        private firebaseService: FirebaseService,
        private authenticationService: AuthenticationService,
        private userPreferenceService: UserPreferenceService,
        private modalService: ModalService,
        private cookieService: CookieService,
        private notepadService: NotepadService,
        private mathOcrService: MathOcrService,
        private mathocrConfirmationService: MathocrConfirmationService,
        private messageBoxService: MessageBoxService,
        private clipboard: Clipboard,
        private snackBar: MatSnackBar,
        private deviceService: DeviceService
    ) {
        this.isLab = this.authenticationService.isLab();
        this.credential = this.authenticationService.credentials;
        let guiLangCookie = this.cookieService.get('GUILanguage');
        if (localStorage.getItem('locale') === null) {
            if (guiLangCookie === null) guiLangCookie = 'it';
            localStorage.setItem('locale', guiLangCookie);

        }
        const cLang = UiConstants.findLanguage(this.translateService.currentLang, UiConstants.LANGUAGES);
        this._currLanguage = cLang.value;
        this.selectedLangIcon = cLang.icon;
        if (!this.isInitializedFirestore) {
            const userId = this.credential?.firebaseUserId;

            if (userId) {
                this.isInitializedFirestore = true;
               

                this.initFirestoreListener(userId, '');
            }
        }
    }

    initPdf(): void {
        if (!this.isInitialize) {
            this.isInitialize = true;


            if (this.userPreferenceService.onUserPrefsChange) {
                this.userPreferenceChangeSubscription = this.userPreferenceService.onUserPrefsChange.subscribe((_userPref: UserPrefsDto) => {
                    this.userPreferenceService.userPrefs = _userPref;
                    if (!this.issavingPref) this.pdfMaster?.initializeTools(_userPref.pdf);
                    this.issavingPref = false;
                });
            }

            // if (!this.noPDfOpen) {
            this.onCheckPdfToLoadSubscription = this.smeService.checkPdfToLoad(this.mapStateService.id).subscribe({
                next: (value => {
                    if (value === '') {
                        this.noPDfOpen = true;
                        this.uploading = false;
                    } else {
                        if (value.storageRef !== '') {
                            this.noPDfOpen = false;
                            this.currentFileName = value.name;

                            this.colorPDF = value.color;
                            this.pdfService.currentPdf = this.pdfService.getRecentById(value.pdfId);
                            this.openPDF(value.storageRef);
                            this.pdfService.addOrUpdatePdf(value.pdfId, value.name, '', new Date().toISOString(), new Date().toISOString(), new Date().toISOString(), value.storageRef, '', value.color)
                                .then((curritem: RecentPdfDto) => {
                                    this.currentPdfId = curritem.idPdf;
                                    this.pdfService.currentPdf = curritem;

                                });

                            this.currentPdfId = value.pdfId;
                            this.uploading = false;
                        } else {
                            this.noPDfOpen = true;
                            this.uploading = false;
                        }
                    }
                }),
                error: () => {
                    this.noPDfOpen = true;
                    this.uploading = false;
                }
            });
            if (!this.isInitializedFirestore) {
                const userId = this.credential?.firebaseUserId;
    
                if (userId) {
                    this.isInitializedFirestore = true;
                   
    
                    this.initFirestoreListener(userId, '');
                }
            }
            // } else {
            //     this.uploading = false;
            // }
        } else {
            this.uploading = false;
        }

    }
    ngOnInit() {
        this.uploading = true;
        // this._currLanguage = localStorage.getItem('language');


        this.onMapGetFocusSubscription = this.mapStateService.onMapGetFocus.subscribe((value) => {
            if (value) this.pdfMaster?.setFocus(false);
        });
        // this.i18nService.language = this._currLanguage;
        // const genLang = this._currLanguage.substring(0, 2);
        // this.cookieService.set('GUILanguage', genLang);
        // document.querySelector('html')?.setAttribute('lang', genLang);
        // (window as any).WEBSPELLCHECKER_CONFIG.localization = genLang;
        this.initTTS();
        // this.extraService.isExtraPanelOpen = true;
        this.onToggleExtraSubscrition = this.extraService.onToggleExtraPanel.subscribe((extraOpenDto: ExtraOpenDto) => {
            if (extraOpenDto.extraItem === ExtraItem.OPEN_PDF && !this.isInitialize) this.initPdf();

            this.pdfMaster?.setFocus(extraOpenDto.extraItem === ExtraItem.OPEN_PDF);
        });

        if (this.extraService.isExtraPanelOpen && this.extraService.lastOpenDto.extraItem === ExtraItem.OPEN_PDF) {
            this.pdfMaster?.setFocus(true);
            this.initPdf();
            // } else {
            //     this.openHomePdf();

        }

        this.mapStateService.onImageLoaded.subscribe(() => {
            this.canCaptureMath = true;
        });

        // this.onCheckPdfToLoadSubscription = this.smeService.checkPdfToLoad(this.mapStateService.id).subscribe({
        //     next: (value => {
        //         if (value === '') {
        //             this.noPDfOpen = true;
        //             this.uploading = false;
        //         } else {
        //             if (value.storageRef !== '') {
        //                 this.noPDfOpen = false;
        //                 this.currentFileName = value.name;

        //                 this.colorPDF = value.color;
        //                 this.pdfService.currentPdf = this.pdfService.getRecentById(value.pdfId);
        //                 this.openPDF(value.storageRef);


        //                 this.currentPdfId = value.pdfId;
        //                 this.uploading = false;
        //             } else {
        //                 this.noPDfOpen = true;
        //                 this.uploading = false;
        //             }
        //         }
        //     }),
        //     error: () => {
        //         this.noPDfOpen = true;
        //         this.uploading = false;
        //     }
        // });



        // Cattura schermo
        setTimeout(() => {
            this.rect = this.rectangle?.nativeElement;
            this.captureZone = this.overlay?.nativeElement;
            if (!this.captureZone) {
                console.warn('"captureZone" is not set');
                return;
            }
            this.captureZone.onmousedown = (e) => this.startCapture(e);
            this.captureZone.onmousemove = (e) => this.drawRect(e);
            this.captureZone.onmouseup = () => this.endCapture();
            document.body.onmouseup = () => this.stopCapture();
        }, 2000);
    }

    ngOnDestroy(): void {
        if (this.downloadPdfSubscription) this.downloadPdfSubscription.unsubscribe();
        if (this.updatePdfMapSubscription) this.updatePdfMapSubscription.unsubscribe();
        if (this.onGetVoicesSubscription) { this.onGetVoicesSubscription.unsubscribe(); }
        if (this.uploadPDFasBlobSubscription) { this.uploadPDFasBlobSubscription.unsubscribe(); }
        if (this.uploadPdfSubscription) { this.uploadPdfSubscription.unsubscribe(); }
        if (this.downloadPdfSubscription) { this.downloadPdfSubscription.unsubscribe(); }

        if (this.userPreferenceChangeSubscription) { this.userPreferenceChangeSubscription.unsubscribe(); }
        if (this.onExtraPanelResizedSubscrition) { this.onExtraPanelResizedSubscrition.unsubscribe(); }
        if (this.onTTSBoundarySubcription) { this.onTTSBoundarySubcription.unsubscribe(); }
        if (this.onCheckPdfToLoadSubscription) { this.onCheckPdfToLoadSubscription.unsubscribe(); }
        if (this.downloadPdfFromDriveSubscription) { this.downloadPdfFromDriveSubscription.unsubscribe(); }
        if (this.onSpeechEditEnableSubscription) { this.onSpeechEditEnableSubscription.unsubscribe(); }
        if (this.speechRecordSubscription) { this.speechRecordSubscription.unsubscribe(); }
        if (this.renamePdfSubscription) { this.renamePdfSubscription.unsubscribe(); }
        if (this.askNameSubscription) { this.askNameSubscription.unsubscribe(); }
        if (this.uploadPdfSubscription) { this.uploadPdfSubscription.unsubscribe(); }
        if (this.dbListenerPdfUnsubscribe) { this.dbListenerPdfUnsubscribe(); }



        if (this.dbListenerPdfUnsubscribe) {
            this.dbListenerPdfUnsubscribe();
        }

        if (this.onToggleExtraSubscrition) { this.onToggleExtraSubscrition.unsubscribe(); }

        this.extraService.isExtraPanelOpen = false;
    }


    readAllText() {
        this.pdfMaster?.getCurrentPageText().then((text) => {
            console.log(text);
        });
    }

    readSelectedText(text: string) {
        //   this.ttsService.speak(text, 'it-IT', true);
        //this.TTSRead();
        this.ttsService.speak(text, this._currLanguage, true);// , undefined, this._currVoice);
    }

    dirtyChange(value: boolean) {
        this.isDirty = value;
        if (this.isDirty) {
            this.savePdf();
        }

    }

    initFirestoreListener(userId: string, idPdf: string = '') {

        const promises: Array<any> = [];
        const events: Array<any> = [];
        this.dbListenerPdfUnsubscribeRef = this.firebaseService.getFirestoreRecentPdfListener(userId);
        this.dbListenerPdfUnsubscribe = onSnapshot(this.dbListenerPdfUnsubscribeRef, (querySnapshot: any) => {
            // if (this.currentPdfId !== "") {
            if (!this.isReloading) {
                querySnapshot.docChanges().forEach((change: any) => {
                    const promise = this.firebaseService.getPdfRecent(userId, change.doc.id, events);
                    promises.push(promise);

                });
                let endPromise = false;
                let doRefresh = false;
                if (promises.length > 0) {
                    Promise.all(promises).then(() => {
                        if (events && events.length > 0) {
                            let i = 0;
                            while (i < events.length && !endPromise) {
                                const ev = events[i];
                                if (ev && ev.event === 'reload' && !this.noPDfOpen) {
                                    if (this.pdfService.currentPdf === undefined) {
                                        const items = this.pdfService.recentsPdf.filter((x) => {

                                            return x.idPdf === this.currentPdfId
                                        });
                                        this.pdfService.currentPdf = items[0];
                                    }
                                    if (ev.pdfId === this.currentPdfId && this.pdfService.currentPdf && this.mapStateService.guid !== ev.guid && this.lastUpdate < ev.timestamp) {
                                        this.isReloading = true;
                                        endPromise = true;
                                        this.confirmationService.confirm(
                                            this.translateService.instant(extract('WARNING')),
                                            this.translateService.instant(extract('CONFIRM_RELOAD_PDF')),
                                            this.translateService.instant(extract('BUTTON_OK')),
                                            '')
                                            .subscribe({
                                                next: result => {
                                                    if (result) {
                                                        if (result === true) {
                                                            doRefresh = this.noPDfOpen;
                                                            const stRef = this.pdfService.currentPdf?.storageRef;
                                                            this.openPDF(stRef);
                                                            this.isReloading = false;

                                                        } else {

                                                        }
                                                    }
                                                },
                                                error: (error: any) => {
                                                    console.error(error);
                                                }
                                            });


                                        // this.pdfMaster?.openPdf(stRef);
                                    } else {
                                        i++;
                                    }
                                    // } else if (this.mapStateService.guid !== ev.guid) {
                                    //     if (this.noPDfOpen) {
                                    //         this.pdfRecents?.getRecents();
                                    //     } else {

                                    //     }
                                    //     i++;

                                } else {
                                    if (ev && ev.event === 'recents' && ev.guid !== this.mapStateService.guid && ev.timestamp > this.lastUpdatingRecent) {
                                        doRefresh = this.noPDfOpen;
                                    }
                                    i++;
                                }
                            }

                            if (doRefresh) {
                                this.lastUpdatingRecent = new Date().toISOString()
                                this.pdfRecents?.getRecents();

                            }

                        }

                    });
                }
            }

        });

    }

    startLoading() {
        this.uploading = true;
    }

    endLoading() {
        this.uploading = false;
    }

    savePdf() {
        if (this.isDirty) {
            this.isDirty = false;
            // this.pdfMaster?.SavePdf();
            this.onSavePdf();
        }
    }




    onSavePdf() {
        if (!this.startSaving && !this.saving && this.currentPdfId !== '') {
            this.startSaving = true;

            // controlla se c'è lo storage
            this.smeService.getPdfStorageById(this.currentPdfId).subscribe({
                next: (data) => {
                    if (data !== '') {
                        //se lo trova lo salva
                        this.startSaving = false;
                        //this.uploadBlob(blob, this.currentPdfId);
                        const recentPdf = this.pdfService.currentPdf;
                        this.isDirty = false;
                        if (recentPdf) {
                            this.pdfService.addOrUpdatePdf(recentPdf.idPdf, recentPdf.name, recentPdf.googleId, new Date().toISOString(), recentPdf.lastOpened, recentPdf.imported, recentPdf.storageRef, recentPdf.link, recentPdf.color);
                        }
                        this.firebaseService.addPdfRecent(this.mapStateService.guid, this.credential.firebaseUserId, this.currentPdfId, 'reload');
                    } else {
                        this.confirmationService.confirm(
                            this.translateService.instant(extract('WARNING')),
                            this.translateService.instant(extract('CONFIRM_RESTORE_PDF')),
                            this.translateService.instant(extract('BUTTON_OK')),
                            this.translateService.instant(extract('GENERAL_CANCEL')))
                            .subscribe((result: any) => {
                                if (result === true) {
                                    this.startSaving = false;
                                    //  this.uploadBlob(blob, this.currentPdfId);
                                    const recentPdf = this.pdfService.currentPdf;
                                    this.isDirty = false;
                                    if (recentPdf) {
                                        this.pdfService.addOrUpdatePdf(recentPdf.idPdf, recentPdf.name, recentPdf.googleId, new Date().toISOString(), recentPdf.lastOpened, recentPdf.imported, recentPdf.storageRef, recentPdf.link, recentPdf.color);
                                    }
                                    this.firebaseService.addPdfRecent(this.mapStateService.guid, this.credential.firebaseUserId, this.currentPdfId, 'reload');
                                } else {
                                    this.startSaving = false;
                                    this.saving = false;

                                }
                            });
                    }
                }, error: () => {
                    this.startSaving = false;
                    this.saving = false;

                }
            });
        }
    }



    // onChangeBlob(blob_url: any) {

    //     this.uploadBlob(blob_url, '', this.pdfMaster?.currentFileName);
    // }

    endLoadedPdf(data: any) {
        //  document.title = this.mapStateService.name + ' - ' + this.translateService.instant(extract('MAPEDIT_TITLE'))
        // setTimeout(() => {
        //     this.initAutosaveTimer();
        // });

        if (data.baseUrl === 'error') {
            this.showMessage(this.translateService.instant(extract('INVALID_PDF')));
            this.currentFileName = '';

        } else {

            if (!this.isInitializedFirestore) {
                const userId = this.credential?.firebaseUserId;

                if (userId) {
                    this.isInitializedFirestore = true;
                   

                    this.initFirestoreListener(userId, '');
                }
            }
            // file caricato con il link o il drag
            if (data.baseUrl !== this.pdfService.currentPdf?.storageRef) {
                this.pdfMaster?.getPDFData().then((blob: any) => {
                    const name = this.getFileNameFromLink(this.pdfMaster?.currentFileName);
                    const blobPDF = new Blob([blob], { type: 'application/pdf' });
                    //const blobURL = URL.createObjectURL(blobPDF);
                    this.uploadBlob(blobPDF, '', name);
                });
            }
            if (this.pdfMaster?.isInitialized && this.userPreferenceService.userPrefs?.pdf) {

                this.pdfMaster?.initializeTools(this.userPreferenceService.userPrefs.pdf);
                this.pdfMaster?.setGUILanguage(this.translateService.currentLang);
            }
        }
    }

    private openPDF(pdf: any, name: string = '') {
        // this.autosaveTimer = null;
        this.pdfUrl = pdf;

        if (this.pdfMaster?.isInitialized && this.userPreferenceService.userPrefs?.pdf) {
            this.pdfMaster?.initializeTools(this.userPreferenceService.userPrefs.pdf);

        }


        this.lastUpdate = new Date().toISOString()

        this.pdfMaster?.openPdf(pdf);

        if (name !== '' && this.pdfMaster) {
            this.pdfMaster.currentFileName = name;
            this.pdfMaster?.setDownloadFilename(name);
        }
        // document.title = this.mapStateService.name;


    }


    // initAutosaveTimer() {
    //     this.autosaveTimer = setInterval(() => {
    //         if (navigator.onLine === true && this.error !== this.translateService.instant(extract('GATEWAY_TIMEOUT'))) {
    //             this.savePdf();
    //         } else {
    //             // this.saveStatus = this.translateService.instant(extract('OFFLINE_STATUS'));
    //             if (navigator.onLine === true) {
    //                 this.saveError = this.translateService.instant(extract('ERROR-SAVING'));
    //             } else {
    //                 this.saveError = ' ' + this.translateService.instant(extract('OFFLINE_STATUS'));
    //             }
    //         }
    //     }, 20 * 1000);
    // }


    private uploadBlob(blob: any, pdfId: string, name?: string) {
        //Usato per i file aperti in drag sul pdf
        let pdfPath = '';


        if (pdfId === '') {
            pdfId = Guid.create().toString();
            this.currentPdfId = pdfId;
            this.pdfService.currentPdf = undefined;
        }
        if (this.uploadPDFasBlobSubscription) { this.uploadPDFasBlobSubscription.unsubscribe(); }
        this.uploadPDFasBlobSubscription = this.smeService.uploadPDFasBlob(blob, pdfId).subscribe({
            next: (ref) => {
                pdfPath = ref.url;

            },
            complete: () => {
                if (name === undefined) name = '';
                this.currentFileName = name;
                this.pdfService.addOrUpdatePdf(this.currentPdfId, name, '', new Date().toISOString(), new Date().toISOString(), new Date().toISOString(), pdfPath, '', 'gray')
                    .then((curritem: RecentPdfDto) => {
                        this.currentPdfId = curritem.idPdf;
                        if (this.updatePdfMapSubscription) this.updatePdfMapSubscription.unsubscribe();
                        this.updatePdfMapSubscription = this.smeService.updatePdfMap(curritem.idPdf, this.mapStateService.id).subscribe();
                    });
                // this.firebaseService.addPdfRecent(this.mapStateService.guid, this.credential.firebaseUserId, this.currentPdfId, 'reload');


            },
            error: (error: any) => {
                let errorCode = '';
                if (error.status === 504) {
                    errorCode = this.translateService.instant(extract('OFFLINE_GENERIC'));
                } else {
                    errorCode = (error.code) ? error.code : 'Error on updload PDF';
                }


            }
        });



    }
    private getSavedReadSpeedByCurrentLanguage(): number {
        const usermail = this.authService.getUserEmail();
        if (usermail != '') {
            const filter: Array<string> = [usermail, this._currLanguage];
            const savedReadSpeed = this.userPreferenceService.getReadSpeedCookie(filter);
            if (savedReadSpeed > 0) {
                return savedReadSpeed;
            } else {
                return 1;
            }
        }

        return 1;
    }

    private initTTS() {

        if (this.onTTSBoundarySubcription) { this.onTTSBoundarySubcription?.unsubscribe(); }
        this.onTTSBoundarySubcription = this.ttsService.onBoundary.subscribe((item) => {
            //     this.pdfEditor?.pdfviewer?.karaokeOnPdf(item);
            this.pdfMaster?.karaokeOnPdf(item);

        });
        this.speechEditEnabled = false;
        this.setLanguage(this._currLanguage);
        this.rateValue = this.getSavedReadSpeedByCurrentLanguage();
        this.ttsService.voiceRate = this.rateValue;


        if (this.onTTSTalkingStart) this.onTTSTalkingStart.unsubscribe();
        this.onTTSTalkingStart = this.ttsService.TalkingStart.subscribe(() => {
            this.isTalking = true;

        });

        if (this.onTTSTalkingEnd) this.onTTSTalkingEnd.unsubscribe();
        this.onTTSTalkingEnd = this.ttsService.TalkingEnd.subscribe(() => {
            this.isTalking = false;


        });

        if (this.onTTSTalkingChanged) { this.onTTSTalkingChanged.unsubscribe(); }
        this.onTTSTalkingChanged = this.ttsService.isTalkingStateChanged.subscribe((talking: boolean) => {

        });

        //  console.log("GET VOICES");
        if (this.onGetVoicesSubscription) this.onGetVoicesSubscription.unsubscribe();
        this.onGetVoicesSubscription =
            this.ttsService.onGetVoices.subscribe((voices: SpeechSynthesisVoice[]) => {
                if (this.deviceService.isMobileOrTabletDevice()) {
                    this.languages = UiConstants.initLanguagesMobile(voices, this.translateService);
                } else {
                    this.languages = UiConstants.initLanguagesDesktop(voices, this.translateService);
                }
                this.voices = voices;
                // this.filterVoices = this.filterVoicesByLang(this._currLanguage);
                // this.filterVoices = this.voices.filter(x => x.lang === this._currLanguage);
                // if (this.filterVoices.length === 0) {
                //     this.filterVoices = this.voices;
                //     this._currVoice = undefined;
                // }
            });

        // this.voices = this.ttsService.voices;
        // if (this.ttsService.voices) {
        //     this.languages = UiConstants.initLanguages(this.ttsService.voices, this.translateService);
        //     this.filterVoices = this.filterVoicesByLang(this._currLanguage);
        //     // this.filterVoices = this.voices.filter(x => x.lang === this._currLanguage);
        //     // if (this.filterVoices.length === 0) {
        //     //     this.filterVoices = this.voices;
        //     //     this._currVoice = undefined;
        //     // }
        // }

        this.quickEditService.onCanEdit.emit(false);
        if (this.onSpeechEditEnableSubscription) { this.onSpeechEditEnableSubscription.unsubscribe(); }
        this.onSpeechEditEnableSubscription = this.quickEditService.onSpeechEditEnable.subscribe((enabled: boolean) => {
            if (this.quickEditService.origin === this.quickEditService.ORIGIN.NOTES) {
                this.speechEditEnabled = enabled;
                if (enabled) {
                    this.startSpeechEdit();
                } else {
                    this.stopSpeechEdit();
                }
            }
        });


    }

    openFromRecent(recentPdf: RecentPdfDto) {
        ///Download file from storage
        this.noPDfOpen = false;
        this.currentPdfId = recentPdf.idPdf;
        this.colorPDF = recentPdf.color;
        this.pdfService.currentPdf = recentPdf;
        this.openPDF(recentPdf.storageRef, recentPdf.name);
        this.currentFileName = recentPdf.name;
        this.pdfService.addOrUpdatePdf(recentPdf.idPdf, recentPdf.name, recentPdf.googleId, recentPdf.lastUpdated, new Date().toISOString(), recentPdf.imported, recentPdf.storageRef, recentPdf.link, recentPdf.color)
            .then((currentItem: RecentPdfDto) => {
                this.pdfService.currentPdf = currentItem;
                if (this.updatePdfMapSubscription) this.updatePdfMapSubscription.unsubscribe();
                this.updatePdfMapSubscription = this.smeService.updatePdfMap(currentItem.idPdf, this.mapStateService.id).subscribe();
            }).catch((error: any) => {
                console.log(error);
            });
    }

    private getFileNameFromLink(link: string | undefined): string {
        const name = 'documento.pdf';
        if (link && link != '' && link.indexOf('.pdf') > 0) return link.substring(link.lastIndexOf('/') + 1, link.indexOf('.pdf') + 4).replace('%20', ' PDF');

        return name;

    }

    isUrl(url: string) {
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlRegex.test(url);
    }

    openFromLink(link: string) {
        if (this.isUrl(link)) {
            const pdfId = Guid.create().toString();
            if (this.pdfMaster) {
                this.currentFileName = this.getFileNameFromLink(link);

                this.noPDfOpen = false;
                if (link.toLowerCase().startsWith('https://drive.google.com/')) {
                    const i = link.indexOf('file/d/') + 7;
                    const l = link.lastIndexOf('/');
                    const _fileId = link.substring(i, l);
                    const url = link;

                    this.downloadPDFFromDrive(_fileId, url, this.currentFileName);
                } else {
                    this.openPDF(link, this.currentFileName);
                    this.colorPDF = 'gray';
                }
            }
        } else {
            this.showMessage(this.translateService.instant(extract('INVALID_URL')));
        }
    }

    downloadRecent(recent: RecentPdfDto) {
        this.downloadPdfSubscription = this.smeService.downloadPDf(recent.storageRef).subscribe((data: Blob) => {
            const file = new window.Blob([data], { type: 'application/pdf' });
            const downloadAncher = document.createElement("a");
            downloadAncher.style.display = "none";
            const fileURL = URL.createObjectURL(file);
            downloadAncher.href = fileURL;
            downloadAncher.download = recent.name;
            downloadAncher.click();
        })
    }

    renamePdf(recentPdf: RecentPdfDto) {
        this.pdfService.renamePdf(recentPdf);
    }

    private uploadPdf(pdfResource: ResourceUpload) {
        let pdf: any;
        const pdfId = Guid.create().toString();
        this.uploadPdfSubscription = this.smeService.uploadPDF(pdfResource, pdfId).subscribe({
            next: (_pdf: any) => {
                if (_pdf > 0 && _pdf < 100) {
                    this.uploading = true;
                }
                pdf = _pdf;
            },
            complete: () => {
                const pdfData = pdf.url;
                this.openPDF(pdfData, this.currentFileName);
                this.uploading = false;
                /// JHUBA SCRIVERE DB con MapID
                this.currentPdfId = pdfId;
                this.pdfService.addOrUpdatePdf(pdfId, this.currentFileName, '', new Date().toISOString(), new Date().toISOString(), new Date().toISOString(), pdf.url, '', 'gray')
                    .then((currentItem: RecentPdfDto) => {
                        this.pdfService.currentPdf = currentItem;
                        this.currentPdfId = currentItem.idPdf;
                        if (this.updatePdfMapSubscription) this.updatePdfMapSubscription.unsubscribe();
                        this.updatePdfMapSubscription = this.smeService.updatePdfMap(currentItem.idPdf, this.mapStateService.id).subscribe();
                    }).catch((error: any) => {
                        console.log(error);
                    });
            },
            error: (error: any) => {
                let errorCode = '';
                if (error.status === 504) {
                    errorCode = this.translateService.instant(extract('OFFLINE_GENERIC'));
                } else {
                    errorCode = (error.code) ? error.code : 'Error on updload PDF';
                }
            }
        });
    }

    openHomePdf() {
        this.noPDfOpen = true;
        this.currentFileName = '';
        this.colorPDF = 'gray';
        if (this.isInitialize) {
            this.savePdf();
        }
        this.pdfMaster?.closePdf();
        this.onClickPdf(null);
        this.lastUpdatingRecent = new Date().toISOString()
        this.pdfService.getRecents();
        if (this.deletePdfFromMapsSubscription) this.deletePdfFromMapsSubscription.unsubscribe();
        this.deletePdfFromMapsSubscription = this.smeService.deletePdfFromMaps(this.mapStateService.id).subscribe({
            next: () => {
                this.pdfService.currentPdf = undefined;
            },
            error: (err) => {
                console.log(err);
            }
        });
        this.onClickPdf(null);
    }

    public openPDFFromLocal(event: any) {
        this.noPDfOpen = false;
        let currentImageFile: any;
        if (event.target) {
            currentImageFile = event.target.files[0];
        } else currentImageFile = event;
        this.noPDfOpen = false;
        const currentPDFUpload = new ResourceUpload(currentImageFile);
        this.currentFileName = currentImageFile.name;
        this.colorPDF = 'gray';
        this.uploadPdf(currentPDFUpload);
    }

    private downloadPDFFromDrive(_fileId: string, url: string, name: string) {
        if (_fileId && _fileId !== '') {
            this.uploading = true;
            const fileId = _fileId;
            this.colorPDF = 'gray';
            this.currentGoogleId = _fileId;
            this.currentFileName = name;
            if (this.downloadPdfFromDriveSubscription) { this.downloadPdfFromDriveSubscription.unsubscribe(); }
            this.downloadPdfFromDriveSubscription = this.smeService.downloadPdfFromDrive(fileId, this.currentFileName, this.mapStateService.id).subscribe({
                next: (_pdf: any) => {
                    const pdfBlob = new Blob([_pdf], { type: 'application/pdf' });
                    const pdfId = Guid.create().toString();
                    if (this.uploadPDFasBlobSubscription) this.uploadPDFasBlobSubscription.unsubscribe();
                    this.uploadPDFasBlobSubscription = this.smeService.uploadPDFasBlob(pdfBlob, pdfId).subscribe({
                        next: (pdfToOpen: any) => {
                            this.openPDF(pdfToOpen.url, this.currentFileName);
                            this.currentPdfId = pdfId;
                            this.pdfService.addOrUpdatePdf(pdfId, this.currentFileName, fileId, new Date().toISOString(), new Date().toISOString(), new Date().toISOString(), pdfToOpen.url, url, 'gray')
                                .then((pdfItem: RecentPdfDto) => {
                                    this.pdfService.currentPdf = pdfItem;
                                    this.currentPdfId = pdfItem.idPdf;
                                    if (this.updatePdfMapSubscription) this.updatePdfMapSubscription.unsubscribe();
                                    this.updatePdfMapSubscription = this.smeService.updatePdfMap(pdfId, this.mapStateService.id).subscribe();
                                });
                            this.uploading = false;
                            this.noPDfOpen = false;
                        },
                        error: (error: any) => {
                            let errorCode = '';
                            this.uploading = false;
                            if (error.status === 504) {
                                errorCode = this.translateService.instant(extract('OFFLINE_GENERIC'));
                            } else {
                                errorCode = (error.code) ? error.code : 'Error on updload PDF';
                            }
                        }
                    });
                },
                error: (error: any) => {
                    let errorCode = '';
                    this.uploading = false;
                    if (error.status === 504) {
                        errorCode = this.translateService.instant(extract('OFFLINE_GENERIC'));
                    } else {
                        errorCode = (error.code) ? error.code : 'Error on updload PDF';
                    }
                }
            });
        } else {
            this.uploading = false;
        }
    }


    public openPDFFromDrive() {
        let accessToken = '';
        this.uploading = true;
        this.noPDfOpen = false;

        if (this.fileChoosenSubscription) { this.fileChoosenSubscription.unsubscribe(); }
        this.fileChoosenSubscription = this.googleService.fileChoosen.subscribe((result: any) => {
            const _fileId = result.fileId;
            const url = result.url;
            this.downloadPDFFromDrive(_fileId, url, result.name);


            this.fileChoosenSubscription?.unsubscribe();
        });
        this.smeService.getAccessTokenPromise()
            .then((_accessToken: string) => {
                accessToken = _accessToken;
                this.uploading = false;
                return this.googleService.loadPicker();
            }).then(() => {
                const language = this.i18nService.language;
                this.googleService.createPdfPicker(accessToken, this.picker, language);
            }).catch((error: any) =>{
               
                if (this.authenticationService.credentials && !this.authenticationService.isNativegoogleUser()) {
                                     
                    const id = this.authenticationService.credentials.firebaseUserId;
                    this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK,
                        this.translateService.instant('WARNING'),
                        this.translateService.instant('RICONNNECT_GOOGLE_ACCOUNT'), // [fragment]="{userId: '1101',mobileNo:931065465 }
                    ).subscribe(() => {
                        this.router.navigate(['/user-profile', id], { fragment: 'highlight=1' });
                    });
                } else {
                    this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
                }
            });
    }

    showMessage(message: any) {
        if (!this.isOpenMessageWin) {
            this.isOpenMessageWin = true;
            this.messageBoxService.showTextMessage('OK', this.translateService.instant('WARNING'), message).subscribe((value) => {
                this.isOpenMessageWin = false;
            });
        }
    }

    public onClickPdf(event: any) {
        this.smService.setEnableKeyPresses(false);
        this.mapStateService.setFocusOnMap(false);

        const textSelected = this.pdfMaster?.getSelectedText();
        this.isTextSelected = (textSelected !== '');
    }

    onAnnotationSetText(jsonobj: any) {
        if (this.isCopyOnDeep) {
            this.isCopyOnDeep = false;
            return;
        }
        if (this.userPreferenceService.userPrefs?.pdf.copyHighligth && jsonobj?.annotation_type === 'highlight') {
            this.notepadService.insertText(jsonobj.all_str);
        }
        if (this.userPreferenceService.userPrefs?.pdf.copyUnderlined && jsonobj?.annotation_type === 'underline') {
            this.notepadService.insertText(jsonobj.all_str);
        }
    }

    onOpenConfig(json: string) {
        if (this.settingEnable) {
            this.settingEnable = false;
            this.pdfMaster?.resetAnnotationButtons();
            const pref = (this.userPreferenceService.userPrefs ? this.userPreferenceService.userPrefs.pdf : new PdfToolsPrefsDto(json));
            const pdfConfig = this.modalService.showPdfPreferences(pref);
            if (this.configSavedSubscription) this.configSavedSubscription.unsubscribe();
            this.configCloseSubscription = pdfConfig.componentInstance.onClosedConfig.subscribe({
                next: () => {
                    this.settingEnable = true;
                }

            });
            this.configSavedSubscription = pdfConfig.componentInstance.onSavedConfig.subscribe({
                next: (config: PdfToolsPrefsDto) => {
                    this.changeConfig(config);
                    this.settingEnable = true;
                },
                error: (err: any) => {
                    console.log(err);
                    this.settingEnable = true;
                }
            });
        }
    }

    changeConfig(configPdf: PdfToolsPrefsDto) {
        if (this.userPreferenceService.userPrefs) {
            this.userPreferenceService.userPrefs.pdf = configPdf;
            this.issavingPref = true;
            this.userPreferenceService.setUserPreference(this.userPreferenceService.userPrefs);
            this.pdfMaster?.initializeTools(this.userPreferenceService.userPrefs.pdf);
        }
    }

    private setLanguage(currLanguage: string) {
        this._currLanguage = currLanguage;
        const spellLanguage = currLanguage.replace('-', '_');
        (window as any).WEBSPELLCHECKER_CONFIG.lang = spellLanguage;
        localStorage.setItem('wsc_lang', 'string<$>' + spellLanguage);
        if (this.languages) {
            const language = UiConstants.findLanguage(this._currLanguage, this.languages);
            if (language && language.icon) {
                this.selectedLangIcon = language.icon;
            }
            // this.filterVoices = this.filterVoicesByLang(this._currLanguage);

        }
    }

    private filterVoicesByLang(langCode: string): SpeechSynthesisVoice[] {
        const code = langCode.substring(0, 2).toLowerCase();
        this.filterVoices = this.voices.filter(x => x.lang.toLowerCase().startsWith(code));
        if (this.filterVoices.length === 0) {
            this.filterVoices = this.voices;
            this._currVoice = undefined;
        }
        return this.filterVoices;
    }

    private setVoice(voice: string) {
        if (this.voices) {
            const voices = this.voices.filter(x => x.name === voice);
            if (voices.length > 0) {
                this._currVoice = voices[0];
            } else {
                this._currVoice = undefined;
            }
        }
    }



    changeLanguage(event: any) {
        this.setLanguage(event.currentTarget.id);
        this.smService.setSelectionLanguage(this._currLanguage);
    }

    changeVoice(event: any) {
        this.setVoice(event.currentTarget.id);
    }

    speedGotFocus() {
        //this.mapStateService.setEditingState(true);
        this.smService.setEnableKeyPresses(false);
        this.quickEditService.onCanEdit.emit(false);
        this.mapStateService.setFocusOnMap(false);
    }

    speedLostFocus() {
        this.checkSpeedValue();
        //this.mapStateService.setEditingState(true);
        this.smService.setEnableKeyPresses(true);
        this.quickEditService.onCanEdit.emit(true);
        this.mapStateService.setFocusOnMap(true);
    }

    changeSpeechRate(event: any) {
        this.rateValue = parseFloat(event.target.value);
        this.checkSpeedValue();
        this.ttsService.voiceRate = this.rateValue;
    }

    checkSpeedValue() {
        if (this.rateValue < 0.5) {
            this.rateValue = 0.5;
            if (this.speedInput)
                this.speedInput.nativeElement.value = this.rateValue;
        }
        if (this.rateValue > 1.5) {
            this.rateValue = 1.5;
            if (this.speedInput)
                this.speedInput.nativeElement.value = this.rateValue;
        }
    }

    private startSpeechEdit() {
        console.log('startSpeechEdit');
        this.pdfMaster?.ReplaceText('PROVA');
    }

    private stopSpeechEdit() {
        console.log('stopSpeechEdit');
        this.speechRecognitionService.DestroySpeechObject();
    }

    speechEdit() {
        this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
    }

    public TTSRead() {
        if (this.pdfMaster) {
            const textToRead = this.pdfMaster?.getText();
            // read all text
            if (textToRead === "") {
                this.pdfMaster?.getCurrentPageText().then((text) => {
                    if (this.pdfMaster?.useKaraoke) {
                        this.ttsService.speak(text, this._currLanguage, true, undefined, this._currVoice);
                    } else {
                        this.ttsService.speak(text, this._currLanguage, true);
                    }
                });
            }
            else {
                // read selection
                // this.ttsService.speak(textToRead, this.mapStateService.mapLanguage, true, undefined, this._currVoice);
                if (this.pdfMaster?.useKaraoke) {
                    this.ttsService.speak(textToRead, this._currLanguage, true, undefined, this._currVoice);
                } else {
                    this.ttsService.speak(textToRead, this._currLanguage, true);
                }
            }

        }
    }

    public TTSStop() {
        this.ttsService.stop();
    }

    closeExtra() {
        this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
    }

    public InsertText() {
        const textSelected = this.pdfMaster?.getSelectedText(); //this.pdfMaster?.getText();
        if (textSelected !== undefined && textSelected !== '') {
            this.isCopyOnDeep = true;
            this.pdfMaster?.highlightSelection();
            this.mapClipboardService.pasteFromHTML(textSelected);
        }
    }

    public InsertDeep() {
        const textSelected = this.pdfMaster?.getSelectedText(); //this.pdfMaster?.getText();
        if (textSelected !== undefined && textSelected !== '') {
            const node = this.smService.getSelectedNode();
            if (node) {
                const deep = node.deepHtml + '<p>&nbsp;</p>' + textSelected;
                this.isCopyOnDeep = true;
                this.pdfMaster?.highlightSelection();

                const data = { titleHtml: node.titleHtml, deepHtml: deep };
                data.deepHtml = UiConstants.normalizeHtmlForDeep(data.deepHtml);
                this.smService.editSelectedElement(data);

                this.snackBar.open(
                    this.translateService.instant(extract('COPY_ON_DEEP_MSG')),
                    '', {
                    horizontalPosition: 'start',
                    verticalPosition: 'bottom',
                    duration: 3000,
                });
            } else {
                this.snackBar.open(
                    this.translateService.instant(extract('NO_NODE_SELECTED')),
                    '', {
                    horizontalPosition: 'start',
                    verticalPosition: 'bottom',
                    panelClass: ['red-snackbar'],
                    duration: 3000,
                });

            }
        }
    }

    public Capture() {
        this.pdfMaster?.CaptureScreenshot();
        this.IsMathOCR = false;
        /*
        // VECCHIO SISTEMA DI CATTURA (STESSO DEL NOTEPAD)
        if (this.capturingScreen) {
            this.stopCapture();
        } else {
            console.log("START CAPUTURE");
            this.capturingScreen = true;
            this.percCropX = 0;
            this.percCropY = 0;
            this.percCropX2 = 0;
            this.percCropY2 = 0;
            this.captureTogglePressed = true;
            setTimeout(() => this.captureTogglePressed = false, 20);
        }
        */
    }

    public CaptureMathOCR() {
        this.pdfMaster?.CaptureScreenshot();
        this.IsMathOCR = true;
        /*
        // VECCHIO SISTEMA DI CATTURA (STESSO DEL NOTEPAD)
        if (this.capturingScreen) {
            this.stopCapture();
        } else {
            console.log("START CAPTURE");
            this.capturingScreen = true;
            this.percCropX = 0;
            this.percCropY = 0;
            this.percCropX2 = 0;
            this.percCropY2 = 0;

            this.captureTogglePressed = true;
            setTimeout(() => this.captureTogglePressed = false, 20);
        }
        */
    }

    /**
     * Convert base64/URLEncoded data component to raw binary data held in a string
     */
    private dataUrlToBlob = function (dataUrl: any) {
        let byteString;
        if (dataUrl != null) {
            if (dataUrl.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataUrl.split(',')[1]);
            } else {
                byteString = decodeURI(dataUrl.split(',')[1]);
            }
            const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
            const array = [];
            for (let i = 0; i < byteString.length; i++) {
                array.push(byteString.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], { type: mimeString });
        }
        return null;
    };

    private blobToBase64(blob: Blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    public ImageCaptured(data: any) {
        this.canCaptureMath = false;
        console.log(data);
        let nodeCreatedId = '';
        const urlCreator = window.URL || window.webkitURL;
        const resImage = urlCreator.createObjectURL(data.content);
        const result: any = data.content;
        if (result != null) {
            const now = new Date();
            result.lastModifiedDate = now;
            result.name = `camera-${now}.png`;
            if (this.IsMathOCR) {
                this.mathocrConfirmationService.confirm(this.translateService.instant('OCRSCAN'), this.authenticationService.getUserEmail()).subscribe({
                    next: result => {
                        if (result) {
                            let editNode = this.smService.getSelectedNode();
                            if (!this.mathocrConfirmationService.isCopyLatex) {
                                if (!editNode) {
                                    this.smService.addNodeAndSelectIfNoSelection('');
                                    editNode = this.smService.getSelectedNode();
                                    nodeCreatedId = editNode.id;
                                }
                                this.smService.setImagePlaceholderToNodeId(editNode.id, true);
                            }
                            const email = this.authenticationService.getUserEmail();
                            this.blobToBase64(data.content).then((base64Image) => {
                                //console.log(base64Image);
                                this.mathOcrService.getFormulaFromImage(email, base64Image as string, true).subscribe({
                                    next: (resp) => {
                                        if (resp.ok) {
                                            if (resp.result.ok) {
                                                const latex = resp.result.latex;
                                                if (latex) {
                                                    const latexFix = UiConstants.fixLatexFromOcr(latex);
                                                    if (this.mathocrConfirmationService.isCopyLatex) {
                                                        console.log("COPY LATEX " + latex);
                                                        this.clipboard.copy(latex);
                                                        this.canCaptureMath = true;
                                                    } else {
                                                        this.latex2ImageSubscription = this.smeService.latex2image(latexFix, latex).subscribe({
                                                            next: (response: any) => {
                                                                const dataUrl = response.result;
                                                                if (dataUrl) {
                                                                    console.log(dataUrl)
                                                                    this.smService.setLatexToNode(editNode.id, latex);
                                                                    this.imageMapsService.insertImageFromSvg(dataUrl, editNode.id);
                                                                }
                                                            },
                                                            error: (error: any) => {
                                                                console.error(JSON.stringify(error));
                                                                const errMsg = error.toString();
                                                                const check = 'Error: TeX parse error: Undefined control sequence ';
                                                                // if (errMsg.startsWith(check)) {
                                                                const errItem = errMsg.substring(check.length).replace(/\\\\/g, '\\');
                                                                console.log('ErrItem: ' + errItem);
                                                                const fbLatex = latex.replace(errItem, '\\error{' + errItem + '}');
                                                                // }
                                                                this.canCaptureMath = true;
                                                            }
                                                        });
                                                    }
                                                } else {
                                                    // Latex non riconosciuto
                                                    this.messageBoxService.showTextMessage(
                                                        this.messageBoxService.MODE_TYPE.OK,
                                                        this.translateService.instant('OCRSCAN'),
                                                        this.translateService.instant('OCR_LATEX_EMPTY'));
                                                    if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                                    this.canCaptureMath = true;
                                                }
                                            } else {
                                                // ok=false: errore della chiamata http
                                                this.confirmationService.confirm(
                                                    this.translateService.instant('OCRSCAN'),
                                                    this.translateService.instant('MSG_HTTP_CALL_ERROR'),
                                                    this.translateService.instant(extract('BUTTON_OK')),
                                                    '');
                                                if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                                this.canCaptureMath = true;
                                            }
                                        } else {
                                            // ok=false: errore della chiamata http
                                            this.confirmationService.confirm(
                                                this.translateService.instant('OCRSCAN'),
                                                this.translateService.instant('MSG_HTTP_CALL_ERROR'),
                                                this.translateService.instant(extract('BUTTON_OK')),
                                                '');
                                            if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                            this.canCaptureMath = true;
                                        }
                                    },
                                    error: (error) => {
                                        // messaggio di errore generico
                                        this.confirmationService.confirm(
                                            this.translateService.instant('OCRSCAN'),
                                            this.translateService.instant('MSG_OCRSCAN_GENERIC_ERROR'),
                                            this.translateService.instant(extract('BUTTON_OK')),
                                            '');
                                        if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                        this.canCaptureMath = true;
                                    }
                                });
                            });
                        } else {
                            this.canCaptureMath = true;
                        }
                    },
                    error: (error: any) => {
                        this.canCaptureMath = true;
                    }
                });
                this.mathocrConfirmationService.setImage(resImage as string);
            } else {
                this.imageMapsService.insertImageFromFile(result, null);
            }
        } else {
            this.canCaptureMath = true;
        }
    }

    public PrintPdf() {
        const urlCodificato = this.pdfService.currentPdf?.idPdf;
        if (urlCodificato) {
            const url: string = window.location.origin + '/pdfprint/' + urlCodificato;
            localStorage.setItem('printpdf', urlCodificato)
            const printWindow = window.open(url, '_blank') as Window;
            console.log("************ " + this.pdfUrl);
            printWindow.onafterprint = () => {
                // Chiudi la finestra dopo la stampa
                printWindow.close();
            };
        }
    }

    /**
     * VECCHIO SISTEMA DI CATTURA IMMAGINE
     * @param e 
     * 
     * @returns 
     */
    private startCapture(e: any) {
        if (!this.capturingScreen) return;
        const mouse = this.setMousePosition(e, true);
        this.isDrawing = true;
        this.cropDimensions = {
            x: mouse.x,
            y: mouse.y,
            width: 0,
            height: 0,
        };
        if (this.captureZone) {
            this.captureZone.style.cursor = 'crosshair';
        }
    }

    private drawRect(e: any) {
        if (this.isDrawing) {
            const mouse = this.setMousePosition(e, false);
            this.cropDimensions = {
                x: mouse.x - this.mouseStart.x < 0 ? mouse.x - this.offsetLeft + this.offsetLeftPDF : this.mouseStart.x - this.offsetLeft + this.offsetLeftPDF,
                y: mouse.y - this.mouseStart.y < 0 ? mouse.y - this.offsetTop + this.offestTopPDF : this.mouseStart.y - this.offsetTop + this.offestTopPDF,
                width: Math.abs(mouse.x - this.mouseStart.x),
                height: Math.abs(mouse.y - this.mouseStart.y),
            };
            this.setRectangle();

            let offsetExtra = 0;
            const extraContainerElement = <HTMLElement>document.getElementById("extra-container");
            const canvasContainerElement = <HTMLCollectionOf<Element>>document.getElementsByClassName("textLayer");
            if (extraContainerElement && canvasContainerElement) {
                console.log(canvasContainerElement[0].clientWidth + " *** " + extraContainerElement.clientWidth);
                if (extraContainerElement.clientWidth > canvasContainerElement[0].clientWidth) {
                    offsetExtra = ((extraContainerElement.clientWidth - canvasContainerElement[0].clientWidth) / 2);
                    console.log("offsetExtra *** " + offsetExtra);
                    //offsetExtra = 0;
                }
            }
            // offsetExtra = 0;
            this.cropDimensions = {
                x: mouse.x - this.mouseStart.x < 0 ? mouse.x - offsetExtra : this.mouseStart.x - offsetExtra,
                y: mouse.y - this.mouseStart.y < 0 ? mouse.y - this.offsetTop : this.mouseStart.y - this.offsetTop,
                width: Math.abs(mouse.x - this.mouseStart.x),
                height: Math.abs(mouse.y - this.mouseStart.y),
            };
            console.log("Mouse x: " + mouse.x);
            console.log("offsetExtra left: " + offsetExtra);
            console.log(this.cropDimensions);
        }
    }

    private setMousePosition(e: any, isStart = false): Point {
        const ev = e || window.event; // Moz || IE
        const mouse: Point = { x: 0, y: 0 };
        if (ev.pageX) {
            // Moz
            mouse.x = ev.clientX;
            mouse.y = ev.clientY;
        } else if (ev.clientX) {
            // IE
            mouse.x = ev.clientX + document.body.scrollLeft;
            mouse.y = ev.clientY + document.body.scrollTop;
        }
        if (isStart) {
            this.mouseStart.x = mouse.x;
            this.mouseStart.y = mouse.y;
        }
        return mouse;
    }

    private stopCapture() {
        setTimeout(() => {
            if (!this.captureTogglePressed) {
                if (this.capturingScreen) {
                    console.log("STOP CAPUTURE");
                    this.capturingScreen = false;
                    //  this.SetReadonly(this.capturingScreen);
                    if (this.captureZone) { this.captureZone.style.cursor = 'default'; }
                    this.isDrawing = false;
                    this.cropDimensions = {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                    };
                    this.setRectangle();
                }
            }
        }, 10);
    }

    private endCapture() {
        if (this.capturingScreen) {
            this.canCaptureMath = false;
            let nodeCreatedId = '';
            console.log('STOP CAPTURE');
            this.capturingScreen = false;
            if (this.captureZone && this.isDrawing) {
                this.captureZone.style.cursor = 'default';
                this.isDrawing = false;

                let resImage: string | undefined = '';
                if (this.IsMathOCR) {
                    this.mathocrConfirmationService.confirm(this.translateService.instant('OCRSCAN'), this.authenticationService.getUserEmail()).subscribe({
                        next: result => {
                            if (result) {
                                let editNode = this.smService.getSelectedNode();
                                if (!this.mathocrConfirmationService.isCopyLatex) {
                                    if (!editNode) {
                                        this.smService.addNodeAndSelectIfNoSelection('');
                                        editNode = this.smService.getSelectedNode();
                                        nodeCreatedId = editNode.id;
                                    }
                                    this.smService.setImagePlaceholderToNodeId(editNode.id, true);
                                }
                                const email = this.authenticationService.getUserEmail();
                                this.mathOcrService.getFormulaFromImage(email, resImage as string, true).subscribe({
                                    next: (resp) => {
                                        if (resp.ok) {
                                            if (resp.result.ok) {
                                                let latex = resp.result.latex;
                                                if (latex) {
                                                    latex = UiConstants.fixLatexFromOcr(latex);
                                                    const latexMJ = latex;
                                                    if (this.mathocrConfirmationService.isCopyLatex) {
                                                        console.log("COPY LATEX " + latex);
                                                        this.clipboard.copy(latex);
                                                    } else {
                                                        this.latex2ImageSubscription = this.smeService.latex2image(latex, latexMJ).subscribe({
                                                            next: (response: any) => {
                                                                const dataUrl = response.result;
                                                                if (dataUrl) {
                                                                    console.log(dataUrl)
                                                                    this.smService.setLatexToNode(editNode.id, latex);
                                                                    this.imageMapsService.insertImageFromSvg(dataUrl, editNode.id);
                                                                    // Safety timeout for reenable capture button
                                                                    setTimeout(() => { this.canCaptureMath = true; }, 5000);
                                                                }
                                                            },
                                                            error: (error: any) => {
                                                                console.error(JSON.stringify(error));
                                                                const errMsg = error.toString();
                                                                const check = 'Error: TeX parse error: Undefined control sequence ';
                                                                if (errMsg.startsWith(check)) {
                                                                    const errItem = errMsg.substring(check.length).replace(/\\\\/g, '\\');
                                                                    console.log('ErrItem: ' + errItem);
                                                                    const fbLatex = latex.replace(errItem, '\\error{' + errItem + '}');
                                                                }
                                                                this.canCaptureMath = true;
                                                            }
                                                        });
                                                    }
                                                } else {
                                                    // Latex non riconosciuto
                                                    this.messageBoxService.showTextMessage(
                                                        this.messageBoxService.MODE_TYPE.OK,
                                                        this.translateService.instant('OCRSCAN'),
                                                        this.translateService.instant('OCR_LATEX_EMPTY'),
                                                    );
                                                    if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                                    this.canCaptureMath = true;
                                                }
                                            } else {
                                                // result.ok=false: errore scansione ocr
                                                this.confirmationService.confirm(
                                                    this.translateService.instant('OCRSCAN'),
                                                    this.translateService.instant(resp.result.message),
                                                    this.translateService.instant(extract('BUTTON_OK')),
                                                    '');
                                                if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                                this.canCaptureMath = true;
                                            }
                                        } else {
                                            // ok=false: errore della chiamata http
                                            this.confirmationService.confirm(
                                                this.translateService.instant('OCRSCAN'),
                                                this.translateService.instant('MSG_HTTP_CALL_ERROR'),
                                                this.translateService.instant(extract('BUTTON_OK')),
                                                '');
                                            if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                            this.canCaptureMath = true;
                                        }
                                    },
                                    error: (error) => {
                                        // messaggio di errore generico
                                        this.confirmationService.confirm(
                                            this.translateService.instant('OCRSCAN'),
                                            this.translateService.instant('MSG_OCRSCAN_GENERIC_ERROR'),
                                            this.translateService.instant(extract('BUTTON_OK')),
                                            '');
                                        if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                        this.canCaptureMath = true;
                                    }
                                });
                            } else {
                                this.canCaptureMath = true;
                            }
                        },
                        error: (error: any) => {
                            this.canCaptureMath = true;
                        }

                    });
                }

                setTimeout(() => {
                    this.captureService
                        .getImage(this.screen.nativeElement, false, {
                            ...this.cropDimensions,
                            x: this.cropDimensions.x + 1 + window.scrollX,
                            y: this.cropDimensions.y + 1 + window.scrollY,
                        })
                        .pipe(
                            take(1),
                            tap((img: string | undefined) => {
                                resImage = img;
                                const result: any = this.dataUrlToBlob(img);
                                if (result != null) {
                                    const now = new Date();
                                    result.lastModifiedDate = now;
                                    result.name = `camera-${now}.png`;
                                    if (this.IsMathOCR) {
                                        this.mathocrConfirmationService.setImage(img as string);
                                    } else {
                                        this.imageMapsService.insertImageFromFile(result, null);
                                    }
                                }
                            })
                        )
                        .subscribe();
                    this.cropDimensions = {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                    };
                    this.setRectangle();
                })
            }
        }
    }

    private setRectangle() {
        if (this.rect) {
            this.rect.style.left = this.cropDimensions.x + 'px';
            this.rect.style.top = this.cropDimensions.y + 'px';
            this.rect.style.width = this.cropDimensions.width + 'px';
            this.rect.style.height = this.cropDimensions.height + 'px';
            this.setMask(this.cropDimensions.x, this.cropDimensions.y, this.cropDimensions.width, this.cropDimensions.height);
        }
    }

    private setMask(x: number, y: number, width: number, height: number) {
        const w = Number(this.captureZone?.offsetWidth);
        const h = Number(this.captureZone?.offsetHeight);

        const pW = 100 / w;
        const pH = 100 / h;
        this.percCropX = pW * x;
        this.percCropY = pH * y;
        this.percCropX2 = pW * (x + width);
        this.percCropY2 = pH * (y + height);
    }
}


