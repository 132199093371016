import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { FirebaseAuthService } from '../../core/firebase/firebase-auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PasswordValidator } from 'src/app/login-form/set-password/set-password.component';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CurrentUserLoggingInDto } from 'src/app/login-form/set-password/set-password.component';


@Component({
    selector: 'app-new-user-profile',
    templateUrl: './new-user-profile.component.html',
    styleUrls: ['./new-user-profile.component.scss']
})


export class NewUserProfileComponent implements OnInit {


    selectedFiles?: FileList;
    selectedFileNames: string[] = [];
    imageFile: File | null = null;
    imageUrl: any = '';// 'assets/icons8-utente-48.png';
    loginErrorMessage = '';
    clickableMsg = false;
    progressInfos: any[] = [];
    message: string[] = [];
    hide: boolean = true;

    previews: string[] = [];
    imageInfos?: Observable<any>;
    APP_ID = environment.configFirebase.messagingSenderId;


    client_id = environment.firebaseClientId;
    public user = {
        id: '',
        uid: '',
        email: '',
        displayName: '',
        picture: '',
        refreshToken: '',
        token: ''
    };
    isLoading = false;
    username = '';
    password = '';

    isError = false;
    showingError = false;
    errorMessage = '';
    createUserParams: boolean = false;
    paramMapSubscription: Subscription | undefined;
    form: FormGroup = new FormGroup({

    });

    constructor(private firebaseAuthService: FirebaseAuthService, private autenticationService: AuthenticationService, private router: Router,
        private route: ActivatedRoute, private translateService: TranslateService, private DOMSanitizer: DomSanitizer) { }
    ngOnInit(): void {
        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            this.username = params['email'];

            this.form = new FormGroup({
                // username: new FormControl(this.username, Validators.required),
                fullname: new FormControl('', Validators.required),
                imageUrl: new FormControl(''),
                password: new FormControl('', Validators.required),
                ripetiPassword: new FormControl('', Validators.required)
            }, [PasswordValidator.matchPassword]);
        });


        // this.firebaseAuthService.auth.onAuthStateChanged((user: any) => {
        //     this.imageUrl = user.photoURL;
        //     this.form.controls['imageUrl'].setValue(this.imageUrl);
        //     this.form.controls['fullname'].setValue(user.displayName);
        // });
    }



    submit() {
        this.isError = false;
        if (this.form.valid) {
            this.form.controls['imageUrl'].setValue(this.imageUrl);
            this.createUser(this.form.value)


            //   this.submitCreate.emit(this.form.value);


        } else {
            this.isError = true;
        } 

    }

    get f() { return this.form?.controls; }

    isIEOrEdge(): boolean {
        return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    }


    getInitials() {
        let result = '';
        if (this.form.controls['fullname'].value.trim() !== '') {
            this.form.controls['fullname'].value.split(' ').forEach((name: string) => {
                result += name[0]?.toUpperCase();
            });
        }
        return result?.toUpperCase();
    }


    createUser(event: any) {
        this.isLoading = true;
        this.showingError = false;
        console.log(`Login...`);
        if (navigator.onLine) {
            console.log(`ONLINE`);

            this.firebaseAuthService.createUser(this.username, event.password, event.fullname, this.imageFile)
                .then((userCred: any) => {
                    // alert('Account created!');
                    this.client_id = environment.firebaseClientId;

                    this.user = {
                        id: userCred.uid,
                        uid: userCred.uid,
                        email: userCred.email,
                        displayName: userCred.displayName ? userCred.displayName : '',
                        picture: userCred.photoURL,
                        refreshToken: userCred.stsTokenManager.refreshToken,
                        token: userCred.stsTokenManager.accessToken
                    };
                    const UserInfo = {

                        state: 'password',
                        access_token: userCred.stsTokenManager.accessToken,
                        email: userCred.email,
                        fullName: userCred.displayName ? userCred.displayName : '',
                        id: userCred.uid,
                        id_token: userCred.stsTokenManager.idToken,
                        picture: userCred.photoURL,
                        refresh_token: userCred.stsTokenManager.refreshToken
                    };

                    // this.router.navigate(['/login'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });


                    this.router.navigate(['/loggedin'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });
                    //this.loginStep1();
                }).catch(error => {
                    console.error(error);
                    this.isLoading = false;
                    this.showError(error);
                });
        } else {
            console.error(`ERROR: OFFLINE`);
            this.showError('OFFLINE_LOGIN');
        }
    }

    private showError(errorCode: any) {
        // console.log(`ERRORCODE: ${errorCode}`);
        if (errorCode) {
            this.loginErrorMessage = errorCode //this.translateService.instant(errorCode);
            this.clickableMsg = false;
        } else {
            this.loginErrorMessage = this.translateService.instant('LOGIN_COOKIES_URL');
            this.clickableMsg = true;
        }
        this.showingError = true;
        this.isLoading = false;
    }

    setPassword(event: CurrentUserLoggingInDto) {
        this.password = event.password;
        this.form.controls['password'].setValue(this.password);
    }

    enabledForm() {
        let en = false;
        if (this.form) {
            en = this.form.valid;
        }
        return en;
    }

    selectFiles(event: any): void {

        this.selectedFileNames = [];
        this.selectedFiles = event.target.files;
        this.imageFile = null;

        this.previews = [];
        if (this.selectedFiles && this.selectedFiles[0]) {
            const numberOfFiles = this.selectedFiles.length;
            for (let i = 0; i < numberOfFiles; i++) {
                const reader = new FileReader();

                reader.onload = (e: any) => {
                    console.log(e.target.result);
                    this.previews.push(e.target.result);
                    this.imageUrl = e.target.result;
                };

                reader.readAsDataURL(this.selectedFiles[i]);
                this.imageFile = this.selectedFiles[i];

                this.selectedFileNames.push(this.selectedFiles[i].name);
            }
        }
    }

    showHidePsw(): void {
        this.hide = !this.hide;
    }

    @HostListener('keypress', ['$event'])
    keypress(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.form.valid) {
                this.submit();
            }
        }
    }

    @Input() error: string | null = "";


    @Output() submitCreate = new EventEmitter();
}
