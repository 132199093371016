import { Component, OnInit, HostListener, ViewChild, Compiler, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { SmeService } from '../core/sme/sme.service';
import { SmService } from 'supermappe-core';
import { Logger } from '../core/logger.service';
import { I18nService, extract } from '../core/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { SmEventBroker } from '../shared/sm-event-broker';
import { PrintMapService } from '../shared/commands/print-map.service';
import { DirtyDataStatus, DirtyDataService } from '../shared/commands/dirty-data.service';
import { ElementRef } from '@angular/core';
import { ZoomService } from '../shared/commands/zoom.service';
import { AutosaveService } from './commands/autosave.service';
import { MapStateService } from '../shared/map-state.service';
import { ExportSmeService } from './commands/export-sme.service';
import { Point } from '../shared/point';
import { Subscription } from 'rxjs';
import { TtsService } from '../shared/commands/tts.service';
import { Title } from '@angular/platform-browser';
import { QuickEditService } from '../shared/commands/quick-edit.service';
import { SpeechRecognitionService } from '../shared/speech/speechrecognition.service';
import { SpeechRecognitionResult } from '../shared/speech/speech-recognition-result';
import { UiConstants } from '../shared/ui-constants';
import { MapClipboardService } from './commands/map-clipboard.service';
import { ExtraItem, ExtraOpenDto, ExtraService } from './commands/extra.service';
import { environment } from '../../environments/environment';
import { ImageMapsService } from './commands/image-maps.service';
import { WebSearchService } from '../shared/commands/web-search.service';
import { ConfirmationService } from '../shared/dialog/confirmation.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { YoutubeService } from '../shared/commands/youtube.service';
import { MapEditorComponent } from '../shared/map-editor/map-editor.component';
import { ModalService } from '../shared/links-menu/modal-service';
import { MapEditService } from './map-edit.service';
import { RegisteredUserService } from '../core/authentication/registered-user.service';
import { UserPrefsDto } from '../shared/users/user-prefs-dto';
import { UserPreferenceService } from '../shared/user-preference.service';
import { MapShowService } from '../map-show/map-show.service';
import { CreateGoogleDocService } from '../shared/commands/create-google-doc.service';
import { CreateMapDocumentService } from '../shared/commands/create-map-document.service';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ImportMapService } from '../shared/commands/import-map.service';
import { MapOperationType } from '../shared/map-operations';
import { FirebaseService } from '../core/firebase/firebase.service';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { UsersService } from '../shared/commands/users.service';
import { ChatService } from '../shared/chat/chat.service';
import { DesmosService } from '../shared/commands/desmos.service';
import { LinkData } from '../shared/links-menu-data';
import { onSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { OutlineService } from './outline/outline.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { DesmosConfigDto } from './desmos-config/desmosConfig-dto';
import { MathService } from '../shared/commands/math.service';
import { DeviceService } from '../core/device.service';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MapFindService } from '../shared/map-find/map-find.service';

const logger: Logger = new Logger('MapEditComponent');

const SMX_CONFIG = {
    'CONTINUOUS_IMAGE_UPDATE': true,
    'EDIT_ON_TEXT': true,
    'FIT_WHOLE_PAGE': false,
    'STAMP_IMAGE_NAME': 'stamp-image',
    'MAP_QRCODE_SYMBOL_NAME': 'map-qrcode-symbol',
    'MAP_QRCODE_NAME': 'map-qrcode',
    'GDOC_QRCODE_SYMBOL_NAME': 'gdoc-qrcode-symbol',
    'GDOC_QRCODE_NAME': 'gdoc-qrcode',
    'INTERNAL_MOUSEWHEEL': true,
    'MIN_ZOOM_DETAILS': 0.5,
    'SHOW_DEEP_STARS': false,
    'SHOW_NODE_MATH': false, // Impostato oltre
    'SHOW_NODE_REC': false,
    'URL': {
        'ROOT': '',
        'MAPLIST': 'resources',
        'BASE': '', //  TOLTO PER NUOVE FUNCTION IL CORE NON DEVE METTERLO!!!!!
        'ASSETS': './assets'
    },
    'default': {
        'NODE': {
            'NODETYPE': 'rectangle',
            'BRUSHCOLOR': 'rgba(255,255,255,1.0)',
            'PENCOLOR': 'rgba(0,112,192,1.0)',
            'PENWIDTH': 1
        },
        'EDGE': {
            'AUTOCURVED': 'false',
            'PENCOLOR': 'rgba(128,128,128,1.0)',
            'PENWIDTH': 1,
            'FREEEDGE': true,
            'TYPE': 'forward'
        },
        'TABLE': {
            'BRUSHCOLOR': 'rgba(255,255,255,1.0)',
            'PENCOLOR': 'rgba(0,112,192,1.0)',
            'PENWIDTH': 1
        },
        'IMAGE': {
            'FRAME': false
        }
    },
    'isMobile': false
};

@Component({
    selector: 'app-map-edit',
    templateUrl: './map-edit.component.html',
    styleUrls: ['./map-edit.component.scss'],
})
export class MapEditComponent implements OnInit, AfterViewInit, OnDestroy {

    //LAYOUT
    editToolbarCompressed = false;
    editMenuCompressed = false;
    editShowToolbarCompressed = false;
    editMenuHidden = false;

    //MOBILE LIMITATIONS
    public isMobileMode = false;

    //save region
    isSaving: boolean; //generic save flag
    autosaveTimer: any;//autosave times
    private countSaveError = 0;
    saveStatus: number = 0; //save status 0 Done, 1 Saving, 2 NotSaved, 3 offline
    saveStatusMessage = ''; //save status message
    forceSave = false;
    saved = true; //saved flag for ui
    public saveError = '';//save error message

    public logoUrl: string;
    public extraBackground: string;

    isLoading: boolean;
    newMapName = '';
    readyToPrint = false;
    error: string;
    imageBase64: any;
    // JHUBA CHECK
    contextItems: Array<LinkData> = new Array<LinkData>();
    isReadonlyMode = false;
    alive: boolean;
    renameMapMode = false;


    private currentLang = '';
    loginError = false;
    loadingError = false;


    _canOpenQuickEdit: boolean;
    switchingInput: boolean;
    webSearchPanelVisible: boolean;
    mapSearchVisible: boolean;
    mapSearchDefaultPosition: any;
    webSearchDefaultPosition: any;
    isChatPanelVisible: boolean;
    desmosPanelVisible: boolean;
    webPanelVisible: boolean;
    autoDrawPanelVisible: boolean;

    downloadJsonSubscription: Subscription | undefined;
    dirtyDataChangeSubscription: Subscription | undefined;
    onBusyStateChangeSubscription: Subscription | undefined;
    paramMapSubscription: Subscription | undefined;
    selectionDataSubscription: Subscription | undefined;
    changeBackgroundcolorSubscription: Subscription | undefined;
    changedBackgroundcolorSubscription: Subscription | undefined;
    onExportChangeSubscription: Subscription | undefined;
    onImageUpdatedSubscription: Subscription | undefined;
    onEditorChangeSubscription: Subscription | undefined;
    onZoomUpdatedSubscritpion: Subscription | undefined;
    onErrorSubscription: Subscription | undefined
    onToggleExtraSubscrition: Subscription | undefined;
    onExtraPanelContentChanged: Subscription | undefined;
    onToggleWebSearchSubscription: Subscription | undefined;
    onToggleMapSearchSubscription: Subscription | undefined;
    onToggleChatSubscrition: Subscription | undefined;
    onCanEnableQuickEditChangeSubscription: Subscription | undefined;
    speechRecordSubscription: Subscription | undefined;
    uploadWorkJsonSubscription: Subscription | undefined;
    renameMapSubscription: Subscription | undefined;
    interactionModeSubscription: Subscription | undefined;
    inputDialogSubscription: Subscription | undefined;
    restoreSubscription: Subscription | undefined;
    findRegisteredUserSubscription: Subscription | undefined;
    checkSessionIdSubscription: Subscription | undefined;
    onDeepEditSubscription: Subscription | undefined;
    onShowImageSubscription: Subscription | undefined;
    videoUrlChangedDataSubscription: Subscription | undefined;
    getLinkPreviewSubscription: Subscription | undefined;
    onChangeMapNameSubscription: Subscription | undefined;
    onQuickEditEnableSubscription: Subscription | undefined;
    onSpeechEditEnableSubscription: Subscription | undefined;
    getUserPreferencesSubscription: Subscription | undefined;
    onForceAutosaveSubscription: Subscription | undefined;
    onCanEditSubscription: Subscription | undefined;
    logoutSubscription: Subscription | undefined;
    onSwitchToEditShowSubscription: Subscription | undefined;
    onChangeEditShowSubscrition: Subscription | undefined;
    onUserPrefsChangeSubscription: Subscription | undefined;
    onChangePasteStateSubscriptions: Subscription | undefined;
    onStartEditImageSubscriptions: Subscription | undefined;
    onGoogleDocFileIdbyMapIdSubscription: Subscription | undefined;
    showPasteSubcription: Subscription | undefined;
    statShareSubscription: Subscription | undefined;
    onCopyFormatSubscription: Subscription | undefined;
    onDataCommandSubscription: Subscription | undefined;
    getShareDataSubscription: Subscription | undefined;
    onGridChangeSubscription: Subscription | undefined;
    onDesmosLoadPreferenceSubscription: Subscription | undefined;
    onStartEditingSubscription: Subscription | undefined;
    onEndEditingSubscription: Subscription | undefined;
    onImageLoadedData: Subscription | undefined;

    onGetVoicesSubscription: Subscription | undefined;
    onStartSubscription: Subscription | undefined;
    onBoundarySubscription: Subscription | undefined;
    onMarkSubscription: Subscription | undefined;
    isTalkingStateChangedSubscription: Subscription | undefined;
    TalkingStartSubscription: Subscription | undefined;
    TalkingEndSubscription: Subscription | undefined;

    mathOpenRequestSubscription: Subscription | undefined;

    public desmosCalculator: boolean = false;
    public desmosGeometry: boolean = false;
    public desmosFourFunction: boolean = false;
    public desmosScientific: boolean = false;
    public extraAutodraw: boolean = false;

    dbListenerMapsUnsubscribeRef: any;
    dbListenerMapsUnsubscribe: any;
    editVisible = false;
    editBusy: boolean;

    public isGridVisible = false;

    // Quick edit
    private isReceivingEvents: boolean;
    private selectionData: any;
    public pendingQuickString = '';
    public quickEditEnabled = false;
    public quickEditVisible: boolean;
    public topQuick = -1;
    public leftQuick = -1;
    private quickEditing: boolean;
    public quickEditValue: string;
    private _lastQuickNodeCreated: any;
    // Speech edit
    public speechEditEnabled = false;
    public speechEditVisible = false;
    public topSpeechIcon = -1;
    public leftSpeechIcon = -1;
    private _stopSpeech = false;


    public isFormatCopying = false;
    public isEditShowMode: boolean;
    public pasteMap = false;
    private quickEditFromButtonInteraction = false;
    private pendingMapEvents: Array<any>;
    private lastSomeoneSavingTime: Date = new Date(0);
    private backgroundColor = 'transparent';

    public ltx: string = '';  //'$$x=\\frac{\\sqrt{\\sin(x+\\pi)}}{2}$$';

    public isOutlineEnabled: boolean = false;
    public isPdfEnabled: boolean = false;
    public isMathEnabled: boolean = false;

    @ViewChild('headerUserToolbar') headerUserToolbar: ElementRef | undefined;
    @ViewChild('headerChatToolbar') headerChatToolbar: ElementRef | undefined;
    @ViewChild('headerEditToolbar') headerEditToolbar: ElementRef | undefined;
    @ViewChild('headerEditShowToolbar') headerEditShowToolbar: ElementRef | undefined;
    @ViewChild('quickEditInput') inputQuick: ElementRef | undefined;
    @ViewChild('speechEditInput') inputSpeech: ElementRef | undefined;
    @ViewChild('mapcontainer') mapContainerElement: ElementRef | undefined;
    @ViewChild('titleMap') mapTitle: ElementRef | undefined;
    @ViewChild('mapEditor') mapEditor: MapEditorComponent | undefined;
    @ViewChild('mapButton') mapButton: ElementRef<MatButton> | undefined;

    //EXTRA MENU
    public isExtraMenuVisible: boolean = false;
    public hasNotes: boolean = true;
    private wasExtraMenuVisible: boolean = false; //usato per passare da modalita' modifica presentazione

    //EXTRA PANEL
    public isExtraPanelVisible: boolean = false;
    private lastExtraPanelVisible: ExtraItem = ExtraItem.CLOSE;
    popupBlockedSubscription: Subscription | undefined;
    onMapCreatedSubscription: Subscription | undefined;

    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.firebaseAuthService.auth.currentUser !== null && this.authenticationService.getUserEmail() !== this.firebaseAuthService.auth.currentUser?.email) {
            {
                if (this.mapStateService.isDirty) {
                    this.mapStateService.isDirty = false;
                }
                this.authenticationService.reloadCredentials();

                //this.router.navigate(['/home']);
                this.initialize();


                // location.reload();

            }
        } else {
            if (this.firebaseAuthService.auth.currentUser === null) {
                this.mapStateService.readOnly = true;
            }
        }

    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        console.log('Back button pressed');
        //this.youtubeService.clearAllVideos();
        this.goHome();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        if (this.smService) {
            setTimeout(() => {
                this.pasteMap = false;
                this.mapEditService.notifyResize();

                //if (this.platform.isDesktopDevice()) {
                //this.quickEditService.disableAll();
                //this.mapEditor?.backEditClick(); // JHUBA SECONDO ME DOVREBBE ESSERE IL CORE A CHIUDERLE
                //}

                this.updateCanvasOrigin();
                this.smService.resizeCanvas();
                this.youtubeService.videoPictureInPicture();
            });
        }
    }

    @HostListener('document:contextmenu', ['$event'])
    hideDefaultCtxMenu() {
        if (this.pasteMap) { return true; }
        return false;
    }


    @HostListener('window:afterprint', ['$event'])
    onafterprint(event: any) {
        this.readyToPrint = false;
        this.imageBase64 = null;
        this.removeImgQrCode();
        // Stat
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
        this.statShareSubscription = this.smeService.addCustomMapStat(this.mapStateService.id, MapOperationType.STAT_PRINT, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
    }

    @HostListener('window:beforeprint', ['$event'])
    onbeforeprint(event: any) {
        if (!this.readyToPrint) {
            this.pasteMap = false;
            this.printService.printMap(this.mapStateService.limitPrintSize, this.printService.useQRcodes);
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    onbeforeunload(event: any) {
        localStorage.removeItem('redirectPage');
        localStorage.removeItem('redirectParams');
        localStorage.removeItem('redirectQueryParams');

        if (this.mapStateService.isDirty) {
            const confirmationMessage = '\o/';
            event.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;              // Gecko, WebKit, Chrome <34
        } else return '';
    }

    @HostListener('window:keydown', ['$event'])
    keyDownEvent(event: KeyboardEvent) {
        setTimeout(() => {
            this.pasteMap = false;

            //  if (this.mapStateService && !this.mapShowService.isEditShowMode && !this.outlineService.outlineOpened) {

            if (this.mapStateService && !this.mapShowService.isEditShowMode && this.mapStateService.getFocusOnMap()) {
                this._canOpenQuickEdit = (!event.ctrlKey && !event.altKey && !event.metaKey && this.mapStateService.canEnableQuickEdit);
                if (!this.mapStateService.getEditingState() && this.mapStateService.getFocusOnMap()) {
                    if (!this.checkShortcuts(event, event.shiftKey, event.ctrlKey, event.altKey, event.key)) {
                        if (event.key === 'Shift') { // SHIFT
                            this.smService.setShift(true, true);
                        }
                    } else return false;
                }
                if (this._canOpenQuickEdit && !this.renameMapMode && !this.mapStateService.getEditingState()
                    && !this.mapStateService.selectionData.locked && this.mapStateService.getFocusOnMap()) {
                    // attivazione del quick edit alla pressione dei tasti
                    const key = event.key;
                    // console.log(`+++++++++++++++++++++++++++++++++++++++++++++`);
                    // console.log(`KEY CODE PRESSED: ${key}`);
                    // console.log(`+++++++++++++++++++++++++++++++++++++++++++++`);
                    if (isQuickKey(key)) {
                        if (!this.quickEditService.isQuickEditEnabled() && !this.mathService.isMathOpen) {
                            this.pendingQuickString = '';
                            this.quickEditService.toggleQuickEdit();
                        }
                        if (!this.quickEditing) {
                            if (this.quickEditFromButtonInteraction) {
                                this.pendingQuickString = '';
                            }
                            this.pendingQuickString += event.key;
                            this.quickEditValue = this.pendingQuickString;
                            if (this.inputQuick) {
                                if (!this.quickEditFromButtonInteraction) {
                                    this.inputQuick.nativeElement.value = this.quickEditValue;
                                }
                            }
                        } // else {
                        //    this.pendingQuickString = '';
                        // }
                    }
                    if (this.quickEditService.isSpeechEditEnabled()) {
                        this.onSpeechEditKeyup(event);
                    }


                }
            }
            if (!this.mapStateService.getFocusOnMap()) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    return false;
                }

                // this.checkShortcuts(event, event.shiftKey, event.ctrlKey, event.altKey, event.key);           
            }
            return false;
        });


    }

    @HostListener('document:copy', ['$event'])
    onCopyEvent(event: ClipboardEvent) {
        const copyType = this.mapStateService.copyType;
        console.log('copy type: ' + copyType);
        if (!this.mapStateService.getEditingState() && !this.mapStateService.isCopyingMapImage && this.mapStateService.getFocusOnMap()) {
            this.copyFragment(event, copyType);
        }
    }

    @HostListener('document:focusout')
    onFocusOut() {
        console.log('On Focus out');
        this.smService.resetDrag();
    }

    // @HostListener('window:paste', ['$event'])

    @HostListener('document:paste', ['$event'])
    onPasteEvent(event: ClipboardEvent) {
        console.log('paste');
        if (!this.mapStateService.getEditingState() && this.mapStateService.getFocusOnMap() && !this.mapShowService.isEditShowMode && !this.mapStateService.isLocked) {
            this.pasteFragment(event);
            this.pasteMap = false;
        }
    }

    @HostListener('document:cut', ['$event'])
    onCutEvent(event: ClipboardEvent) {
        console.log('cut');
        if (!this.mapStateService.getEditingState() && this.mapStateService.getFocusOnMap()) {
            this.cutFragment(event);
        }
    }

    @HostListener('window:keyup', ['$event'])
    onKeyUpEvent(event: KeyboardEvent) {
        setTimeout(() => {
            this.pasteMap = false;
            if (this.mapStateService) {
                const key = event.keyCode;
                this._canOpenQuickEdit = (!event.ctrlKey && !event.altKey && !event.metaKey);
                if (!this.mapStateService.getEditingState()) {
                    if (key === 16) { // SHIFT
                        this.smService.setShift(false, true);
                    }
                }
            }
        });
    }

    onQuickEditChange(event: any) {
        if (this.quickEditEnabled) {
            const text: string = event.srcElement.value;
            this.quickEditValue = text;
            if (this.platform.isMobileOrTabletDevice()) {
                if (this.quickEditValue === '') {
                    this.quickEditVisible = false;
                    if (this.quickEditService.isQuickEditEnabled()) { this.quickEditService.toggleQuickEdit(); }
                } else {
                    if (this.completeQuickEdit()) {
                        setTimeout(() => {
                            this.quickEditValue = '';
                            this.startQuickEdit();
                            this.quickEditEnabled = true;
                        });
                    }
                }
            }
        }
    }

    onQuickEditKeyup(ev: any) {
        // ENTER
        if (this.pendingQuickString !== '' && !this.quickEditing) {
            this.quickEditing = true;
            // this.quickEditValue += this.pendingQuickString;
            // this.inputQuick.nativeElement.value = this.quickEditValue;
            this.pendingQuickString = '';
        }
        if (ev.keyCode === 13) {
            if (this.quickEditValue === '') {
                this.quickEditVisible = false;
                if (this.quickEditService.isQuickEditEnabled()) { this.quickEditService.toggleQuickEdit(); }
            } else {
                if (this.completeQuickEdit()) {
                    setTimeout(() => {
                        this.quickEditValue = '';
                        this.startQuickEdit();
                        this.quickEditEnabled = true;
                    });
                }
            }
        }
        // ESCAPE
        if (ev.keyCode === 27) {
            this.quickEditVisible = false;
            if (this.quickEditService.isQuickEditEnabled()) { this.quickEditService.toggleQuickEdit(); }
        }
    }

    onSpeechEditKeyup(ev: any) {
        console.log('onSpeechEditKeyup');
        // ENTER passa al nodo successivo
        if (ev.keyCode === 13) {
            this.speechRecognitionService.DestroySpeechObject();
        }
        // ESCAPE esce da inserimento vocale
        if (ev.keyCode === 27) {
            if (this.quickEditService.isSpeechEditEnabled()) { this.stopSpeechEdit(); }
        }
    }

    onEditorOpened() {
        console.log('onEditorOpened');
        this.pasteMap = false;
        this.ttsService.stop();
    }

    onEditorClosed(content: string) {
        console.log('onEditorClosed');
    }

    onKey(event: KeyboardEvent): void {
        setTimeout(() => {
            this.pasteMap = false;
            const key = event.key;
            if (key === 'Enter') {
                this.exitEditMapName();
            }
            if (event.key === 'Escape') {
                this.smService.setEnableKeyPresses(true);
                this.renameMapMode = false;
                // this.mapButton.nativeElement._elementRef.nativeElement.hidden = false;
            }
        });

    }

    onSearchChange(event: any) {
        if (event && event.target && event.target.value)
            this.newMapName = event.target.value;
    }

    constructor(private route: ActivatedRoute, private router: Router,
        private compiler: Compiler,
        private translateService: TranslateService,
        private firebaseAuthService: FirebaseAuthService,
        private smeService: SmeService,
        private smService: SmService,
        private exportSmeService: ExportSmeService,
        private printService: PrintMapService,
        private dirtyDataService: DirtyDataService,
        private zoomService: ZoomService,
        private autosaveService: AutosaveService,
        public mapStateService: MapStateService,
        private smEventBroker: SmEventBroker,
        private ttsService: TtsService,
        private quickEditService: QuickEditService,
        private titleService: Title,
        private speechRecognitionService: SpeechRecognitionService,
        private mapClipboardService: MapClipboardService,
        public extraService: ExtraService,
        private imageMapsService: ImageMapsService,
        private webSearchService: WebSearchService,
        private mapSearchService: MapFindService,
        private desmosService: DesmosService,
        private authenticationService: AuthenticationService,
        private confirmationService: ConfirmationService,
        private youtubeService: YoutubeService,
        private modalService: ModalService,
        private registeredUserService: RegisteredUserService,
        private mapEditService: MapEditService,
        private userPreferenceService: UserPreferenceService,
        private mapShowService: MapShowService,
        private createGoogleDocService: CreateGoogleDocService,
        private createMapDocumentService: CreateMapDocumentService,
        private importMapService: ImportMapService,
        private firebaseService: FirebaseService,
        private messageBoxService: MessageBoxService,
        private usersService: UsersService,
        private chatService: ChatService,
        private tracker: MatomoTracker,
        private outlineService: OutlineService,
        public mathService: MathService,
        private i18nService: I18nService,
        private platform: DeviceService,
        private breakpointObserver: BreakpointObserver,
        private mapFindService: MapFindService,
    ) {
        this.logoUrl = (this.authenticationService.isLab() ? 'assets/sm-logo-48-lab.png' : 'assets/sm-logo-48.png');
        this.extraBackground = (this.authenticationService.isLab() ? '#F28123' : '#2D939C');
        SMX_CONFIG.SHOW_NODE_MATH = this.authenticationService.isLab();
        this.isReceivingEvents = false;
        this.isSaving = false;
        this.pendingMapEvents = new Array<any>();
        this.mapName = this.translateService.instant(extract('MAPEDIT_LOADING'));
        this.isLoading = true;
        this.error = '';
        this.alive = false;
        this.webSearchPanelVisible = false;
        this.mapSearchVisible = false;
        this.mapSearchDefaultPosition = { x: 0, y: 0 };
        this.webSearchDefaultPosition = { x: 0, y: 0 };
        this.isChatPanelVisible = false;
        this.desmosPanelVisible = false;
        this.webPanelVisible = false;
        this.autoDrawPanelVisible = false;
        this.quickEditVisible = false;
        this.quickEditing = false;
        this.quickEditValue = '';
        this.speechEditVisible = false;
        this._canOpenQuickEdit = true;
        this.switchingInput = false;
        this.editBusy = false;
        this.isEditShowMode = false;
        this.lastSomeoneSavingTime = new Date(0);
        let username = '';
        if (this.authenticationService.isAuthenticated()) {
            username = this.authenticationService.getUserEmail();
        }
        this.usersService.init(username, true);
        this.authenticationService.setIcon();

        // JHUBA: TEST VOICES
        // this.onGetVoicesSubscription = ttsService.onGetVoices.subscribe((event: any) => {
        //     console.log(`+++++TTS EVENT: onGetVoices event=${JSON.stringify(event)}`)
        // });
        // this.onStartSubscription = ttsService.onStart.subscribe((event: any) => {
        //     console.log(`+++++TTS EVENT: onStart event=${JSON.stringify(event)}`)
        // });
        // this.onBoundarySubscription = ttsService.onBoundary.subscribe((event: any) => {
        //     console.log(`+++++TTS EVENT: onBoundary event=${JSON.stringify(event)}`)
        // });
        // this.onMarkSubscription = ttsService.onMark.subscribe((event: any) => {
        //     console.log(`+++++TTS EVENT: onMark event=${JSON.stringify(event)}`)
        // });
        // this.isTalkingStateChangedSubscription = ttsService.isTalkingStateChanged.subscribe((event: any) => {
        //     console.log(`+++++TTS EVENT: isTalkingStateChanged event=${JSON.stringify(event)}`)
        // });
        // this.TalkingStartSubscription = ttsService.TalkingStart.subscribe((event: any) => {
        //     console.log(`+++++TTS EVENT: TalkingStart event=${JSON.stringify(event)}`)
        // });
        // this.TalkingEndSubscription = ttsService.TalkingEnd.subscribe((event: any) => {
        //     console.log(`+++++TTS EVENT: TalkingEnd event=${JSON.stringify(
        //         event)}`)
        // });
        // modifica chrome iOS
        this.mapClipboardService.onCopy.subscribe(() => {
            navigator.clipboard.writeText('');
            const copyType = this.mapStateService.copyType;
            console.log('copy type: ' + copyType);
            if (!this.mapStateService.getEditingState() && !this.mapStateService.isCopyingMapImage && this.mapStateService.getFocusOnMap()) {
                this.copyFragment(new ClipboardEvent('copy'), copyType);
            }
        });

        //MOBILE LIMITATIONS
        this.isMobileMode = platform.isMobileMode();

        //LAYOUT BREAKPOINTS
        this.breakpointObserver
            .observe(['(max-width:1260px)'])
            .subscribe((state: BreakpointState) => {
                this.editToolbarCompressed = state.matches;
            });

        this.breakpointObserver
            .observe(['(max-width:920px)'])
            .subscribe((state: BreakpointState) => {
                this.editMenuCompressed = state.matches;
            });

        this.breakpointObserver
            .observe(['(max-width:830px)'])
            .subscribe((state: BreakpointState) => {
                this.editShowToolbarCompressed = state.matches;
            });

        this.breakpointObserver
            .observe(['(max-width:450px)'])
            .subscribe((state: BreakpointState) => {
                this.editMenuHidden = state.matches;
            });
    }

    handleFirestoreError(err: any) {
        logger.error("Handle firestore error: " + err);
        this.saved = false;
        this.resetSaveFlags();
    }

    openMap(data: any) {

        //   this.mapStateService.initialize();

        //  this.mapStateService.readOnly = data.readonly;
        // try {
        //     this.smService.destroy();
        // } catch (error) {
        //     console.log(error);
        // }

        this.getUserPreferencesSubscription = this.smeService.getUserPreferences().subscribe((resp: any) => {
            let res = '';
            if (resp.result) {
                res = resp.result;
            }
            const userPrefs: UserPrefsDto = new UserPrefsDto(res);
            if (this.userPreferenceService) {
                this.userPreferenceService.userPrefs = userPrefs;

            }
            this.loadDesmosPreference(userPrefs.desmosConfig);
            // set default font styles
            UiConstants.nodeFontStyle = userPrefs.nodeTextStyle;
            UiConstants.edgeFontStyle = userPrefs.edgeTextStyle;
            UiConstants.deepFontStyle = userPrefs.deepTextStyle;
            UiConstants.noteFontStyle = userPrefs.noteTextStyle;
            UiConstants.frameNodeImage = userPrefs.framedNodeImage;
            UiConstants.nodeAspect = userPrefs.nodeAspect;
            UiConstants.edgeAspect = userPrefs.edgeAspect;


            SMX_CONFIG.default.IMAGE.FRAME = UiConstants.frameNodeImage;
            // Default node aspect
            switch (UiConstants.nodeAspect['shape']) {
                case 'PREF_SHAPE_RECT':
                    SMX_CONFIG.default.NODE.NODETYPE = 'rectangle';
                    break;
                case 'PREF_SHAPE_ELLIPSE':
                    SMX_CONFIG.default.NODE.NODETYPE = 'ellipse';
                    break;
                case 'PREF_SHAPE_ROUNDRECT':
                    SMX_CONFIG.default.NODE.NODETYPE = 'roundrect';
                    break;
            }
            SMX_CONFIG.default.NODE.BRUSHCOLOR = UiConstants.nodeAspect['background_color'];
            SMX_CONFIG.default.NODE.PENCOLOR = UiConstants.nodeAspect['border_color'];
            // Default edge aspect
            switch (UiConstants.edgeAspect['type']) {
                case 'PREF_EDGE_STRAIGHT':
                    SMX_CONFIG.default.EDGE.AUTOCURVED = 'false';
                    break;
                case 'PREF_EDGE_CURVE':
                    SMX_CONFIG.default.EDGE.AUTOCURVED = 'true';
                    break;
            }
            SMX_CONFIG.default.EDGE.PENCOLOR = UiConstants.edgeAspect['color'];
            switch (UiConstants.edgeAspect['width']) {
                case '1':
                    SMX_CONFIG.default.EDGE.PENWIDTH = 1;
                    break;
                case '2':
                    SMX_CONFIG.default.EDGE.PENWIDTH = 2;
                    break;
                case '3':
                    SMX_CONFIG.default.EDGE.PENWIDTH = 3;
                    break;
                case '4':
                    SMX_CONFIG.default.EDGE.PENWIDTH = 4;
                    break;
            }
            SMX_CONFIG.default.EDGE.FREEEDGE = (UiConstants.edgeAspect['freeEdge'] === 'true');


            this.loadMap(data.mapJson);

            this.smService.toggleFreeEdge(SMX_CONFIG.default.EDGE.FREEEDGE);
            this.smService.updateConfigImageFrame(userPrefs.framedNodeImage);
            this.addMapEvent('user_in', '').then(() => { }).catch((err => {
                this.handleFirestoreError(err);

            }));

            this.applyMapEvents(this.pendingMapEvents);
            this.mapStateService.viewMode = false;
            this.mapStateService.isNew = this.isNewMap();
            this.mapStateService.googleFileId = data.googleFileId;
            this.mapStateService.googleUri = data.googleUri;
            this.mapStateService.setLoaded(true);
            this.mapStateService.setBusyState(false);
            this.alive = true;
            logger.time('loadMap', 'END loadMap');
            logger.stopTimer('loadMap');
            this.outlineService.checkOwner();
            // Richiede il google Id dell'eventuale file google doc creato. I componenti si sottoscrivono all'evento onchange
            this.createGoogleDocService.findGoogleDocFileIdbyMapId(this.mapStateService.id).subscribe(() => {
            });

            this.openLastExtra();

            setTimeout(() => {
                this.firebaseService.addSharedStateChangeListener('', this.mapStateService.id, this.authenticationService.getUserEmail()).then(() => {
                    console.log('refresh home');
                });
            }, 5000);

            setTimeout(() => {
                this.updateCanvasOrigin();
                this.initAutosaveTimer();
            });
        });
    }

    private openLastExtra() {
        const usermail = this.authenticationService.getUserEmail();
        if (usermail != "") {
            const mapId = this.mapStateService.id;
            const lastExtra = this.userPreferenceService.getCookie("last_opened_extra", [usermail, mapId]) as ExtraItem;
            if (lastExtra && lastExtra != ExtraItem.CLOSE) {
                //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!LAST OPENED EXTRA " + lastExtra.toString());
                this.extraService.toggleExtraPanel(lastExtra, undefined, true);
            }
        }
    }

    private isNewMap(): boolean {
        let i = 0;
        let node: any;
        for (const nodeId of Object.keys(this.smService.sm.map.nodes)) {
            if (i === 0) {
                node = this.smService.sm.map.nodes[nodeId];
            }
            i++;
        }
        if (i === 1 && node.titleText === '') {
            return true;
        } else if (i === 0) {
            return true;
        }
        return false;
    }

    // askToRestore(data: any, trashed: boolean) {
    //     if (this.restoreSubscription) { this.restoreSubscription.unsubscribe(); }
    //     this.restoreSubscription = this.confirmationService.confirm(
    //         this.translateService.instant(extract('RESTORE_TITLE')),
    //         this.translateService.instant(extract('RESTORE_MESSAGE')),
    //         this.translateService.instant(extract('BUTTON_YES')),
    //         this.translateService.instant(extract('BUTTON_NO')))
    //         .subscribe({
    //             next: (result: boolean) => {
    //                 this.isLoading = true;
    //                 if (!result) {
    //                     this.router.navigate(['home']);
    //                 } else {
    //                     if (trashed) {
    //                         this.smeService.restoreMap(this.mapStateService.id).subscribe(() => {
    //                             this.openMap(data);
    //                         }, (error) => {
    //                             logger.error(error);
    //                             this.isLoading = false;
    //                             this.mapStateService.setBusyState(false);
    //                             const errorCode = (error.code) ? error.code : 'MAPEDIT_ERROR_GENERIC_MAP_LOAD';
    //                             this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
    //                         });
    //                     } else {
    //                         this.smeService.restoreMapFromStorage(this.mapStateService.id).subscribe({
    //                             next: (_data: any) => {
    //                                 this.openMap(_data);
    //                             },
    //                             error: (error) => {
    //                                 logger.error(error);
    //                                 this.isLoading = false;
    //                                 this.mapStateService.setBusyState(false);
    //                                 const errorCode = (error.code) ? error.code : 'MAPEDIT_ERROR_GENERIC_MAP_LOAD';
    //                                 this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
    //                             }
    //                         });
    //                     }
    //                 }
    //             },
    //             error: (error) => {
    //                 logger.error(error);
    //                 this.isLoading = false;
    //                 this.mapStateService.setBusyState(false);
    //                 const errorCode = (error.code) ? error.code : 'MAPEDIT_ERROR_GENERIC_MAP_LOAD';
    //                 this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
    //             }
    //         });
    // }

    // paste() {

    //     const isSupported = document.queryCommandSupported('paste');

    //     // if (isSupported) {
    //     document.execCommand('paste');
    //     //  } else {
    //     // navigator['clipboard'].read().then((data: any) => {
    //     //     for (let i = 0; i < data.items.length; i++) {
    //     //         if (data.items[i].type !== 'text/plain') {
    //     //             alert('Clipboard contains non-text data. Unable to access it.');
    //     //         } else {
    //     //             this.mapClipboardService.content = data.items[i].getAs('text/plain');
    //     //         }
    //     //     }
    //     // });
    //     // navigator['clipboard'].read().then((data: DataTransfer) => {

    //     //     this.mapClipboardService.content = data.getData('MapFragment');
    //     //     if (this.mapClipboardService.content) {
    //     //         this.mapClipboardService.pasteFragment();
    //     //     }

    //     // });
    //     // }


    // }


    ngOnInit() {
        // this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
        //     this.mapStateService.id = params['mapId'];
        // });
        // this.firebaseAuthService.onTokenReady.subscribe(() => {


        this.initialize();
        // });
    }

    initialize() {
        logger.startTimer('loadMap');
        logger.debug('map-edit ngOnInit()');
        let lang = localStorage.getItem('language');
        if (lang === 'null') lang = this.translateService.currentLang;
        this.setGuiLanguage(lang + '');

        this.compiler.clearCache();
        this.compiler.clearCacheFor(MatIcon);
        let mapData: any;
        if (!this.userPreferenceService.userPrefs) {
            this.getUserPreferencesSubscription = this.smeService.getUserPreferences().subscribe((resp: any) => {
                let res = '';
                if (resp.result) {
                    res = resp.result;
                }
                const userPrefs: UserPrefsDto = new UserPrefsDto(res);
                if (this.userPreferenceService) {
                    this.userPreferenceService.userPrefs = userPrefs;
                    this.loadDesmosPreference(this.userPreferenceService.userPrefs.desmosConfig);
                    this.isExtraMenuVisible = this.userPreferenceService.userPrefs.sideBarOpen;
                }
                // set default font styles
                UiConstants.nodeFontStyle = userPrefs.nodeTextStyle;
                UiConstants.edgeFontStyle = userPrefs.edgeTextStyle;
                UiConstants.deepFontStyle = userPrefs.deepTextStyle;
                UiConstants.noteFontStyle = userPrefs.noteTextStyle;
                UiConstants.nodeAspect = userPrefs.nodeAspect;
                UiConstants.edgeAspect = userPrefs.edgeAspect;
            }, (error: any) => {
                logger.error('getUserPreference().subscribe: ' + error);
            });

        }
        else {

            this.loadDesmosPreference(this.userPreferenceService.userPrefs.desmosConfig);
            this.isExtraMenuVisible = this.userPreferenceService.userPrefs.sideBarOpen;
        }

        // Enabled modules
        this.isOutlineEnabled = this.authenticationService.isOutlineEnabled();
        this.isPdfEnabled = this.authenticationService.isPdfEnabled();
        this.isMathEnabled = this.authenticationService.isMathEnabled();
        this.mathService.init(this.isMathEnabled);

        this.youtubeService.videoPosition = this.youtubeService.VIDEO_POSITION.ON_NODE;

        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            this.mapStateService.initialize();
            this.mapStateService.id = params['mapId'];
            this.chatService.init(this.mapStateService.id);
            if (!this.authenticationService.isAuthenticated()) {
                this.router.navigate(['login', '/map-edit/' + this.mapStateService.id]);
            } else {
                const isMocked = !(environment.production) && (this.mapStateService.id === 'mock');

                if (isMocked) {
                    this.initListeners();
                    this.loadMockedMap();
                } else {
                    this.onBusyStateChangeSubscription = this.mapStateService.onBusyStateChange.subscribe((isBusy: boolean) => {
                        this.isLoading = isBusy;
                    });

                    this.mapStateService.editOnText = SMX_CONFIG.EDIT_ON_TEXT;
                    this.mapStateService.setBusyState(true);
                    setTimeout(() => { // senza  settimeout funziona male l'inserimento rapido
                        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
                        const email = this.authenticationService.getUserEmail();
                        const firebaseUserId = this.authenticationService.credentials?.firebaseUserId + '';
                        this.getShareDataSubscription = this.smeService.getShareData(email, firebaseUserId, this.mapStateService.id).subscribe(((resp: any) => {
                            const shareData = (resp && resp.shareData ? resp.shareData : {});
                            if (shareData) {
                                //this.mapStateService.isMine = shareData.isMine;
                                this.mapStateService.isMine = (shareData.isMine);
                                this.mapStateService.readOnly = (shareData.isReadonly);
                                if (shareData.isMine || shareData.isShared) {
                                    this.checkSessionIdSubscription = this.smeService.checkSessionId().subscribe({
                                        next: (accessOK: any) => {
                                            if (accessOK.ok && accessOK.result) {
                                                // accessToken = _accessToken;
                                                this.downloadJsonSubscription = this.smeService.downloadJsonToEdit(this.mapStateService.id).subscribe({
                                                    next: (_mapData: any) => {
                                                        mapData = _mapData;
                                                        this.mapStateService.googleFileId = _mapData.googleFileId;
                                                        if (!mapData.mapJson) {
                                                            this.isLoading = false;
                                                            // this.askToRestore(mapData, false);
                                                            this.messageBoxService.showTextMessage(
                                                                this.messageBoxService.MODE_TYPE.OK,
                                                                this.translateService.instant('WARNING'),
                                                                this.translateService.instant('ERROR_OWNER_NOT_FOUND')
                                                            ).subscribe(() => {
                                                                this.router.navigate(['home']);
                                                            })
                                                        } else {
                                                            if ((!shareData.isMine && shareData.isReadonly) || this.authenticationService.isUserExpired()) {
                                                                this.router.navigate(['map-view/', this.mapStateService.id]);
                                                            } else {

                                                                this.openMap(mapData);

                                                                // const isTrashed = mapData.trashed || mapData.explicitlyTrashed;
                                                                // if (isTrashed) {
                                                                //     this.isLoading = false;
                                                                //     this.askToRestore(mapData, true);
                                                                // } else {
                                                                //     this.openMap(mapData);
                                                                // }
                                                            }
                                                        }
                                                        logger.info('Check double access SUCCESS');
                                                        // Check whitelist
                                                        this.findRegisteredUserSubscription = this.registeredUserService.findRegisteredUser(this.authenticationService.getUserEmail()).subscribe((registeredUser: any) => {
                                                            if (registeredUser.valid) {
                                                                const reload = (registeredUser.config !== this.authenticationService.credentials?.config);
                                                                this.authenticationService.updateRegisteredUser(registeredUser.startDate, registeredUser.expireDate, registeredUser.admin, registeredUser.config);
                                                                if (reload) {
                                                                    (window as any).location.reload();
                                                                }
                                                            } else {
                                                                this.isLoading = false;
                                                                this.loginError = true;
                                                                this.authenticationService.logout();
                                                                this.mapStateService.setBusyState(false);
                                                                this.mapStateService.setStateError(this.translateService.instant(extract('ERR_LOGIN_INVALID_CREDENTIALS')));
                                                                return;
                                                            }
                                                        });

                                                    },
                                                    error: (error: any) => {
                                                        logger.error(error);
                                                        this.isLoading = false;
                                                        this.mapStateService.setBusyState(false);
                                                        this.authenticationService.reloadCredentials();
                                                        if (this.authenticationService.isAuthenticated()) {


                                                            if (error.status === 504 || error.status === 403 || navigator.onLine !== true) {
                                                                this.mapStateService.setStateError(this.translateService.instant(extract('OFFLINE_GENERIC')));
                                                            } else if (error.status === 404) {
                                                                this.mapStateService.setStateError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                                                            } else {
                                                                const errorCode = (error.code) ? error.code : 'MAPEDIT_ERROR_GENERIC_MAP_LOAD';
                                                                this.loadingError = true;
                                                                this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
                                                            }
                                                        } else {
                                                            this.router.navigate(['login', '/map-edit/' + this.mapStateService.id]);
                                                        }
                                                    }
                                                });
                                            } else {
                                                // Doppio accesso logout
                                                logger.info('Check double access FAILED');
                                                this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'double_access=1' }));
                                            }
                                        },
                                        error: (error: any) => {
                                            this.isLoading = false;
                                            if (error.status === 504 || error.status === 403 || navigator.onLine === true) {
                                                this.mapStateService.setStateError(this.translateService.instant(extract('OFFLINE_GENERIC')));
                                            } else {
                                                const errorCode = (error.code) ? error.code : 'MAPEDIT_ERROR_GENERIC_MAP_LOAD';
                                                this.loadingError = true;
                                                this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
                                            }
                                        }
                                    });
                                } else {
                                    this.authenticationService.reloadCredentials();
                                    if (this.authenticationService.isAuthenticated()) {

                                        this.showError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                                        this.mapStateService.setStateError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                                    } else {
                                        this.router.navigate(['login', '/map-edit/' + this.mapStateService.id]);
                                    }
                                }
                            }
                        }));

                        // }, (error: any) => {
                        //     logger.error(error);
                        //     this.isLoading = false;
                        //     this.mapStateService.setBusyState(false);
                        //     if (error.type === 'userLoggedOut') {
                        //         // this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
                        //         this.authenticationService.logout().subscribe(() => this.router.navigate(['login', '/map-edit/' + this.mapStateService.id]));
                        //     } else {
                        //         this.loadingError = true;
                        //         const errorCode = (error.code) ? error.code : 'MAPEDIT_ERROR_GENERIC_MAP_LOAD';
                        //         this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
                        //     }
                        // }) .catch((error) => {
                        //     if (navigator.onLine) {
                        //         // this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
                        //         this.authenticationService.logout().subscribe(() => this.router.navigate(['login', '/map-edit/' + this.mapStateService.id]));
                        //         logger.error('Map-Edit getAccessToken error: ' + error);
                        //     } else {
                        //         this.isLoading = false;
                        //         this.mapStateService.setBusyState(false);
                        //         const errorCode = (error.code) ? error.code : 'OFFLINE_GENERIC';
                        //         this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
                        //     }
                        // });
                    });
                    this.initListeners();
                }
            }
        });
    }
    setGuiLanguage(language: string) {
        // this.currLanguage = language;
        this.i18nService.language = language;
        const genLang = language.substring(0, 2);
        // this.cookieService.set('GUILanguage', genLang);
        localStorage.setItem('language', language);
        localStorage.setItem('locale', genLang);
        document.querySelector('html')?.setAttribute('lang', genLang);
        (window as any).WEBSPELLCHECKER_CONFIG.localization = genLang;
    }


    ngAfterViewInit() {
        this.resizable(document.getElementById('quickedit'), 7);
        this.resizable(document.getElementById('speechedit'), 7);
    }

    goHome() {
        this.youtubeService.clearAllVideos();
        this.addMapEvent('user_out', '').then(() => {
            // window.location.href = 'home';
            this.router.navigate(['home']);
        }).catch(err => {
            window.location.href = 'home';
        })
    }

    ngOnDestroy(): void {
        logger.debug('map-edit ngOnDestroy()');
        this.addMapEvent('user_out', '');
        this.alive = false;
        if (this.autosaveTimer) { clearInterval(this.autosaveTimer); }
        if (this.dirtyDataChangeSubscription) { this.dirtyDataChangeSubscription.unsubscribe(); }
        if (this.onBusyStateChangeSubscription) { this.onBusyStateChangeSubscription.unsubscribe(); }
        if (this.paramMapSubscription) { this.paramMapSubscription.unsubscribe(); }
        if (this.showPasteSubcription) { this.showPasteSubcription.unsubscribe(); }
        if (this.onChangePasteStateSubscriptions) { this.onChangePasteStateSubscriptions.unsubscribe(); }
        if (this.onStartEditImageSubscriptions) { this.onStartEditImageSubscriptions.unsubscribe(); }
        if (this.selectionDataSubscription) { this.selectionDataSubscription.unsubscribe(); }
        if (this.onExportChangeSubscription) { this.onExportChangeSubscription.unsubscribe(); }
        if (this.onImageUpdatedSubscription) { this.onImageUpdatedSubscription.unsubscribe(); }
        if (this.onZoomUpdatedSubscritpion) { this.onZoomUpdatedSubscritpion.unsubscribe(); }
        if (this.onToggleExtraSubscrition) { this.onToggleExtraSubscrition.unsubscribe(); }
        if (this.onExtraPanelContentChanged) { this.onExtraPanelContentChanged.unsubscribe(); }
        if (this.onToggleWebSearchSubscription) { this.onToggleWebSearchSubscription.unsubscribe(); }
        if (this.onToggleMapSearchSubscription) { this.onToggleMapSearchSubscription.unsubscribe(); }
        if (this.onToggleChatSubscrition) { this.onToggleChatSubscrition.unsubscribe(); }
        if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }
        if (this.onQuickEditEnableSubscription) { this.onQuickEditEnableSubscription.unsubscribe(); }
        if (this.onSpeechEditEnableSubscription) { this.onSpeechEditEnableSubscription.unsubscribe(); }
        if (this.onCanEditSubscription) { this.onCanEditSubscription.unsubscribe(); }
        if (this.onForceAutosaveSubscription) { this.onForceAutosaveSubscription.unsubscribe(); }
        if (this.onDeepEditSubscription) { this.onDeepEditSubscription.unsubscribe(); }
        if (this.onShowImageSubscription) { this.onShowImageSubscription.unsubscribe(); }
        if (this.videoUrlChangedDataSubscription) { this.videoUrlChangedDataSubscription.unsubscribe(); }
        if (this.onErrorSubscription) { this.onErrorSubscription.unsubscribe(); }
        if (this.speechRecordSubscription) { this.speechRecordSubscription.unsubscribe(); }
        if (this.uploadWorkJsonSubscription) { this.uploadWorkJsonSubscription.unsubscribe(); }
        if (this.renameMapSubscription) { this.renameMapSubscription.unsubscribe(); }
        if (this.interactionModeSubscription) { this.interactionModeSubscription.unsubscribe(); }
        if (this.onCanEnableQuickEditChangeSubscription) { this.onCanEnableQuickEditChangeSubscription.unsubscribe(); }
        if (this.inputDialogSubscription) { this.inputDialogSubscription.unsubscribe(); }
        if (this.restoreSubscription) { this.restoreSubscription.unsubscribe(); }
        if (this.onChangeMapNameSubscription) { this.onChangeMapNameSubscription.unsubscribe(); }
        if (this.findRegisteredUserSubscription) { this.findRegisteredUserSubscription.unsubscribe(); }
        if (this.checkSessionIdSubscription) { this.checkSessionIdSubscription.unsubscribe(); }
        if (this.getLinkPreviewSubscription) { this.getLinkPreviewSubscription.unsubscribe(); }
        if (this.logoutSubscription) { this.logoutSubscription.unsubscribe(); }
        if (this.getUserPreferencesSubscription) { this.getUserPreferencesSubscription.unsubscribe(); }
        if (this.onSwitchToEditShowSubscription) { this.onSwitchToEditShowSubscription.unsubscribe(); }
        if (this.onChangeEditShowSubscrition) { this.onChangeEditShowSubscrition.unsubscribe(); }
        if (this.onGoogleDocFileIdbyMapIdSubscription) { this.onGoogleDocFileIdbyMapIdSubscription.unsubscribe(); }
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
        if (this.onCopyFormatSubscription) { this.onCopyFormatSubscription.unsubscribe(); }
        if (this.onDataCommandSubscription) { this.onDataCommandSubscription.unsubscribe(); }
        if (this.dbListenerMapsUnsubscribe) { this.dbListenerMapsUnsubscribe(); }
        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
        if (this.onDesmosLoadPreferenceSubscription) { this.onDesmosLoadPreferenceSubscription.unsubscribe(); }
        if (this.onEndEditingSubscription) this.onEndEditingSubscription.unsubscribe();
        if (this.onStartEditingSubscription) this.onStartEditingSubscription.unsubscribe();
        if (this.popupBlockedSubscription) this.popupBlockedSubscription.unsubscribe();
        if (this.onMapCreatedSubscription) this.onMapCreatedSubscription.unsubscribe();

        // JHUBA: TEST VOICES
        if (this.onGetVoicesSubscription) this.onGetVoicesSubscription.unsubscribe();
        if (this.onStartSubscription) this.onStartSubscription.unsubscribe();
        if (this.onBoundarySubscription) this.onBoundarySubscription.unsubscribe();
        if (this.onMarkSubscription) this.onMarkSubscription.unsubscribe();
        if (this.isTalkingStateChangedSubscription) this.isTalkingStateChangedSubscription.unsubscribe();
        if (this.TalkingStartSubscription) this.TalkingStartSubscription.unsubscribe();
        if (this.TalkingEndSubscription) this.TalkingEndSubscription.unsubscribe();
        if (this.mathOpenRequestSubscription) this.mathOpenRequestSubscription.unsubscribe();

        this.smService.destroy();
        this.smEventBroker.clearData();

        this.mapStateService.clear();
    }

    private loadDesmosPreference(desmosValue: DesmosConfigDto) {

        this.desmosCalculator = desmosValue.calculator;
        this.desmosFourFunction = desmosValue.function;
        this.desmosGeometry = desmosValue.geometry;
        this.desmosScientific = desmosValue.scientific;
        this.extraAutodraw = desmosValue.autoDraw;
    }

    private initListeners() {

        // this.smEventBroker.initialize();

        // this.onDesmosLoadPreferenceSubscription = this.desmosService.onChangeDesmosConfig.
        //     subscribe({
        //         next: (desmosConfig: DesmosConfigDto) => {
        //             this.loadDesmosPreference(desmosConfig);
        //             this.isLoading = false;
        //         },
        //         error: (error: any) => {
        //             console.error(error);
        //         }

        //     });
        // this.desmosService.loadPreference();

        if (this.smEventBroker && this.smEventBroker.onStartUpdatingData)
            this.onStartEditingSubscription = this.smEventBroker.onStartUpdatingData.subscribe(() => {
                this.mapStateService.setBusyState(true);
            });

        if (this.smEventBroker && this.smEventBroker.onEndUpdatingData)
            this.onEndEditingSubscription = this.smEventBroker.onEndUpdatingData.subscribe(() => {
                this.mapStateService.setBusyState(false);
            });

        if (this.smEventBroker && this.smEventBroker.onImageLoadedData) {
            this.onImageLoadedData = this.smEventBroker.onImageLoadedData.subscribe(() => {
                this.mapStateService.onImageLoaded.emit();
            });
        }

        this.onCopyFormatSubscription = this.mapStateService.onCopyFormatStateChange.subscribe((value: boolean) => {
            this.isFormatCopying = value;
        });

        this.onCanEnableQuickEditChangeSubscription = this.mapStateService.onCanEnableQuickEditChange
            .subscribe((value: boolean) => {
                this._canOpenQuickEdit = value;
            });

        this.onChangeMapNameSubscription = this.mapStateService.onChangeMapName
            .subscribe((value: string) => {
                if (value !== '') {
                    this.mapName = value;
                }
            });

        this.mapStateService.onGridChange.subscribe((value: boolean) => {
            this.setGrid(value);
        });

        if (this.dirtyDataService.dirtyDataChange) {
            this.dirtyDataChangeSubscription = this.dirtyDataService.dirtyDataChange
                .subscribe((dirtyDataStatus: DirtyDataStatus) => {
                    this.mapStateService.index = dirtyDataStatus.index;
                    this.mapStateService.isDirty = dirtyDataStatus.isDirty;
                    if (this.mapStateService.isDirty) {
                        this.saveStatus = 2;
                        this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
                        this.saved = false;
                    } else {
                        this.saveStatus = 0;
                        //this.saveStatusMessage = '';
                        this.saveStatusMessage = this.translateService.instant(extract('ALLSAVED'));
                        this.saved = true;
                    }
                });
        }

        // this.onBusyStateChangeSubscription = this.mapStateService.onBusyStateChange.subscribe((isBusy: boolean) => {
        //     this.isLoading = isBusy;
        // });

        // this.mapStateService.editOnText = SMX_CONFIG.EDIT_ON_TEXT;
        // this.mapStateService.setBusyState(true);
        this.onStartEditImageSubscriptions = this.mapStateService.onStartEditImage.subscribe(() => {
            this.modalService.showSelectedNodeImageDialogCropper();

        });

        this.onChangePasteStateSubscriptions = this.mapStateService.onChangePasteState.subscribe((value: boolean) => {
            if (this.userPreferenceService.userPrefs?.usePasteWithouKeyboard) {
                this.pasteMap = value;
            }
        });

        this.onUserPrefsChangeSubscription = this.userPreferenceService.onUserPrefsChange.subscribe((value: boolean) => {
            if (value) {
                const prefs = this.userPreferenceService.userPrefs;
                if (prefs) {
                    this.loadDesmosPreference(prefs.desmosConfig);

                    this.isExtraMenuVisible = prefs.sideBarOpen;
                }

            }
        });

        this.showPasteSubcription = this.mapClipboardService.onShowPaste.subscribe((value: boolean) => {
            // this.quickEditSetup();
            if (this.mapStateService.canvasOrigin) {
                this.leftQuick = this.mapStateService.canvasOrigin.x + this.selectionData.scrCenterX - 100;
                this.topQuick = this.mapStateService.canvasOrigin.y + this.selectionData.scrCenterY;
            }
            if (this.userPreferenceService.userPrefs?.usePasteWithouKeyboard) {
                this.pasteMap = value;
            }
            // document.getElementById('pastearea').contextMenu();
        });

        if (this.smEventBroker.selectionData) {
            this.selectionDataSubscription = this.smEventBroker.selectionData.subscribe((_selectionData: any) => {
                if (this.mathService.isMathOpen && !this.mathService.isMathOpening) {
                    //this.mathService.commitEditToNode(true); tolto autocommit! il commit del contenuto del math editor deve essere esplicito
                    const node = this.smService.getSelectedNode();
                    if (node) {
                        const latex = node.getMathLatex();
                        if (latex !== '') {
                            const openKeyboard = _selectionData.source != "outline";
                            this.mathService.setLatexInEditor(latex, openKeyboard);
                        }
                    }
                    this.mapStateService.onClearMathErrors.emit();
                }
                this.renameMapMode = false;
                // this.mapButton.nativeElement._elementRef.nativeElement.hidden = false;
                this.mapStateService.workJson = _selectionData.mapWorkJson;
                this.selectionData = _selectionData;
                this.mapStateService.selectionData = _selectionData;
                if (_selectionData.language) {
                    const langChanged = this.currentLang !== _selectionData.language;
                    if (langChanged) {
                        this.currentLang = _selectionData.language;
                    }
                } else {
                    if (this.currentLang === '' || this.currentLang === undefined) {
                        this.currentLang = this.mapStateService.mapLanguage;
                    }
                }
                this.pasteMap = false;
                this.quickEditVisible = false;
                if (this.quickEditService.isQuickEditEnabled()) {
                    setTimeout(() => {
                        const selectedElement = this.smService.getSelectedElement();
                        if (selectedElement && selectedElement.className !== 'Edge') {
                            this.startQuickEdit();
                        } else {
                            if (!this.isReceivingEvents) {
                                if (this.quickEditService.isQuickEditEnabled()) { this.quickEditService.toggleQuickEdit(); }
                            }
                        }
                    });
                } else if (this.speechEditEnabled) {
                    setTimeout(() => {
                        const selectedElement = this.smService.getSelectedElement();
                        if (selectedElement && selectedElement.className !== 'Edge') {
                            this.startSpeechEdit();
                        } else {
                            if (!this.isReceivingEvents) {
                                if (this.quickEditService.isSpeechEditEnabled()) { this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.MAP); }
                            }
                        }
                    });
                }
            });
        }

        this.changeBackgroundcolorSubscription = this.mapStateService.onChangedBackColor.subscribe((color: any) => {
            this.backgroundColor = color;
            this.setGrid(this.mapStateService.isGridVisible);
        });

        this.onExportChangeSubscription = this.mapStateService.onExportChange.subscribe((status: any) => {
            this.mapStateService.setBusyState(status);
        });

        this.onErrorSubscription = this.mapStateService.onError.subscribe((error: any) => {
            this.pasteMap = false;
            this.error = error;
            if (error !== '') {
                this.showError(error);
            } else {
                this.hideError();
            }
        });

        this.onImageUpdatedSubscription = this.printService.onImageUpdated.subscribe((imageBase64: any) => {
            if (!this.readyToPrint) {
                this.readyToPrint = true;
                this.imageBase64 = imageBase64.image;
            }
        });

        this.onToggleExtraSubscrition = this.extraService.onToggleExtraPanel.subscribe((extraOpenDto: ExtraOpenDto) => {
            this.isExtraPanelVisible = extraOpenDto.extraItem !== ExtraItem.CLOSE
            this.hasNotes = this.extraService.hasNotes();
            this.updateCanvasOrigin();
            this.smService.resizeCanvas();
        });

        this.onExtraPanelContentChanged = this.extraService.onExtraPanelContentChanged.subscribe((extraOpenDto: ExtraOpenDto) => {
            this.hasNotes = this.extraService.hasNotes();
            switch (extraOpenDto.extraItem) {
                case ExtraItem.OPEN_OUTLINE:
                    if (!this.isExtraMenuVisible) {
                        this.isExtraMenuVisible = true;
                        this.userPreferenceService.updateSideBar(true);
                    }
                    this.currentSelectedExtraMenuButtonId = document.getElementById("outline-extra-menu-button");
                    break;
                case ExtraItem.OPEN_NOTEPAD:
                    if (!this.isExtraMenuVisible) {
                        this.isExtraMenuVisible = true;
                        this.userPreferenceService.updateSideBar(true);
                    }
                    this.currentSelectedExtraMenuButtonId = document.getElementById("appunti-extra-menu-button");
                    break;

                case ExtraItem.OPEN_PDF: {
                    if (!this.isExtraMenuVisible) {
                        this.isExtraMenuVisible = true;
                        this.userPreferenceService.updateSideBar(true);
                    }
                    this.currentSelectedExtraMenuButtonId = document.getElementById("pdf-extra-menu-button");
                    break;
                }
                case ExtraItem.OPEN_AUTODRAW:
                    if (!this.isExtraMenuVisible) {
                        this.isExtraMenuVisible = true;
                        this.userPreferenceService.updateSideBar(true);
                    }

                    this.currentSelectedExtraMenuButtonId = document.getElementById("autodraw-extra-menu-button");
                    break;
                default:
                    break;
            }
        });

        // this.onToggleWebSubscrition = this.webService.onToggleWebPanel.subscribe((opened: boolean) => {
        //     if (opened && this.isExtraPanelVisible) {
        //         this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
        //     }

        //     if (opened && this.desmosPanelVisible) {
        //         this.desmosService.toggleDesmosPanel();
        //     }
        //     // this.isExtraPanelVisible = opened || this.extraPanelVisible;
        //     this.webPanelVisible = opened;
        //     this.updateCanvasOrigin();
        //     this.smService.resizeCanvas();
        // });

        // this.onToggleDesmosSubscrition = this.desmosService.onToggleDesmosPanel.subscribe((opened: boolean) => {
        //     if (opened && this.isExtraPanelVisible) {
        //         this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
        //     }
        //     if (opened && this.webService.webPanelOpen) {
        //         this.webService.toggleWebPanel();
        //     }
        //     this.isExtraPanelVisible = opened || this.isExtraPanelVisible;
        //     this.desmosPanelVisible = opened;
        //     this.updateCanvasOrigin();
        //     this.smService.resizeCanvas();
        // });

        this.onToggleWebSearchSubscription = this.webSearchService.onToggleWebSearchPanel.subscribe((opened: boolean) => {
            this.webSearchPanelVisible = opened;
            if (opened) {
                setTimeout(() => {
                    this.setWebSearchPosition();
                }, 0);
            }
        });

        this.onToggleMapSearchSubscription = this.mapSearchService.openFindInMap.subscribe((opened: boolean) => {
            this.mapSearchVisible = opened;
            if (opened) {
                setTimeout(() => {
                    this.setMapSearchPosition();
                }, 0);
            }
        });

        this.onToggleChatSubscrition = this.chatService.onToggleChatPanel.subscribe((opened: boolean) => {
            this.isChatPanelVisible = opened;
            // this.updateCanvasOrigin();
            this.smService.resizeCanvas();
        });

        if (this.zoomService.zoomDataChange) {
            this.onZoomUpdatedSubscritpion = this.zoomService.zoomDataChange.subscribe((data: any) => {
                this.pasteMap = false;
                if (this.quickEditService.isQuickEditEnabled()) {
                    this.quickEditService.toggleQuickEdit();
                }
                if (this.quickEditService.isSpeechEditEnabled()) {
                    this.stopSpeechEdit();
                }
            });
        }

        this.onQuickEditEnableSubscription = this.quickEditService.onQuickEditEnable.subscribe((enabled: boolean) => {
            if (this.quickEditFromButtonInteraction) {
                if (!enabled) this.quickEditValue = '';
            } else {
                this.quickEditValue = '';
            }

            this.quickEditEnabled = enabled;
            if (enabled) {
                this.pasteMap = false;
                this.quickEditFromButtonInteraction = this.quickEditService.quickEditFromButtonInteraction;

                this.startQuickEdit();
            } else {
                this.stopQuickEdit();
            }
            this.quickEditService.quickEditFromButtonInteraction = false;
        });

        this.onSpeechEditEnableSubscription = this.quickEditService.onSpeechEditEnable.subscribe((enabled: boolean) => {
            if (this.quickEditService.origin === this.quickEditService.ORIGIN.MAP) {
                this.speechEditEnabled = enabled;
                if (enabled) {
                    this.pasteMap = false;
                    this.startSpeechEdit();
                } else {
                    this.stopSpeechEdit();
                }
            }
        });

        this.onForceAutosaveSubscription = this.mapStateService.onForceAutosave.subscribe(() => {
            this.forceAutosave();
        });

        this.onCanEditSubscription = this.quickEditService.onCanEdit.subscribe((canOpenQuickEdit: boolean) => {
            this._canOpenQuickEdit = canOpenQuickEdit;
            if (!canOpenQuickEdit) {
                if (this.quickEditService.isQuickEditEnabled()) {
                    this.quickEditService.toggleQuickEdit();
                }
                if (this.quickEditService.isSpeechEditEnabled()) {
                    if (this.quickEditService.origin === this.quickEditService.ORIGIN.MAP) {
                        this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.MAP);
                    }
                }
            }
        });

        if (this.smEventBroker.showImageData) {
            this.onShowImageSubscription = this.smEventBroker.showImageData.subscribe((node: any) => {
                if (this.smService.sm) {
                    if (node && node.image && node.image.name) {
                        this.modalService.showImageContent(true, node.id);

                    }
                }

            });
        }

        if (this.smEventBroker.editDeepData) {
            this.onDeepEditSubscription = this.smEventBroker.editDeepData.subscribe((node: any) => {
                this.pasteMap = false;
                if (this.smService.sm) {
                    if (node && node.deepHtml && node.deepText && node.nodeData && node.nodeData.language) {
                        this.modalService.showDeepContent(node.id, node.deepHtml, node.deepText, node.nodeData.language);
                    }
                }
            });
        }
        if (this.smEventBroker.videoUrlChangedData) {
            this.videoUrlChangedDataSubscription = this.smEventBroker.videoUrlChangedData.subscribe((data: any) => {
                if (data && data.node) {
                    this.youtubeService.updateYoutubeVideoInNode(data.node);
                }
            });
        }

        this.onSwitchToEditShowSubscription = this.mapShowService.onSwitchToEditShow.subscribe((isEditShow: boolean) => {
            this.pasteMap = false;
            this.isEditShowMode = isEditShow;
            if (isEditShow) {
                if (this.webSearchPanelVisible) {
                    this.webSearchService.toggleWebSearchPanel();
                }

                //salvataggio dati extrapanel e extramenu
                this.wasExtraMenuVisible = this.isExtraMenuVisible;
                this.lastExtraPanelVisible = this.extraService.lastOpenDto.extraItem;

                if (this.extraService.isExtraPanelOpen) {
                    this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
                }
                if (this.isExtraMenuVisible)
                    this.toggleExtraMenuVisibility();

                this.youtubeService.clearAllVideos();

                if (this.mathService.isMathOpen) {
                    this.mathService.toggleMath();
                    this.mathService.init(false);
                }

            } else {//ripristino stato extramenu e extrapanel
                if (this.wasExtraMenuVisible)
                    this.toggleExtraMenuVisibility();
                if (this.lastExtraPanelVisible != ExtraItem.CLOSE)
                    this.extraService.toggleExtraPanel(this.lastExtraPanelVisible, undefined, true);

                this.isMathEnabled = this.authenticationService.isMathEnabled();
                this.mathService.init(this.isMathEnabled);
            }
        });

        this.onChangeEditShowSubscrition = this.mapShowService.onChangeEditShow.subscribe(() => {
            this.pasteMap = false;
            this.forceAutosave();
        });

        if (this.smEventBroker.onDataCommandChangedData) {
            this.onDataCommandSubscription = this.smEventBroker.onDataCommandChangedData.subscribe((data: any) => {
                if (data !== null) {
                    const payload = JSON.stringify(data);
                    this.addMapEvent('command', payload).then(() => { }).catch((err => {
                        this.handleFirestoreError(err);

                    }));
                }
            });
        }

        // Firestore real time events listener
        try {
            this.dbListenerMapsUnsubscribeRef = this.firebaseService.getFirestoreMapsListener(this.mapStateService.id);
            this.dbListenerMapsUnsubscribe = onSnapshot(this.dbListenerMapsUnsubscribeRef, (querySnapshot: QuerySnapshot) => {
                this.receiveMapEvents(querySnapshot);
            });
        } catch (err) {
            console.log(`[MapEdit] FirestoreDB onSnapshot maps ERROR: ${err}`);
        }
    }

    setGrid(visible: boolean) {
        this.isGridVisible = visible;
        this.mapStateService.isGridVisible = visible;
        this.smService.setGridSize(40);
        this.smService.setGrid(visible);
        const cb = document.getElementById('sm-canvas-base');
        const cg = document.getElementById('sm-canvas-grid');
        if (visible) {

            if (cb) cb.style.backgroundColor = 'transparent';
            if (cg) {
                cg.style.backgroundColor = this.backgroundColor;
                cg.style.visibility = 'visible';
            }
        } else {
            if (cb) cb.style.backgroundColor = this.backgroundColor;
            if (cg) {
                cg.style.backgroundColor = 'transparent';
                cg.style.visibility = 'collapse';
            }
        }
    }

    addMapEvent(type: string, payload: string) {
        try {
            const userEmail = this.authenticationService.getUserEmail();
            const userName = this.authenticationService.getUserName();
            const userIcon = (this.authenticationService.getUserImageUrl());
            return this.firebaseService.addMapEvent(this.mapStateService.guid, type, userEmail, userName, userIcon, this.mapStateService.id, payload);
        } catch (err) {
            console.log(`[MapEdit] FirestoreDB addMapEvent ERROR: ${err}`);
            return Promise.reject(err);
        }
    }

    receiveMapEvents(querySnapshot: QuerySnapshot) {
        try {
            this.isReceivingEvents = true;
            console.log('Listening to the projectTransactions events collection');
            const promises: Array<any> = [];
            const events: Array<any> = [];
            querySnapshot.docChanges().forEach((change: any) => {
                // A new transaction has been added
                if (change.type === 'added') { // type can also be 'removed' or 'modified'
                    // console.log(`----->LISTENER: A new transaction has been ADDED with ID: ${change.doc.id}`);
                    const promise = this.firebaseService.getMapsEvent(this.mapStateService.id, change.doc.id, events);
                    promises.push(promise);
                }
            });
            // Get all the events
            Promise.all(promises).then(() => {
                // Sort events in timestamp ascending order
                events.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
                if (this.smService && this.smService.sm) {
                    this.applyMapEvents(events);
                } else {
                    // Initializing: no sm available: wait for map loaded
                    this.pendingMapEvents = events;
                }
            });
            this.isReceivingEvents = false;
        } catch (err) {
            this.isReceivingEvents = false;
            console.log(`[MapEdit] FirestoreDB receiveMapEvents ERROR: ${err}`);
        }
    }

    applyMapEvents(events: Array<any>) {
        if (events && events.length > 0) {
            if (this.smService && this.smService.sm) {
                let atLeast1 = false;
                for (let i = 0; i < events.length; i++) {
                    // Get command from the event
                    const event = events[i];
                    if (event) {
                        if (event.type === 'command') {
                            if (event.guid !== this.mapStateService.guid) {
                                const command = this.firebaseService.getEventPayloadAsJson(event);
                                // Pass command to core to be applied
                                this.smService.applyCommand(command, event.user.email);
                                atLeast1 = true;
                            }
                            // Update active users (users toolbar)
                            this.mapStateService.emitUserEvent(event);
                        } else if (event.type === 'save_start') {
                            if (event.guid !== this.mapStateService.guid) {
                                this.saveStatus = 1;
                                this.saveStatusMessage = this.translateService.instant(extract('SAVING_OTHER')) + ': ' + event.user.name;
                            }
                            const saveTimestamp = new Date(event.timestamp);
                            const now = new Date();
                            const deltamSec = now.getTime() - saveTimestamp.getTime();
                            if (deltamSec < 60000) {
                                // Saves older than 1 minute are ignored
                                this.isSaving = true;
                            }
                            this.lastSomeoneSavingTime = saveTimestamp;
                        } else if (event.type === 'save_end') {
                            this.saveStatus = 0;
                            this.saveStatusMessage = this.translateService.instant(extract('ALLSAVED'));
                            //this.saveStatusMessage = '';
                            this.isSaving = false;
                        } else if (event.type === 'user_in' || event.type === 'user_out') {
                            this.mapStateService.emitUserEvent(event);
                        }
                    }
                }
                if (atLeast1) {
                    this.smService.askOutline();
                }
            } else {
                console.log('CANNOT APPLY MAP EVENTS: NO smService or smService.sm!');
            }
        }
    }

    forceAutosave() {
        // this.smService.sm.updateSelection();
        const selectionData = this.smService.sm.map.getSelectionData(this.smService.sm.stageBase);
        this.mapStateService.workJson = selectionData.mapWorkJson;
        this.saveStatus = 2;
        this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
        this.mapStateService.index++;
        this.mapStateService.isDirty = true;
    }

    initAutosaveTimer() {
        if (this.autosaveTimer) {
            clearInterval(this.autosaveTimer);
        }

        this.autosaveTimer = setInterval(() => {
            if (navigator.onLine === true && this.error !== this.translateService.instant(extract('GATEWAY_TIMEOUT'))) {
                this.autosave();
            } else {
                // this.saveStatus = this.translateService.instant(extract('OFFLINE_STATUS'));
                if (navigator.onLine === true) {
                    this.saveError = this.translateService.instant(extract('ERROR-SAVING'));
                } else {
                    this.saveError = ' ' + this.translateService.instant(extract('OFFLINE_STATUS'));
                }
            }
        }, 5 * 1000);
    }

    /**
     * Carica la mappa per finta: ci serve in sviluppo
     * quando bisogna lavorare soltanto alla grafica della pagina.
     */
    private loadMockedMap() {
        logger.info('LOAD MOCKED MAP!');
        this.isLoading = false;
        this.mapStateService.isNew = false;
        this.mapName = 'MOCKED MAP';
        this.mapStateService.name = this.mapName;
        // this.mapStateService.id = this.mapStateService.id;
        this.mapStateService.setBusyState(false);
        this.alive = true;
    }

    private copyFragment(e: ClipboardEvent, copyType: string) {
        this.mapClipboardService.copy(e, copyType);
        e.preventDefault();
    }

    private pasteFragment(e: ClipboardEvent) {
        if (!this.quickEditing) {
            this.mapClipboardService.paste(e);
            e.preventDefault();
        }
    }

    private cutFragment(e: ClipboardEvent) {
        this.mapClipboardService.cut(e);
        e.preventDefault();
    }

    // True if a shortcut is recognized and fired
    private checkShortcuts(event: KeyboardEvent, shiftKey: boolean, ctrlKey: boolean, altKey: boolean, key: string): boolean {
        let result = false;
        if (!this.mathService.isMathOpen) {
            if (ctrlKey && !altKey && !shiftKey && !event.metaKey) {
                if (key === 'z') {
                    // Ctrl-Z
                    this.dirtyDataService.undo();
                    result = true;
                } else if (key === 'y') {
                    // Ctrl-Y
                    this.dirtyDataService.redo();
                    result = true;
                }
            }
            if (ctrlKey && key === 'a') {
                this.smService.selectAll();
                result = true;
            }
            // // Add others here...
            // if (key === 'ArrowUp') {
            //     // PgUp / Down / Right

            //     this.smService.moveSelection(0, -10);

            //     result = true;
            // } else if (key === 'ArrowDown') {
            //     // PgDn / Up / Left

            //     this.smService.moveSelection(0, 10);


            //     result = true;
            // } else if (key === 'ArrowLeft') {
            //     // PgDn / Up / Left

            //     this.smService.moveSelection(-10, 0);


            //     result = true;
            // } else if (key === 'ArrowRight') {
            //     // PgDn / Up / Left

            //     this.smService.moveSelection(10, 0);

            //     result = true;
            // }
            else if (key === 'Delete') {
                // PgDn / Up / Left
                this.smService.deleteSelection();
                result = true;
            }
        }
        return result;
    }

    //#region QUICK/SPEECH EDIT
    private quickEditSetup() {
        let selectedElement = this.smService.getSelectedElement();
        if (!selectedElement) {
            selectedElement = this.smService.addNodeAndSelect(this.selectionData.scrCenterX, this.selectionData.scrCenterY);
            this._lastQuickNodeCreated = selectedElement;
            // this.firstQuickNodeCreated = selectedElement;
        }
        if (selectedElement) {
            this.updateCanvasOrigin();
            setTimeout(() => {
                if (selectedElement.className === 'Node') {
                    // Empty node Node or table cell (in place)
                    const isEmpty = (selectedElement.titleText.trim() === '');
                    const isCell = selectedElement.nodeTable &&
                        selectedElement.nodeTable.tableId &&
                        selectedElement.nodeTable.tableId !== '';
                    if (this.mapStateService.canvasOrigin) {
                        if (isEmpty || isCell) {
                            // In place
                            this.leftQuick = this.mapStateService.canvasOrigin.x + this.selectionData.scrCenterX - 100;
                            this.topQuick = this.mapStateService.canvasOrigin.y + this.selectionData.scrCenterY - 35;
                        } else {
                            // Titled node (add child, down)
                            const zoomFactor = this.smService.getZoomFactor();
                            const scaledNodeHeight = (selectedElement.geometry.height / 2 * zoomFactor);
                            this.leftQuick = this.mapStateService.canvasOrigin.x + this.selectionData.scrCenterX - 100;
                            this.topQuick = this.mapStateService.canvasOrigin.y + this.selectionData.scrCenterY + scaledNodeHeight - 35;
                        }
                    }
                } else if (selectedElement.className === 'Edge') {
                    // Edge
                    if (this.mapStateService.canvasOrigin) {
                        this.leftQuick = this.mapStateService.canvasOrigin.x + this.selectionData.scrCenterX - 100;
                        this.topQuick = this.mapStateService.canvasOrigin.y + this.selectionData.scrCenterY - 45;
                    }
                }
            }, 10);
        }
    }

    private completeQuickEdit(): boolean {
        this.editBusy = true;
        let hasNext = true;
        const selectedElement = this.smService.getSelectedElement();
        if (selectedElement) {
            // Stile di default da quickedit
            const isNode = (selectedElement.className === 'Node');
            let html = UiConstants.getDefaultHtmlForTitle(this.mapEditService.htmlEncode(this.quickEditValue), isNode);
            // let html = UiConstants.getHtmlTitle(this.quickEditValue, isNode);
            let data = { titleHtml: html };
            this.mapStateService.autoRenameNewMap(this.quickEditValue);
            if (selectedElement.className === 'Edge') {
                // Edge
                data.titleHtml = UiConstants.normalizeHtmlForTitle(data.titleHtml, false);
                this.smService.editSelectedElement(data);
                hasNext = false;
            } else {
                // Node/Cell
                const isEmpty = (selectedElement.titleText.trim() === '');
                const isCell = selectedElement.nodeTable &&
                    selectedElement.nodeTable.tableId &&
                    selectedElement.nodeTable.tableId !== '';
                if (isEmpty || isCell) {
                    // In place 
                    if (selectedElement.titleHtml !== "") {
                        html = selectedElement.titleHtml.replace('&nbsp;', this.mapEditService.htmlEncode(this.quickEditValue));
                        // html = UiConstants.getHtmlTitle(html, isNode);
                        data = { titleHtml: html };
                    }
                    data.titleHtml = UiConstants.normalizeHtmlForTitle(data.titleHtml, true);
                    this.smService.editSelectedElement(data);
                    if (isCell) {
                        const currentSelection = this.smService.getSelectedElement();
                        if (!currentSelection) {
                            // After last cell selection is empty
                            hasNext = false;
                        }
                    }
                } else {
                    // Create new node
                    const newNode = this.smService.addChildNode(selectedElement);
                    this.smService.setNodeTitleCommand(newNode.id, html);
                    this._lastQuickNodeCreated = newNode;
                }
            }
        }
        this.stopQuickEdit();
        // this.stopSpeechEdit();
        this.editBusy = false;
        return hasNext;
    }

    //#region quick/speech edit
    resizeInput(el: any, factor: number) {
        const n = (el.value.length + 1) * factor;
        el.style.width = n + 'px';
    }

    resizable(el: any, factor: number) {
        const int = Number(factor) || 7.7;
        const e = 'keyup,keypress,focus,blur,change'.split(',');
        function resize() {
            const n = (el.value.length + 1) * int;
            el.style.width = n + 'px';
        }
        for (let i = 0; i < e.length; i++) {
            el.addEventListener(e[i], resize, false);
        }
        resize();
    }

    startQuickEdit() {
        this.smService.setEnableKeyPresses(false);
        if (this.inputQuick) {
            this.inputQuick.nativeElement.width = 200;
            if (this.quickEditFromButtonInteraction) {
                if (this.inputQuick.nativeElement.value.length > 1) {
                    this.inputQuick.nativeElement.value = this.quickEditValue; // '';
                }
            } else {
                this.inputQuick.nativeElement.value = this.quickEditValue; // '';
            }
        }
        this.quickEditVisible = true;
        this.quickEditSetup();

        setTimeout(() => {
            this.inputQuick?.nativeElement.focus();
            if (this.quickEditFromButtonInteraction) {
                this.pendingQuickString = '';
            }
        });
    }

    stopQuickEdit() {
        this.quickEditFromButtonInteraction = false;
        this.quickEditing = false;
        this.smService.setEnableKeyPresses(true);
        this.quickEditVisible = false;
        if (this.quickEditEnabled) { this.quickEditService.toggleQuickEdit(); }
        if (this._lastQuickNodeCreated && this._lastQuickNodeCreated.titleHtml === '' && !this._lastQuickNodeCreated.tableId) {
            this.smService.deleteNode(this._lastQuickNodeCreated.id);
            this._lastQuickNodeCreated = null;
            // this.smService.selectNode(this._lastQuickNodeCreated);
            // this.smService.deleteSelection();
        }
        // if (this.firstQuickNodeCreated && this.firstQuickNodeCreated.titleHtml === '' && !this.firstQuickNodeCreated.tableId) {
        //     this.smService.deleteNode(this.firstQuickNodeCreated.id);
        //     this.firstQuickNodeCreated = null;
        //     // this.smService.selectNode(this.firstQuickNodeCreated);
        //     // this.smService.deleteSelection();
        // }
    }

    startSpeechEdit() {
        this.quickEditSetup();
        if (this.inputSpeech) {
            this.inputSpeech.nativeElement.width = 200;
            this.inputSpeech.nativeElement.value = '';
        }
        this.topSpeechIcon = this.topQuick;
        this.leftSpeechIcon = this.leftQuick - 25;
        this._stopSpeech = false;

        /*setTimeout(() => {
            this.inputSpeech?.nativeElement.focus();
        }, 10);*/

        if (this.speechRecordSubscription) { this.speechRecordSubscription.unsubscribe(); }
        this.speechRecordSubscription = this.speechRecognitionService.record(this.currentLang)
            .subscribe({
                // listener
                next: (srr: SpeechRecognitionResult) => {
                    const text: string = srr.transcript;
                    console.log("****************Speechedit transcript: " + text);
                    if (srr.isFinal) {
                        // End speech recognition
                        if (text.toLowerCase() === 'ok' || text.toLowerCase() === 'stop') {
                            this.stopSpeechEdit();
                        } else {
                            this.quickEditValue = text;
                            if (!this.switchingInput) {
                                this.completeQuickEdit();
                            } else {
                                this.switchingInput = false;
                            }

                            setTimeout(() => {
                                console.log("***********Speechedit final timeout")
                                this.quickEditValue = '';
                                if (!this._stopSpeech) {
                                    this.startSpeechEdit();
                                } else {
                                    this.stopSpeechEdit();
                                }
                            }, 100);
                        }

                    } else {
                        // Partial speech recognition
                        this.quickEditValue = text;
                        if (this.inputQuick) {
                            //this.inputQuick.nativeElement.value = srr.transcript;
                            this.resizeInput(this.inputQuick.nativeElement, 7);
                            this.resizeInput(this.inputSpeech?.nativeElement, 7);
                        } else {
                            console.log("No quick input");
                        }
                    }
                },
                // error
                error: (err) => {
                    console.log("****************Speechedit error " + JSON.stringify(err));
                    console.log("****************Speechedit error " + err.error);
                    console.log("****************Speechedit error " + err.message);

                    if (err.error === 'no-speech') {
                        console.log('SpeechEdit error: no-speech');
                        if (!this._stopSpeech) {
                            this.startSpeechEdit();
                        }
                    }

                    if ((err.error.toLowerCase() as string).includes("aborted")) {
                        console.log('SpeechEdit error: aborted');
                        if (!this._stopSpeech) {
                            this.startSpeechEdit();
                        }
                    }
                },
                // completion
                complete: () => {
                    console.log('****************Speechedit complete');

                    //tolto perche' si pesta i piedi con la sequenza di un riconoscimento "isFinal"
                    /*if (!this._stopSpeech) {
                        console.log('****************Speechedit complete restart');
                        this.startSpeechEdit();
                    }*/
                }
            });
        this.speechEditVisible = true;
    }

    stopSpeechEdit() {
        console.log('stopSpeechEdit');

        this.speechEditVisible = false;
        this.speechRecognitionService.DestroySpeechObject();
        this._stopSpeech = true;

        if (this.quickEditService.isSpeechEditEnabled()) { this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.MAP); }
        if (this._lastQuickNodeCreated && this._lastQuickNodeCreated.titleHtml === '' && !this._lastQuickNodeCreated.tableId) {
            this.smService.deleteNode(this._lastQuickNodeCreated.id);
            this._lastQuickNodeCreated = null;
        }
    }

    editMapName() {
        this.mapStateService.setFocusOnMap(false);
        this.smService.setEnableKeyPresses(false);
        this.renameMapMode = true;
        //  this.mapButton.nativeElement._elementRef.nativeElement.hidden = true;

        setTimeout(() => {
            this.mapTitle?.nativeElement.focus();

        }, 10);
    }

    exitEditMapName() {
        this.mapStateService.setFocusOnMap(true);
        this.smService.setEnableKeyPresses(true);
        this.renameMapMode = false;
        //  this.mapButton.nativeElement._elementRef.nativeElement.hidden = false;
        if (this.newMapName !== this.mapName && this.newMapName.trim() !== '') {
            this.mapName = this.newMapName;
            this.newMapName = '';
            this.mapStateService.workJson = this.smService.setMapName(this.mapName);
            this.mapStateService.isDirty = true;
            this.smeService.findOwnerIdByMapId(this.mapStateService.id).subscribe((result: any) => {
                const ownerId = result.ownerId;
                this.uploadWorkJsonSubscription = this.smeService.uploadWorkJson(ownerId, this.mapStateService.id, this.mapStateService.workJson).subscribe(() => {
                    this.mapStateService.isDirty = false;
                });
                // this.googleService.getAccessToken().then((accessToken: string) => {
                this.renameMapSubscription = this.smeService.renameMap(this.mapStateService.id, this.mapName + '.sme').subscribe(() => {
                    this.mapStateService.forceAutoSave();
                    logger.info('Map name changed');
                });
                // }).catch(error => {
                //     this.isLoading = false;
                //     // this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
                //     this.authenticationService.logout().subscribe(() => this.router.navigate(['login', '/map-edit/' + this.mapStateService.id]));
                // });
            });
        }
    }

    updateCanvasOrigin() {
        if (this.mapContainerElement) {
            setTimeout(() => {
                const element: HTMLElement = this.mapContainerElement?.nativeElement;
                const rect = element.getBoundingClientRect();
                this.mapStateService.canvasOrigin = new Point(rect.left, rect.top);
                this.smService.setCanvasOrigin(rect.left, rect.top, rect.right, rect.bottom);
            });
        }
    }

    printImage() {
        if (this.imageBase64 != null) {
            setTimeout(() => {
                (<any>window).print();
                // this.imageBase64 = null;
                // this.removeImgQrCode();
            }, 1000);
        }
    }

    private removeImgQrCode() {
        let oldel = document.getElementById('map-qrcode');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('gdoc-qrcode');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('map-qrcode-symbol');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('gdoc-qrcode-symbol');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('stamp-image');
        if (oldel) { oldel.remove(); }
    }

    public moveMap() {
        this.router.navigate(['gdrive-folder-picker', this.mapStateService.id]);
    }

    private checkAutosaveError(error: any) {
        logger.error('AUTOSAVE ERROR: ' + error);
        // if (this.mapStateService.index === -1) {
        this.saveStatus = 2;
        this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
        if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
            // this.showError('ERR_GDRIVE_3PTY_DISABLED');
            this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
                this.saved = false;
                this.resetSaveFlags();
            });
        } else {
            this.saved = false;
            this.resetSaveFlags();
            this.countSaveError++;
            if (error.status === 504) {
                this.saveStatus = 3;
                this.saveStatusMessage = this.translateService.instant(extract('OFFLINE_STATUS'));
            } else {
                this.saveStatus = 2;
                this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
            }
            if (error.code === UiConstants.SMXERRORS.ERROR_AUTHENTICATION) {
                if (this.logoutSubscription) { this.logoutSubscription.unsubscribe(); }
                this.logoutSubscription = this.authenticationService.logout().subscribe(() => {
                    this.router.navigate(['/login', 'map-edit/' + this.mapStateService.id]);
                });
                this.saved = false;
                this.resetSaveFlags();
                return;
            } else if (error.code === UiConstants.SMXERRORS.ERROR_GOOGLEDRIVE) {
                this.saveError = this.smeService.parseErrorFromGoogleDrive(error);
                this.saved = false;
                this.isSaving = false;
                //this.mapStateService.setAutosaving(true);
                this.showError(this.saveError);
            }
            this.saveError = this.translateService.instant(extract('ERROR-SAVING'));
            if (this.countSaveError > 2) {
                this.error = this.translateService.instant(extract('ERROR_ZIPPER'));
                if (error !== '') {
                    this.showError(error);
                }
            }
        }
    }

    private resetSaveFlags() {
        if (this.isSaving) logger.info('***AutoSave: shutting down flag isSaving...');
        this.isSaving = false;
        if (this.mapStateService.getAutosaving()) logger.info('***AutoSave: shutting down flag mapStateService.autosaving...');
        this.mapStateService.setAutosaving(false);
    }

    private checkLastSaveTimeMoreThanOneMinute(): boolean {
        const now = new Date();
        const deltamSec = now.getTime() - this.lastSomeoneSavingTime.getTime();
        return deltamSec > 60000;
    }

    public autosave() {
        // logger.info('***Autosave: start checking...');
        // Check if last save was more than 1 minute ago
        if (this.checkLastSaveTimeMoreThanOneMinute() && (this.isSaving || this.mapStateService.getAutosaving())) {
            // If last remote save is older than 1 minute, reset save flags
            // logger.info('***Autosave: >60sec, resetting up save flags...');
            this.resetSaveFlags();
        }

        //if map is not modified, don't autosave
        if (!this.mapStateService.isDirty) {
            // logger.info('***Autosave: map not dirty - aborted!');
            return;
        }

        //if someone is saving, don't autosave
        if (this.isSaving || this.mapStateService.getAutosaving()) {
            logger.info('***Autosave: Someone else is saving, aborted!');
            return;
        }

        //Abort autosave if not logged in and return to login
        if (!this.authenticationService.isAuthenticated()) {
            logger.error('***Autosave ERROR: Not logged in, aborted!');
            this.saveStatus = 2;
            this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
            this.saveError = this.translateService.instant(extract('ERROR-SAVING'));
            this.mapStateService.forceExit = true;
            this.resetSaveFlags();
            // this.router.navigate(['loggedin'], { fragment: 'expired=1' });
            this.authenticationService.logout().subscribe(() => this.router.navigate(['login', '/map-edit/' + this.mapStateService.id]));
        }

        if (this.checkSessionIdSubscription) { this.checkSessionIdSubscription.unsubscribe(); }

        logger.info('***Autosave: Save started...');
        this.isSaving = true;
        this.checkSessionIdSubscription = this.smeService.checkSessionId()
            .subscribe({
                next: (accessOK: any) => {
                    if (accessOK.ok && accessOK.result) {
                        logger.info('***Autosave: Check double access SUCCESS');
                        logger.info('***Autosave: Try Autosaving...');
                        try {
                            this.mapStateService.workJson = JSON.stringify(this.smService.convertToWorkJson());

                            this.saveStatus = 1;
                            this.saveStatusMessage = this.translateService.instant(extract('SAVING'));
                            this.addMapEvent('save_start', '').then(() => { }).catch((err => {
                                this.handleFirestoreError(err);
                            }));
                            // Now is the time reference to delete map real time events (firestore)
                            const autosaveTime = new Date();
                            this.autosaveService.autosave(this.mapStateService.index, this.mapStateService.id, this.mapStateService.workJson)
                                .then((savedIndex) => {
                                    logger.info('***Autosave: SUCCESS first step');
                                    // this.firebaseService.deleteMapEvents('after_save', this.mapStateService.id, autosaveTime);
                                    const toDateTime = (autosaveTime ? autosaveTime.toISOString() : '');
                                    this.smeService.deleteMapEvents('after_save', this.mapStateService.id, toDateTime)
                                        .subscribe({
                                            next: () => {
                                                // Notify save end to all clients
                                                this.addMapEvent('save_end', '').then(() => { }).catch((err => {
                                                    this.handleFirestoreError(err);
                                                }));
                                                logger.info('***Autosave: SUCCESS second step');
                                                this.saveError = '';
                                                if (savedIndex !== -1) {
                                                    if (this.mapStateService.index === -1) {
                                                        savedIndex = -1;
                                                    }
                                                    this.mapStateService.isDirty = (this.mapStateService.index !== savedIndex);
                                                    this.mapStateService.lastSaveIndex = savedIndex;
                                                    if (this.mapStateService.isDirty) {
                                                        this.saveStatus = 2;
                                                        this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
                                                        this.saved = false;
                                                        this.resetSaveFlags();
                                                    } else {
                                                        this.saved = true;
                                                        this.resetSaveFlags();
                                                        this.saveStatus = 0;
                                                        this.saveStatusMessage = this.translateService.instant(extract('ALLSAVED'));
                                                    }

                                                } else {
                                                    this.saveStatus = 2;
                                                    this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
                                                    this.saved = false;
                                                    this.resetSaveFlags();
                                                }
                                            },
                                            error: () => {
                                                logger.error('***Autosave: ERROR during deleteMapEvents');
                                                this.saveStatus = 2;
                                                this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
                                                this.saved = false;
                                                this.resetSaveFlags();
                                            }
                                        });
                                }).catch((error) => {
                                    // Error: log and reset
                                    // Clear global saving event
                                    // this.firebaseService.deleteMapEvents('reset_save', this.mapStateService.id, null);
                                    logger.error('***Autosave: ERROR during autosave ' + error);
                                    this.smeService.deleteMapEvents('reset_save', this.mapStateService.id, '')
                                        .subscribe({
                                            next: () => {
                                                // Notify save end to all clients (even if it's failed, to unlock others)
                                                this.addMapEvent('save_end', '').then(() => { }).catch((err => {
                                                    this.handleFirestoreError(err);
                                                }));
                                                this.checkAutosaveError(error);
                                                // }
                                            },
                                            error: () => {
                                                this.checkAutosaveError(error);
                                            }
                                        });
                                });
                        } catch (err: any) {
                            logger.error('***Autosave: ERROR during autosave try catch ' + err);
                            this.saved = false;
                            this.resetSaveFlags();
                        }
                    } else {
                        // Doppio accesso logout
                        logger.error('***Autosave: Check double access FAILED - Logout...');
                        this.mapStateService.isDirty = false;
                        this.saved = false;
                        this.resetSaveFlags();
                        this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'double_access=1' }));
                    }
                },
                error: (error: any) => {
                    // this.countSaveError++;
                    logger.error('***Autosave ERROR check session id: ' + error);
                    if (error.status === 504 || error.status === 403) {
                        this.saveError = this.translateService.instant(extract('ERROR-SAVING'))
                            + ' ' + this.translateService.instant(extract('OFFLINE_STATUS'));
                    } else {
                        // const s = JSON.parse(error);
                        // this.saveError = error.code;
                        this.saveError = this.translateService.instant(extract('ERROR-SAVING'))
                            + ' (code:' + error.status + ')';
                    }
                    // if (this.countSaveError > 2) {
                    //     this.error = this.translateService.instant(extract('ERROR_ZIPPER'));
                    //     if (error !== '') {
                    //         this.showError(error);
                    //     }
                    // }

                    this.saveStatus = 2;
                    this.saveStatusMessage = this.translateService.instant(extract('DIRTY'));
                    this.saved = false;
                    this.resetSaveFlags();
                }
            });
    }

    loadMap(mapJson: any) {
        logger.debug(`Loading map ${this.mapStateService.id}...`);
        const urlResolver = this.smeService.getUrlResolver();
        const eventBroker = this.smEventBroker;
        if (this.smEventBroker.interactionMode) {
            this.interactionModeSubscription = this.smEventBroker.interactionMode.subscribe((_isReadonlyMode) => {
                this.isReadonlyMode = _isReadonlyMode;
            });
        }

        SMX_CONFIG.isMobile = this.platform.isMobileOrTabletDevice();
        this.smService.init(SMX_CONFIG, eventBroker, urlResolver);

        this.smService.show(this.mapStateService.id, mapJson, this.mapStateService.isNew);

        // Add stat
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
        this.statShareSubscription = this.smeService.addCustomMapStat(this.mapStateService.id, MapOperationType.STAT_VIEWSHARED, this.authenticationService.getUserEmail()).subscribe((data: any) => { });

        this.mapStateService.isNew = false;
        if (mapJson.mapProps && mapJson.mapProps.mapName) {
            // Calcola il basename
            this.mapName = mapJson.mapProps.mapName.split(/[\\/]/).pop();
            this.mapName = this.mapName.replace('.sme', '');
        } else {
            if (mapJson.mapProps && mapJson.mapProps.mapname) {
                this.mapName = mapJson.mapProps.mapname.split(/[\\/]/).pop();
                this.mapName = this.mapName.replace('.sme', '');
            }
        }
        this.mapStateService.name = this.mapName;
        if (mapJson.mapProps && mapJson.mapProps.author) {
            this.mapStateService.author = mapJson.mapProps.author;
        }
        this.mapStateService.workJson = JSON.stringify(mapJson);
        try {
            this.tracker.trackPageView('Apertura Mappa ' + this.mapName + ' da ' + this.authenticationService.getUserEmail());
        } catch (e: any) {
            logger.error('Matomo error ' + e.message);
        }

        // document.getElementById('sm-canvas-base').style.backgroundColor = (mapJson.extra.background) ? (mapJson.extra.background) : 'white';

        // if (!environment.production) {
        (<any>window).sm = this.smService.sm;
        // }
        if (this.smEventBroker.backgroundChangedData) {
            this.changedBackgroundcolorSubscription = this.smEventBroker.backgroundChangedData.subscribe((color: any) => {
                this.backgroundColor = color;
                this.setGrid(this.mapStateService.isGridVisible);
            });
        }
    }

    openRevision() {
        const id = this.mapStateService.id;
        this.mapStateService.mapPreview = this.smService.convertToImage(false, null, this.mapStateService.limitPrintSize, false);
        this.router.navigate(['/map-history', id]);
    }

    private showError(errorCode: string) {
        this.isLoading = false;
        this.error = this.translateService.instant(errorCode);
    }

    public reloadPage() {
        this.mapStateService.setStateError('');
        window.location.reload();
    }

    public hideError() {
        this.error = '';
        this.isLoading = false;
    }

    public hideErrorGlobal() {
        this.mapStateService.setStateError('');
        if (this.loginError) { this.router.navigate(['login']); }
        if (this.loadingError) {
            this.goHome();
        }
    }

    get mapName(): string {
        return this.mapStateService.name;
    }

    set mapName(newMapName: string) {
        this.mapStateService.name = newMapName;
        const newTitle: string = newMapName + ' - ' + this.translateService.instant(extract('MAPEDIT_TITLE'));
        this.titleService.setTitle(newTitle);
    }

    dragOver(event: any) {
        this.preventAndStop(event);
    }

    public importMap(file: any) {
        this.isLoading = true;
        if (this.popupBlockedSubscription) this.popupBlockedSubscription.unsubscribe();
        this.popupBlockedSubscription = this.importMapService.onPopupBlocked.subscribe((blocked: boolean) => {
            const title = this.translateService.instant('WARNING');
            let url = '';
            const message = this.translateService.instant('MSG_BLOCKED_POPUP');
            if (this.popupBlockedSubscription) this.popupBlockedSubscription.unsubscribe();
            if (this.onMapCreatedSubscription) this.onMapCreatedSubscription.unsubscribe();
            this.onMapCreatedSubscription = this.importMapService.onMapCreated.subscribe((_url) => {
                url = _url;
            })
            this.popupBlockedSubscription = this.importMapService.onPopupBlocked.subscribe((blocked: boolean) => {

                const title = this.translateService.instant('WARNING');
                const message = this.translateService.instant('MSG_BLOCKED_POPUP');

                this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message).subscribe(() => {



                    const redirectUri = url;//window.location.origin + '/map-open/' + url;
                    window.open(redirectUri, '_blank');


                });
            });
        });
        this.importMapService.onMapCreated.subscribe((mapid: string) => {
            this.isLoading = false;
        });
        this.importMapService.openMap(file, true);

    }


    drop(event: any) {
        if (!this.mapStateService.dropping && !this.mapShowService.isEditShowMode) {

            console.log('DROP:' + event);
            event.dataTransfer.dropEffect = 'copy';
            let goOn = true;
            if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                const file = event.dataTransfer.files[0];
                const extension = file.name.split('.').pop();
                if (extension === 'sme') {
                    goOn = false;
                    this.importMap(file);
                }
            }
            if (goOn && this.mapStateService && this.mapStateService.canvasOrigin) {

                const clientX = (this.extraService.isExtraPanelOpen || this.isExtraMenuVisible) ? event.clientX - this.mapStateService.canvasOrigin.x : event.clientX;
                const clientY = event.clientY - this.mapStateService.canvasOrigin.y;

                const contentHtml: any = event.dataTransfer.getData('text/html');
                let linkText = '';
                if (contentHtml === '') {
                    linkText = event.dataTransfer.getData('text/uri-list');
                    if (linkText === '') {
                        linkText = event.dataTransfer.getData('text/plain');
                    }
                }
                // Insert content from html
                const curSelection = this.mapEditService.editNodeFromHtml(contentHtml, clientX, clientY, linkText);
                const title = event.dataTransfer.getData('text/plain');
                this.mapStateService.autoRenameNewMap(title);
                // if (contentHtml !== '') {
                // this.mapStateService.autoRenameNewMap(this.mapStateService.getPlainText(contentHtml));
                // }

                // Get image from File
                if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                    const file: any = event.dataTransfer.files[0];
                    // this.smService.addNodeAndSelectInEmptySpace(clientX, clientY);
                    setTimeout(() => {
                        this.imageMapsService.insertImageFromFile(file, curSelection);
                    }, 100);
                }
            }
            this.preventAndStop(event);
        } else {
            // BUSY: Operation in progress... wait!
            console.log('BUSY: DROP declined!');
            this.preventAndStop(event);
        }
    }

    dragEnd(event: any) {
        this.preventAndStop(event);
        console.log('DRAG_END:' + event);
    }

    preventAndStop(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }

    //EXTRA MENU
    currentSelectedExtraMenuButtonId: any = null;

    getTimeout(): number {
        return this.extraService.isExtraPanelOpen ? 600 : 0;
    }

    toggleExtraMenuVisibility() {
        this.isExtraMenuVisible = !this.isExtraMenuVisible;
        this.hasNotes = this.extraService.hasNotes();
        this.updateCanvasOrigin();
        this.smService.resizeCanvas();
        if (this.userPreferenceService && this.userPreferenceService.userPrefs) {
            this.userPreferenceService.updateSideBar(this.isExtraMenuVisible);
        }
    }

    openExtraMenuConfig() {
        const desmosConfig = (this.userPreferenceService.userPrefs) ? this.userPreferenceService.userPrefs.desmosConfig : new DesmosConfigDto('');
        this.modalService.showExtraMenuConfig(desmosConfig);

    }

    openDesmos(type: string) {
        this.desmosService.openDesmos(type);

    }

    openExtra(type: string) {
        this.desmosService.openExtra(type);
    }

    openAppunti(buttonRef: HTMLElement) {
        if (this.extraService.lastOpenDto.extraItem != ExtraItem.OPEN_NOTEPAD) {
            this.currentSelectedExtraMenuButtonId = buttonRef;
            setTimeout(() => {
                this.onExtraButtonPressed(ExtraItem.OPEN_NOTEPAD);
            }, this.getTimeout());
        } else {
            this.onExtraButtonPressed(ExtraItem.CLOSE);
        }

    }

    openPdf(buttonRef: HTMLElement) {
        if (this.extraService.lastOpenDto.extraItem != ExtraItem.OPEN_PDF) {
            this.currentSelectedExtraMenuButtonId = buttonRef;
            setTimeout(() => {
                this.onExtraButtonPressed(ExtraItem.OPEN_PDF);
            }, this.getTimeout());
        } else {
            this.onExtraButtonPressed(ExtraItem.CLOSE);
        }
    }

    openOutline(buttonRef: HTMLElement) {
        if (this.extraService.lastOpenDto.extraItem != ExtraItem.OPEN_OUTLINE) {
            this.currentSelectedExtraMenuButtonId = buttonRef;
            setTimeout(() => {
                try {
                    this.tracker.trackEvent('User', 'Click open', 'outline');
                } catch (e: any) {
                    logger.error('matomo error ' + e.message);
                }
                this.onExtraButtonPressed(ExtraItem.OPEN_OUTLINE);
            }, this.getTimeout());
        } else {
            this.onExtraButtonPressed(ExtraItem.CLOSE);
        }
    }

    openAutodraw(buttonRef: HTMLElement) {
        if (this.extraService.lastOpenDto.extraItem != ExtraItem.OPEN_AUTODRAW) {
            this.currentSelectedExtraMenuButtonId = buttonRef;
            setTimeout(() => {
                this.onExtraButtonPressed(ExtraItem.OPEN_AUTODRAW);
            }, this.getTimeout());
        } else {
            this.onExtraButtonPressed(ExtraItem.CLOSE);
        }
    }

    openWeb(buttonRef: HTMLElement) {
        this.currentSelectedExtraMenuButtonId = buttonRef;
        setTimeout(() => {
            this.onExtraButtonPressed(ExtraItem.OPEN_BROWSER);
        }, this.getTimeout());
    }

    onExtraButtonPressed(extra: ExtraItem) {
        this.extraService.toggleExtraPanel(extra);
    }

    getExtraMenuSelectorOffset() {
        if (this.currentSelectedExtraMenuButtonId)
            return { 'top.px': this.currentSelectedExtraMenuButtonId.offsetTop };
        else {
            return {};
        }
    }

    getWebSearchPosition(elementRef: HTMLElement, topOffset: number, leftOffset: number) {
        const totalTopOffset: number = elementRef.offsetTop + topOffset;
        const totalLeftOffset: number = elementRef.offsetLeft + leftOffset;
        return {
            'top.px': totalTopOffset,
            'left.px': totalLeftOffset
        }
    }

    setWebSearchPosition() {
        const webSearchPanel: HTMLElement = document.getElementById("webSearchPanel") as HTMLElement;
        const mapArea: HTMLElement = document.getElementById("sm-container") as HTMLElement;

        if (mapArea && webSearchPanel) {
            const leftOffset = mapArea.getBoundingClientRect().left + (mapArea.clientWidth * 0.5) - (webSearchPanel.clientWidth * 0.5);
            const topOffset = mapArea.offsetTop;

            const newTop = topOffset + 20;
            const newLeft = leftOffset;

            this.webSearchDefaultPosition = { x: newLeft, y: newTop };

            //webSearchPanel.style.top = newTop + 'px';
            //webSearchPanel.style.left = newLeft + 'px';
        }
    }

    setMapSearchPosition() {
        const webSearchPanel: HTMLElement = document.getElementById("mapSearchPanel") as HTMLElement;
        const mapArea: HTMLElement = document.getElementById("sm-container") as HTMLElement;

        if (mapArea && webSearchPanel) {
            const leftOffset = mapArea.getBoundingClientRect().left + (mapArea.clientWidth * 0.5) - (webSearchPanel.clientWidth * 0.5);
            const topOffset = mapArea.offsetTop;

            const newTop = topOffset + 20 + 50 + 20;
            const newLeft = leftOffset;

            this.mapSearchDefaultPosition = { x: newLeft, y: newTop };
        }
    }

    getExtraButtonClass() {
        const c = (!this.isExtraMenuVisible ?
            (this.authenticationService.isLab() ? 'extra-button-color-background-opened-lab' : 'extra-button-color-background-opened-edu') :
            'extra-button-color-background-closed');
        return c;
    }

}
function isQuickKey(key: string) {
    let res = false;
    const quickKeys: Array<string> = [
        // KEYBOARD
        '?',
        'ì',
        '^',

        'è',
        'é',
        '+', '*',

        'ò', 'ç', '@',
        'à', '°', '#',
        'ù', '§',
        '<', '>',
        ',', ';',
        '.', ':',
        '-',
        // NUMPAD
        '/',
        '*',
        '-',
        '+',
        '.',
    ];
    // if ((key >= 'A' && key <= 'Z')        // KEYBOARD A-Z
    //   || (key >= '0' && key <= '9')     // KEYBOARD 0-9
    //   // || (key >= 96 && key <= 105)    // NUMPAD 0-9
    // ) {
    // if (/[a-zA-Z0-9-_ ]/.test(key)) {
    // alert('input was a letter, number, hyphen, underscore or space');
    if (/^[a-z0-9]$/i.test(key)) {
        res = true;
    } else if (quickKeys.indexOf(key) >= 0) {
        res = true;
    }
    // JHUBA: DA CANCELLARE SOTTO
    // console.log(`----------key: ${key} -> ${res}`);
    // return false;
    // JHUBA: DA CANCELLARE SOPRA E LASCIARE QUESTO: (SOLO DEBUG)
    return res;
}

function isQuickKeycode(key: number) {
    let res = false;
    const quickKeys: Array<number> = [
        // KEYBOARD
        219,    // '?
        221,    // ì^
        186,    // èé
        187,    // +*
        192,    // òç@
        222,    // à°#
        191,    // ù§
        220,    // <>
        188,    // ,;
        190,    // .:
        189,    // -_
        // NUMPAD
        111,    // /
        106,    // *
        109,    // -
        107,    // +
        110     // .
    ];
    if ((key >= 48 && key <= 57)        // KEYBOARD A-Z
        || (key >= 60 && key <= 95)     // KEYBOARD 0-9
        || (key >= 96 && key <= 105)    // NUMPAD 0-9
    ) {
        res = true;
    } else if (quickKeys.indexOf(key) >= 0) {
        res = true;
    }
    // JHUBA: DA CANCELLARE SOTTO
    // console.log(`----------key: ${key} -> ${res}`);
    // return false;
    // JHUBA: DA CANCELLARE SOPRA E LASCIARE QUESTO: (SOLO DEBUG)
    return res;
}
